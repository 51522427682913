import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HelperEmpty from '../../helper/EmptyLarge';



const DataEmpty  = [
  {
    id: 'chat',
    title: 'This is the beginning of your conversation',
    text: "Note that messages will\nbe deleted after 30 days",
    icon: 'community',
  },
]



class ListEmpty extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      type: props.type ?? '',
      var: props.var
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      id: props.id,
      type: props.type ?? '',
      var: props.var
    }
  }


  render() {
    var data = null
    var text = ''
    for(var item of DataEmpty) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(data !== null) {
      text = data.text.replace('$var', this.state.var)
      if(this.state.type === 'simple') {
        return (
          <div className="empty large pt-60 clear">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmpty.renderImage(data.icon)}
            </div>
            <div className="data">
              <h3 className="mb-20">{data.title}</h3>
              <p className="mb-20">{text}</p>
            </div>
          </div>
        )
      } else {
        if(data.link === '-func-') {
          return (
            <div className="empty large pt-60 clear">
              <div className="illustration mb-30">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmpty.renderImage(data.icon)}
              </div>
              <div className="data">
                <h3 className="mb-20">{data.title}</h3>
                <p className="mb-20">{data.text}</p>
                <button className="btn tertiary width-12 add" onClick={() => this.props.clickMainButton()}>{data.button}</button>
              </div>
            </div>
          )
        } else {
          return (
            <div className="empty large pt-60 clear">
              <div className="illustration mb-30">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmpty.renderImage(data.icon)}
              </div>
              <div className="data">
                <h3 className="mb-20">{data.title}</h3>
                <p className="mb-20">{data.text}</p>
                <Link to={data.link} className="btn tertiary width-12 add">{data.button}</Link>
              </div>
            </div>
          )
        }
      }
    }
  }
}


export default ListEmpty;