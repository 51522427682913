import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperCal from '../../helper/Calendar';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberProgramPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      name: global.userName,
      session: '',
      id: '',
      program: null,
      type: 'session',
      showOverlayDelete: false,
      hiddenDelete: 'hidden',
      showOverlaySession: false,
      hiddenSession: 'hidden',
      showOverlayVideo: false,
      hiddenVideo: 'hidden',
      showOverlayEx: false,
      hiddenEx: 'hidden',
      margin: 0,
      margin2: 0,
      url: '',
      // Run session stuff
      sid: '',
      created: false,
      // Movement stuff
      eid: '',
      ext: '',
      exd: '',
      exi: '',
      exv: '',
      extl: 0,
      cbest: null,
      isplan: false,
      plan: ''
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-5]
    var sess = arr[arr.length-3]
    var prog = ''
    var plan = ''
    document.title = lang.t('header:title.sessionprogram')+' - '+global.userBusiness

    if(arr[arr.length-3] === 'training') {
      id = arr[arr.length-4]
      sess = ''
      prog = arr[arr.length-1]
      document.title = lang.t('header:title.program')+' - '+global.userBusiness
      this.setState({
        type: 'program',
      })
    }
    if(arr[arr.length-4] === 'training') {
      id = arr[arr.length-5]
      sess = ''
      prog = arr[arr.length-1]
      plan = arr[arr.length-2]
      document.title = lang.t('header:title.program')+' - '+global.userBusiness
      this.setState({
        type: 'program',
        isplan: true,
        plan: plan
      })
    }

    if(id !== 'session' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    
    window.scrollTo(0, 0)

    this.setState({
      done: true,
      session: sess,
      id: prog
    }, () => {
      this.configureData()
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainingLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('plansLoaded', (event) => this.configureData())
  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.sessionprogram')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var program = null
    if(this.state.type === 'session'){
      for(var item of global.sessions) {
        if(item.id === this.state.session) {
          program = item.program[0]
        }
      }
    } else {
      for(var item2 of global.programs) {
        if(item2.id === this.state.id) {
          program = item2
        }
      }
      for(var plan of global.plans) {
        if(plan.id === this.state.plan) {
          for(var item3 of plan.programs) {
            if(item3.id === this.state.id) {
              program = item3
            }
          }
        }
      }
    }
    if(this.state.created) {
      this.props.history.push('/'+global.uid+'/running/'+this.state.sid)
    }
    this.setState({
      sessions: global.sessions,
      program: program
    })
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }



  // Delete overlay ------------------------------------------------------------



  showOverlayDelete() {
    this.setState({showOverlayDelete: true})
    setTimeout(() => {
      this.setState({hiddenDelete: ''});
    }, 100);
  }


  hideOverlayDelete() {
    this.setState({hiddenDelete: 'hidden'})
    setTimeout(() => {
      this.setState({showOverlayDelete: false});
    }, 500);
  }


  renderOverlayDelete() {
    if(this.state.showOverlayDelete) {
      return (
        <div className={'overlay '+this.state.hiddenDelete}>
          <div className="box text">
            <h2 className="mb-20 lft">{lang.t('modal:deleteprogram.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayDelete()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-30">{lang.t('modal:deleteprogram.text')}</p>
            <button className="btn primary" onClick={() => this.deleteProgram()}>{lang.t('modal:deleteprogram.button')}</button>
          </div>
        </div>
      )
    }
  }


  deleteProgram() {
    this.hideOverlayDelete()
    Firebase.database().ref('/workouts/'+global.uidUser+'/'+this.state.id).remove()
    .then((data)=>{
      global.message = lang.t('message:programdeleted')
      this.props.history.push('/'+global.uid+'/training/programs')
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Session overlay ------------------------------------------------------------



  showOverlaySession() {
    this.setState({showOverlaySession: true})
    setTimeout(() => {
      this.setState({hiddenSession: ''});
    }, 100);
  }


  hideOverlaySession() {
    this.setState({hiddenSession: 'hidden'})
    setTimeout(() => {
      this.setState({showOverlaySession: false});
    }, 500);
  }


  renderOverlaySession() {
    if(this.state.showOverlaySession) {
      return (
        <div className={'overlay '+this.state.hiddenSession}>
          <div className="box text">
            <h2 className="mb-20 lft">{lang.t('modal:program.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlaySession()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-30">{lang.t('modal:program.text')}</p>
            <button className="btn primary" onClick={() => this.startSession()}>{lang.t('training:button.doprogram')}</button>
          </div>
        </div>
      )
    }
  }


  startSession() {
    this.hideOverlaySession()
    if(this.state.plan !== '' && this.state.isplan) {
      for(var pl of global.plans) {
        if(pl.id === this.state.plan && pl.data.date === undefined) {
          Firebase.database().ref('/plans/'+global.uidUser+'/'+this.state.plan).update({
            date: Moment().format('DD/MM/YYYY HH:mm')
          })
        }
      }
    }
    var obj = Firebase.database().ref('/sessions/'+global.uidUser).push()
    obj.update({
      attendance: 3,
      client: 'Training Session',
      max: 0,
      group: false,
      notes: '',
      duration: this.state.program.data.time,
      preview: true,
      date: Moment().format('DD/MM/YYYY HH:mm'),
      timestamp: parseInt(Moment().format('X')),
      uid: global.uidUser,
      link: '',
      workout: { [this.state.program.id]: this.state.program.data }
    }).then(() => {
      this.props.history.push('/'+global.uid+'/running/'+obj.key)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Video overlay ------------------------------------------------------------



  showOverlayVideo(url) {
    this.setState({
      showOverlayVideo: true,
      url: url
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenVideo: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayVideo() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenVideo: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayVideo: false});
    }, 500);
  }


  renderPlayer() {
    var arr = this.state.url.split('/')
    if(this.state.url.indexOf('youtu') !== -1) {
      var link = arr[arr.length-1]
      if(arr[arr.length-1].indexOf('?')) {
        var ar1 = arr[arr.length-1].split('=')
        var ar11 = ar1[ar1.length-2].split('&')
        link = ar11[0]
      }
      return (
        <iframe width="100%" height="470" src={'https://www.youtube.com/embed/'+link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      )
    } else {
      var ar2 = arr[arr.length-1].split('?')
      return (
        <iframe src={'https://player.vimeo.com/video/'+ar2[0]+'?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'} frameborder="0" width="100%" height="470" title="Video player"></iframe>
      )
    }
  }


  renderOverlayVideo() {
    if(this.state.showOverlayVideo) {
      return (
        <div className={'overlay '+this.state.hiddenVideo}>
          <div className="box mainvideo auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('common:button.watch')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayVideo()}>{lang.t('modal:common.close')}</button>
            <div className="clear"></div>
            {this.renderPlayer()}
          </div>
        </div>
      )
    }
  }



  // Exercise overlay ------------------------------------------------------------



  showOverlayEx(ex) {
    var ext = ''
    var exd = ''
    var exi = ''
    var exv = ''
    var extl = 0
    var id = ex
    var cbest = null
    for(var item of global.exercises) {
      for(var i=0; i<item.data.subTitles.length; i++) {
        if(item.data.subIds[i] === id) {
          ext = item.data.subTitles[i]
          exd = item.data.subDescs[i]
          exi = item.data.subImages[i]
          exv = item.data.subVideos[i]
          extl = item.data.subTools[i]
        }
      }
    }

    for(var bst of global.clientBest) {
      if(bst.id === id) {
        cbest = bst
      }
    }

    this.setState({
      showOverlayEx: true,
      eid: id,
      ext: ext,
      exd: exd,
      exi: exi,
      exv: exv,
      extl: extl,
      cbest: cbest
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenEx: '',
        margin2: -height/2
      });
    }, 100);
  }


  hideOverlayEx() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenEx: 'hidden',
      margin2: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayEx: false});
    }, 500);
  }


  renderExVideo() {
    if(this.state.exv !== '') {
      return (
        <button className="btn tertiary width-12" onClick={() => this.showOverlayVideo(this.state.exv)}>{lang.t('common:button.video')}</button>
      )
    }
  }


  renderExType(type) {
    var label = "kg"
    if(type !== undefined) {
      label = type
    }
    if(type === 'per') {
      label = (global.spaceLbs ? 'lb' : 'kg')
    }
    return label
  }


  renderExHeaviest() {
    if(this.state.cbest !== null) {
      if(this.state.cbest.data.actual !== undefined && this.state.cbest.data.percent !== undefined) {
        if( this.state.cbest.data.percent === 0) {
          return (
            <p className="mb-20"><strong>{lang.t('modal:weight.text2')}:</strong><br/>{this.state.cbest.data.actual.toFixed(1)} {this.renderExType(this.state.cbest.data.type)}<br/>{Moment(this.state.cbest.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM YYYY')}</p>
          )
        } else {
          return (
            <p className="mb-20"><strong>{lang.t('modal:weight.text2')}:</strong><br/>{this.state.cbest.data.actual.toFixed(1)} {this.renderExType(this.state.cbest.data.type)} {lang.t('training:prog.at')} {this.state.cbest.data.percent}%<br/>{Moment(this.state.cbest.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM YYYY')}<br/>{lang.t('modal:weight.text3')}: {this.state.cbest.data.value.toFixed(1)} {this.renderExType(this.state.cbest.data.type)}</p>
          )
        }
      } else {
        return (
          <p className="mb-20"><strong>{lang.t('modal:weight.text2')}:</strong><br/>{this.state.cbest.data.value.toFixed(1)} {this.renderExType(this.state.cbest.data.type)}<br/>{Moment(this.state.cbest.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM YYYY')}</p>
        )
      }
    }
  }


  renderLastUsed() {
    if(global.exToolsWeight.indexOf(this.state.extl) !== -1) {
      return (
        <p><strong>{lang.t('modal:weight.text1')}:</strong><br/>{this.getLast('value')}<br/>{this.getLast('date')}</p>
      )
    }
  }


  renderOverlayEx() {
    if(this.state.showOverlayEx) {
      return (
        <div className={'overlay '+this.state.hiddenEx}   >
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin2}}>
            <h2 className="mb-20 lft">{lang.t('modal:weight.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayEx()}>{lang.t('modal:common.close')}</button>
            <h4 className="mb-20">{this.state.ext}</h4>
            <p className="mb-20">{this.state.exd}</p>
            {this.renderExVideo()}
            {this.renderExHeaviest()}
            {this.renderLastUsed()}
          </div>
        </div>
      )
    }
  }


  getLast(type) {
    var label1 = '-'
    var label2 = '-'
    var date = Moment('01/01/2000', 'DD/MM/YYYY').format('X')
    for(var item of global.sessions) {
      var sdate = Moment(item.data.date, 'DD/MM/YYYY').format('X')
      var cls = []
      if(item.data.clients !== undefined) { cls = item.data.clients }
      if(item.program.length > 0 && (item.data.client === global.spaceClient || cls.indexOf(global.spaceClient) !== -1)) {
        for(var bl of item.program[0].blocks) {
          if(bl.data.exId.length > 0) {
            for(var i=0; i<bl.data.exId.length; i++) {
              if(bl.data.exId[i] === this.state.eid && parseInt(sdate) >= parseInt(date) && !item.data.group) {
                if(parseInt(sdate) !== parseInt(date) || (parseInt(sdate) === parseInt(date) && bl.data.exResWeight[i] > 0)) {
                  label1 = bl.data.exResWeight[i]+' '+this.renderExType(bl.data.exWeightType[i])
                  if(bl.data.exResReps[i] !== 0) {
                    label1 += ' ('+bl.data.exResReps[i]+' '+lang.t('training:prog.reps')+')'
                  }
                  label2 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM YYYY')
                  date = sdate
                }
              }
              if(bl.data.exId[i] === this.state.eid && parseInt(sdate) >= parseInt(date) && item.data.group) {
                var val = bl.data.exResWeightGroup[i].split('-')
                val.splice(0,1)
                var val2 = bl.data.exResRepsGroup[i].split('-')
                val2.splice(0,1)
                for(var j=0; j<cls.length; j++) {
                  if(cls[j] === this.state.item.id && val.length > j) {
                    if(parseInt(sdate) !== parseInt(date) || (parseInt(sdate) === parseInt(date) && val[j] > 0)) {
                      label1 = val[j]+' '+this.renderExType(bl.data.exWeightType[i])
                      if(val2[j] !== 0 && val2[j] !== undefined) {
                        label1 += ' ('+val2[j]+' '+lang.t('training:prog.reps')+')'
                      }
                      label2 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM YYYY')
                      date = sdate
                    }
                  }
                }
                
              }
            }
          }
        }
      }
    }
    for(var item2 of global.sessionsTraining) {
      var sdate2 = Moment(item2.data.date, 'DD/MM/YYYY')
      if(item2.program.length > 0 && item2.data.client === global.spaceClient) {
        for(var bl2 of item2.program[0].blocks) {
          if(bl2.data.exId.length > 0) {
            for(var i2=0; i2<bl2.data.exId.length; i2++) {
              if(bl2.data.exId[i2] === this.state.eid && parseInt(sdate) >= parseInt(date)) {
                if(parseInt(sdate) !== parseInt(date) || (parseInt(sdate) === parseInt(date) && bl2.data.exResWeight[i] > 0)) {
                  label1 = bl2.data.exResWeight[i2]+' '+this.renderExType(bl2.data.exWeightType[i2])
                  if(bl2.data.exResReps[i2] !== 0 && bl2.data.exResReps[i2] !== undefined) {
                    label1 += ' ('+bl2.data.exResReps[i2]+' '+lang.t('training:prog.reps')+')'
                  }
                  label2 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM YYYY')
                  date = sdate2
                }
              }
            }
          }
        }
      }
    }
    if(type === 'value') {
      return label1
    } else {
      return label2
    }
    
  }



  // Non-editing stuff ------------------------------------------------------------



  renderSetsNameStatic(item) {
    var label = lang.t('training:prog.set')
    if(item.exId.length > 1) {
      var prev = ''
      var multiple = true
      for(var ex of item.exId) {
        if(ex !== prev && prev !== '') {
          label = lang.t('training:prog.superset')
          multiple = false
        }
        if(ex === prev && prev !== '') {
          label = lang.t('training:prog.dropset')
        }
        prev = ex
      }
      if(multiple) {
        label = lang.t('training:prog.dropset')
      } else {
        label = lang.t('training:prog.superset')
      }
    }
    return label
  }


  renderBlockTypeStatic(item) {
    var label = ''
    var s = lang.t('training:prog.round')
    if(item.rounds > 1) {
      s = lang.t('training:prog.rounds')
    }
    if(item.type === 0) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
    }
    if(item.type === 1) {
      label = ' - '+item.rounds+' '+s
      if(item.emom) {
        label = ' - '+item.rounds+' '+s+' '+lang.t('training:prog.of')+' '+HelperCal.getDurationMin(parseInt(item.exWork[0]))
      }
    }
    if(item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' '+lang.t('training:prog.rounds')
      if(item.rounds === 1) {
        label = ' - 1 '+lang.t('training:prog.round')
      }
      if(item.type === 2 && item.emom) { 
        if(item.exRest[0] === 0) {
          label += ' '+lang.t('training:prog.of')+' '+HelperCal.getDurationMin(parseInt(item.exWork[0]))+' '+lang.t('training:prog.worknorest')
        } else {
          label += ' '+lang.t('training:prog.of')+' '+HelperCal.getDurationMin(parseInt(item.exWork[0]))+' '+lang.t('training:prog.work')+' & '+HelperCal.getDurationMin(parseInt(item.exRest[0]))+' '+lang.t('training:prog.rest')
        }
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' '+this.renderSetsNameStatic(item)+'s'
      if(item.rounds === 1) {
        label = ' - 1 '+this.renderSetsNameStatic(item)
      }
    }
    if(item.type === 5) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
      if(item.cycles > 1) {
        label = ' - '+item.cycles+' '+lang.t('training:prog.rounds')+' - '+lang.t('training:prog.cap')+': '+HelperCal.getDurationMin(item.rounds)
      }
    }
    return label
  }


  renderRepsStatic(item, index) {
    var label = item.exReps[index]
    if(item.exRepsRounds !== undefined && item.exRepsRounds !== '') {
      if(item.exRepsRounds.length > index) {
        if(item.exRepsRounds[index] !== '') {
          label = item.exRepsRounds[index].replaceAll('-', ' - ')
        }
      }
    }
    if(item.exUnits !== undefined) {
      if(item.exUnits.length > index) {
        if(item.exUnits[index] === 'dist') {
          label += 'm'
        }
        if(item.exUnits[index] === 'cals') {
          label += 'cal'
        }
        if(item.exUnits[index] === 'time') {
          label += 's'
        }
      }
    } else {
      if(item.exTool[index] === 6 || item.exTool[index] === 7) {
        label += 'm'
      }
      if(item.exTool[index] === 27) {
        label += 'cal'
      }
    }
    if(item.exReps[index] === 0) {
      label = ''
    }
    return label
  }


  renderWeightStatic(item, index) {
    if(item.exWeight[index] !== 0) {
      var label = lang.t('training:prog.at')+' '+item.exWeight[index]+'% '+lang.t('training:prog.of1rm')
      if(item.exWeightRounds !== undefined && item.exWeightRounds !== '') {
        label = lang.t('training:prog.at')+' '+item.exWeightRounds[index].replaceAll('-', ' - ')+'% '+lang.t('training:prog.of1rm')
      }
      if(item.exWeightType !== undefined) {
        if(item.exWeightType.length > index && (item.exWeightType[index] === 'kg' || item.exWeightType[index] === 'lb')) {
          label = lang.t('training:prog.with')+' '+item.exWeight[index]+' '+item.exWeightType[index]
          if(item.exWeightRounds !== undefined && item.exWeightRounds !== '') {
            label = lang.t('training:prog.with')+' '+item.exWeightRounds[index].replaceAll('-', ' - ')+' '+item.exWeightType[index]
          }
        }
      }
      return label
    }
  }


  renderTimeStatic(block, index) {
    if((block.type === 1 || block.type === 2) && !block.emom) {
      return (
        <div className="col-2 program-time">
          <p>{HelperCal.getDurationMin(parseInt(block.exWork[index]))}</p>
        </div>
      )
    }
  }


  renderNotesStatic(item, index) {
    if(item.exNotes !== undefined) {
      if(item.exNotes.length > index) {
        if(item.exNotes[index] !== '') {
          return (
            <span className="program-notes movement"><br/>{item.exNotes[index]}</span>
          )
        }
      }
    }
  }


  renderExerciseRest(block, index) {
    if(block.type === 2 && !block.emom && block.exRest[index] !== 0 && block.exRest[index] !== undefined && block.exRest[index] !== '') {
      return (
        <div className="box list simple highlight mb-10">
          <div className="col-10">
            <div className="icon secondary lft mr-10">
              <div className="inner prog-rest"></div>
            </div>
            <p className="lft"><strong>{lang.t('training:label.rest')}</strong></p>
          </div>
          <div className="col-2 program-time">
            <p>{HelperCal.getDurationMin(parseInt(block.exRest[index]))}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderExercisesStatic(num) {
    var block = this.state.program.blocks[num].data
    if(!block.simple) {
      return (
        <div>
          {block.exName.map((item, index) => (
            <div key={index}>
              <div className="box list simple highlight mb-10">
                <div className="col-10">
                  <div className="icon primary lft mr-10">
                    <div className={'inner '+global.exCatsIcons[block.cat]}></div>
                  </div>
                  <p className="lft" style={{width: 'calc(100% - 60px)'}}><strong>{this.renderRepsStatic(block, index)} {item}</strong> {this.renderWeightStatic(block, index)}{this.renderNotesStatic(block, index)}</p>
                </div>
                {this.renderTimeStatic(block, index)}
                <div className="clear"></div>
              </div>
              {this.renderExerciseRest(block, index)}
            </div>
          ))}
        </div>
      )
    }
  }


  renderStaticNotes(item) {
    if(item.data.notes !== '') {
      if(item.data.simple !== undefined) {
        return <p className="mb-20 pl-10" style={{whiteSpace: 'pre-wrap'}}>{item.data.notes}</p>
      } else {
        return <p className="small mb-20 pl-10" style={{whiteSpace: 'pre-wrap'}}>{item.data.notes}</p>
      }
    }
  }


  renderStaticRest(block) {
    if(block.type === 4 && block.exWork[0] !== 0 && block.exWork[0] !== undefined) {
      return (
        <div className="box list simple highlight mb-10">
          <div className="col-10">
          <div className="icon secondary lft mr-10">
              <div className="inner prog-rest"></div>
            </div>
            <p className="lft"><strong>{lang.t('training:label.rest')}</strong></p>
          </div>
          <div className="col-2 program-time">
            <p>{HelperCal.getDurationMin(parseInt(block.exWork[0]))}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderBlockResultsStatic(item) {
    if(item.data.logResults !== undefined && !item.data.logResults) {
      return <span className="pill primary rgt">{lang.t('training:label.notlogged')}</span>
    }
  }


  renderBlockTitleStatic(item) {
    if(item.data.name !== '' && item.data.name !== undefined) {
      return item.data.name
    } else {
      return lang.t(global.exCats[item.data.cat])
    }
  }


  renderBlocksStatic() {
    return (
      <div>
        {this.state.program.blocks.map((item, index) => ( 
          <div key={index}>
            <div className="clear mb-50">
              <label>{this.renderBlockTitleStatic(item)}</label>
              <h3 className="clear lft mb-20">{lang.t(global.proTypes[item.data.type])} {this.renderBlockTypeStatic(item.data)}</h3>
              {this.renderBlockResultsStatic(item)}
              <div className="clear"></div>
              {this.renderExercisesStatic(index)}
              {this.renderStaticRest(item.data)}
              {this.renderStaticNotes(item)}
            </div>
          </div>
        ))}
      </div>
    )
  }



  // Display stuff ------------------------------------------------------------



  renderBenchmarkPill() {
    if(this.state.program.data.benchmark) {
      return (
        <p className="mb-20">
          <span className="pill primary">{lang.t('training:label.benchmark')}</span>
        </p>
      )
    }
  }


  renderButtonVideo() {
    if(this.state.program.data.video !== undefined && this.state.program.data.video !== "") {
      if(this.state.program.data.video.indexOf('folder') !== -1 || this.state.program.data.video.indexOf('playlist') !== -1) {
        return (
          <div>
            <a className="btn tertiary width-12" href={this.state.program.data.video} target="_blank" rel="noopener noreferrer">{lang.t('common:button.video')}</a>
            <div className="space-50"></div>
          </div>
        )
      } else {
        return (
          <div>
            <button className="btn tertiary width-12" onClick={() => this.showOverlayVideo(this.state.program.data.video)}>{lang.t('common:button.video')}</button>
            <div className="space-50"></div>
          </div>
        )
      }
    }
  }


  renderContent() {
    if(this.state.program !== null) {
      return (
        <div>
          <div className="program-header mb-60">
            <div className={'icon large lft mr-20 '+HelperCal.getColor(this.state.program.data.time)}>
              <p>{this.state.program.data.time}'</p>
            </div>
            <div className="main">
              <h2>{this.state.program.data.name}</h2>
              {this.renderBenchmarkPill()}
              <p className="mb-10">{this.state.program.data.desc}</p>
              {this.renderButtonVideo()}
            </div>
            <div className="clear"></div>
          </div>
          {this.renderBlocksStatic()}
        </div>
      )
    }
  }


  renderSession() {
    var label = 'Session'
    if(this.state.session !== '') {
      for(var item of global.sessions) {
        if(item.id === this.state.session) {
          if(item.data.group) {
            if(item.data.client !== '') {
              label = item.data.client+' - '+Moment(item.data.timestamp, 'X').locale(lang.language).format('ddd, D MMM - LT')
            } else {
              label = 'Group Session - '+Moment(item.data.timestamp, 'X').locale(lang.language).format('ddd, D MMM - LT')
            }
          } else {
            label = 'PT Session - '+Moment(item.data.timestamp, 'X').locale(lang.language).format('ddd, D MMM - LT')
          }
        }
      }
    }
    return label
  }


  renderButtons() {
    if(this.state.type === 'program' && !this.state.isplan) {
      return (
        <div>
          <div className="col-4">&nbsp;</div>
          <div className="col-4">
            <button className="btn primary mb-30" onClick={() => this.showOverlaySession()}>{lang.t('training:button.doprogram')}</button>
          </div>
          <button className="btn tertiary width-12 close clear" onClick={() => this.showOverlayDelete()}>{lang.t('training:button.deleteprogram')}</button>
          <div className="sv-40"></div>
        </div>
      )
    } else if(this.state.type === 'program' && this.state.isplan) {
      return (
        <div>
          <div className="col-4">&nbsp;</div>
          <div className="col-4">
            <button className="btn primary" onClick={() => this.showOverlaySession()}>{lang.t('training:button.doprogram')}</button>
          </div>
          <div className="clear sv-40"></div>
        </div>
      )
    }
  }


  renderBase() {
    if(this.state.type === 'session') {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar mt-20">
            {this.renderContent()}
          </div>
          <div className="header">
            <div className="content">
              <Link to={'/'+global.uid+'/session/'+this.state.session} className="back lft mr-10">
                <div className="circle"></div>
                <div className="arrow"></div>
              </Link>
              <h2>{this.renderSession()}</h2>
            </div>
          </div>
          <Navigation active='training' />
          {this.renderOverlayVideo()}
          {this.renderOverlayEx()}
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar mt-20">
            {this.renderContent()}
            {this.renderButtons()}
          </div>
          <div className="header">
            <div className="content">
              <Link to={!this.state.isplan ? '/'+global.uid+'/training/programs' : '/'+global.uid+'/training/plan/'+this.state.plan} className="back lft mr-10">
                <div className="circle"></div>
                <div className="arrow"></div>
              </Link>
              <h2>{lang.t('header:title.program')}</h2>
            </div>
          </div>
          <Navigation active='training' />
          {this.renderOverlayDelete()}
          {this.renderOverlaySession()}
          {this.renderOverlayEx()}
          {this.renderOverlayVideo()}
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['header','common','training','modal','message'])(withRouter(withAuthorizationMember(condition)(MemberProgramPage)));