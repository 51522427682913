import React, { Component } from 'react';
import Connector from '../../data/Connector';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import { gql } from "@apollo/client";
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberMealPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      meal: null,
      recipes: [],
      recipe: null,
      checked: false,
      preferences: [],
      like: -1,
      rid: 0,
      showOverlaySwap: false,
      hiddenSwap: 'hidden',
      loading: false,
      reload: false
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-4]
    if(id !== 'health' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('health:label.meal')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }

    if(global.nutritionMeal !== null && global.nutritionRecipes !== null) {
      var tmp = null
      var like = -1
      var rid = 0
      var prefs = []
      for(var item of global.nutritionRecipes.recipes) {
        if(item.id === global.nutritionMeal.data.recipe_id) {
          tmp = item
        }
      }
      if(global.nutritionData !== null) {
        prefs = global.nutritionData.users[0].recipe_preferences
        for(var item2 of prefs) {
          if(item2.recipe_id === tmp.id) {
            rid = item2.id
            like = item2.rating
          }
        }
      }
      
      this.setState({
        meal: global.nutritionMeal,
        recipe: tmp,
        checked: global.nutritionMeal.data.checked,
        preferences: prefs,
        like: like,
        rid: rid
      })
    }

    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    this.configureData()

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('health:label.meal')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    if(global.nutritionRecipes !== null) {
      this.setState({
        recipes: global.nutritionRecipes.recipes,
      })
    }
    this.setState({
      done: true
    })
  }


  getMeal() {
    var label = lang.t('common:label.loading')+'...'
    if(this.state.meal !== null) {
      for(var item of global.nutritionRecipes.recipes) {
        if(item.id === this.state.meal.data.recipe_id) {
          label = item.name
        }
      }
    }
    return label
  }


  getMealPhoto(id) {
    var label = ''
    for(var item of global.nutritionRecipes.recipes) {
      if(item.id === id) {
        label = item.photo_file_name
      }
    }
    return label
  }


  getMealLink(id) {
    var start = '000000000'+String(id)
    var total = start.substr(String(id).length, start.length)
    var label = total.substr(0, 3)+'/'+total.substr(3, 3)+'/'+total.substr(6, 3)
    return label
  }


  checkMeal() {
    var chk = !this.state.checked
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation updateMealChecked($id: Int!, $checked: Boolean) {
        update_meals_by_pk(pk_columns: {id: $id}, _set: {checked: $checked}) {
          checked
        }
      }
    `;
    client.mutate({
      variables: { id: parseInt(this.state.meal.data.id), checked: chk },
      mutation
    }).then((result) => {
      this.setState({
        checked: chk,
        reload: true
      })
    }).catch((error) => {
      console.log(error);
    });
  }


  likeMeal(val) {
    var value = val
    if(this.state.like === value) {
      value = -1
    }

    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation updateMealLike($id: Int!, $value: Int!) {
        update_recipe_preferences_by_pk(pk_columns: {id: $id}, _set: {rating: $value}) {
          id
          rating
        }
      }
    `;
    client.mutate({
      variables: { id: parseInt(this.state.rid), value: value },
      mutation
    }).then((result) => {
      this.setState({
        like: value,
        reload: true
      })
    }).catch((error) => {
      console.log(error);
    });
  }



  // Swap overlay ------------------------------------------------------------



  showOverlaySwap() {
    this.setState({
      showOverlaySwap: true,
    })
    setTimeout(() => {
      this.setState({
        hiddenSwap: '',
      });
    }, 100);
  }


  hideOverlaySwap() {
    this.setState({
      hiddenSwap: 'hidden',
    })
    setTimeout(() => {
      this.setState({showOverlaySwap: false});
    }, 500);
  }


  renderOverlaySwap() {
    if(this.state.showOverlaySwap) {
      var list = []
      var id = this.state.meal.data.next_meal_id
      //do {
        for(var pl of global.nutritionData.users[0].plans) {
          for(var ml of pl.meals) {
            if(ml.id === id && list.indexOf(id) === -1 && ml.id !== this.state.meal.data.id) {
              list.push(ml.id)
              id = ml.next_meal_id
            }
          }
        }
      return (
        <div className={'overlay '+this.state.hiddenSwap}>
          <div className="box clients">
            <h2 className="mb-20 lft">{lang.t('modal:meal.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlaySwap()}>{lang.t('modal:common.close')}</button>
            <div className="clear"></div>
            <div className="scroll">
              <p className="mb-30">{lang.t('modal:meal.text')}</p>
              {list.map(item => (
                <div>
                  <img src={'https://backtomybody-dev.s3.amazonaws.com/recipes/photos/'+this.getMealLink(this.getMealRecipe(item))+'/medium/'+this.getMealPhoto(this.getMealRecipe(item))} style={{width: '100%'}} alt={this.getMealName(item)}/>
                  <h4 className="mt-20 mb-20">{this.getMealName(item)}</h4>
                  <button className="btn primary" onClick={() => this.swapMeal(item)}>{lang.t('modal:meal.button')}</button>
                  <div className="sv-50" clear></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
    }
  }


  getMealName(mid) {
    var label = 'Meal'
    var id = 0
    for(var pl of global.nutritionData.users[0].plans) {
      for(var ml of pl.meals) {
        if(ml.id === mid) {
          id = ml.recipe_id
        }
      }
    }
    for(var item of global.nutritionRecipes.recipes) {
      if(item.id === id) {
        label = item.name
      }
    }
    return label
  }


  getMealRecipe(mid) {
    var label = ''
    for(var pl of global.nutritionData.users[0].plans) {
      for(var ml of pl.meals) {
        if(ml.id === mid) {
          label = ml.recipe_id
        }
      }
    }
    return label
  }


  swapMeal(item) {
    this.hideOverlaySwap()
    this.setState({
      loading: true
    })
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation swapMeal($id1: Int!, $id2: Int!) {
        swapMeals(meal1: $id1, meal2: $id2) {
          response
        }
      }
    `;

    client.mutate({
      variables: { id1: parseInt(this.state.meal.data.id), id2: parseInt(item) },
      mutation
    }).then((result) => {
      setTimeout(() => {
        this.setState({
          loading: false,
          reload: true
        }, () => {
          global.nutritionData = null
          global.nutritionWeeks = null
          this.props.history.push('/'+global.uid+'/health/nutrition/')
        })
      }, 6000);
    }).catch((error) => {
      console.log(error);
    });
  }



  // Display stuff ------------------------------------------------------------



  renderStatus() {
    if(Moment(global.nutritionDate, 'YYYY-MM-DD') < Moment()) {
      if(this.state.checked) {
        return (
          <div className="infobox small green mb-20">
            <p>{lang.t('health:meal.checked')} <button className="btn tertiary small" onClick={() => this.checkMeal()}>{lang.t('health:button.uncheck')}</button></p>
          </div>
        )
      } else {
        return (
          <div className="infobox small yellow mb-20">
            <p>{lang.t('health:meal.notchecked')} <button className="btn tertiary small" onClick={() => this.checkMeal()}>{lang.t('health:button.check')}</button></p>
          </div>
        )
      }
    } 
  }


  renderLike() {
    if(Moment(global.nutritionDate, 'YYYY-MM-DD') < Moment()) {
      return (
        <div className="radios mt-20">
          <div className={this.state.like === 2 ? 'radio active col-6 pt-10' : 'radio col-6 pt-10'} onClick={() => this.likeMeal(2)}>{lang.t('health:meal.like')}</div>
          <div className={this.state.like === 0 ? 'radio active col-6 pt-10' : 'radio col-6 pt-10'} onClick={() => this.likeMeal(0)}>{lang.t('health:meal.dislike')}</div>
        </div>
      )
    }
  }


  renderData() {
    if(this.state.recipe !== null) {
      return (
        <div className="mb-50">
          <div className="info">
            <label>{lang.t('health:log.protein')}</label>
            <p>{this.state.recipe.protein.toFixed(1)}g</p>
          </div>
          <div className="info">
            <label>{lang.t('health:log.sugar')}</label>
            <p>{this.state.recipe.sugars.toFixed(1)}g</p>
          </div>
          <div className="info">
            <label>{lang.t('health:log.carbs')}</label>
            <p>{this.state.recipe.total_carbs.toFixed(1)}g</p>
          </div>
          <div className="info">
            <label>{lang.t('health:log.fat')}</label>
            <p>{this.state.recipe.total_fat.toFixed(1)}g</p>
          </div>
        </div>
      )
    }
  }


  renderType() {
    var label = lang.t('health:meal.breakfast')
    if(this.state.meal.data.breakfast !== null) {
      label = lang.t('health:meal.breakfast')
    }
    if(this.state.meal.data.snack_morning !== null) {
      label = lang.t('health:meal.morning')
    }
    if(this.state.meal.data.snack_afternoon !== null) {
      label = lang.t('health:meal.afternoon')
    }
    if(this.state.meal.data.snack_evening !== null) {
      label = lang.t('health:meal.evening')
    }
    if(this.state.meal.data.lunch !== null) {
      label = lang.t('health:meal.lunch')
    }
    if(this.state.meal.data.dinner !== null) {
      var additional = 0
      if(global.nutritionData.users[0].leftover_lunches) {
        additional = 1
      }
      label = lang.t('health:meal.dinner')+' ('+(this.state.meal.data.serve_now_servings+additional)+' '+((this.state.meal.data.serve_now_servings+additional) === 1 ? lang.t('health:meal.serving') : lang.t('health:meal.servings'))+')'
    }
    if(this.state.meal.data.dessert !== null) {
      label = lang.t('health:meal.dessert')
    }
    return label
  }


  renderMethod() {
    if(this.state.recipe !== null) {
      var list = this.state.recipe.method.split('.')
      return (
        <p>
          {list.map(item => (
            <span key={item}>{item}<br/></span>
          ))}
        </p>
      )
    }
  }


  renderIngredient(item) {
    var label = ""
    var additional = 0
    if(global.nutritionData.users[0].leftover_lunches) {
      additional = 1
    }
    var amount = item.ingredient.base_amount*this.state.meal.data.size*item.size*(this.state.meal.data.serve_now_servings+additional)
    var incr = 1
    if(item.ingredient.increments !== null) {
      incr = item.ingredient.increments
    }
    if(amount % incr !== 0) {
      var rest = amount % incr
      amount = amount-rest+incr
    }

    label+= amount+" "+item.ingredient.measurement+" x "+(item.ingredient.kind === null ? '' : item.ingredient.kind)+" "+item.ingredient.name
    return label
  }


  renderSwap() {
    if(Moment(global.nutritionDate, 'YYYY-MM-DD') > Moment()) {
      return (
        <button className="btn secondary mt-20" onClick={() => this.showOverlaySwap()}>{lang.t('health:button.viewoptions')}</button>
      )
    }
  }


  renderContent() {
    if(this.state.meal !== null && this.state.recipe !== null) {
      return (
        <div>
          <div className="col-6">
            <div className="box">
              {this.renderStatus()}
              <img src={'https://backtomybody-dev.s3.amazonaws.com/recipes/photos/'+this.getMealLink(this.state.meal.data.recipe_id)+'/medium/'+this.getMealPhoto(this.state.meal.data.recipe_id)} style={{width: '100%'}} alt={this.getMeal()}/>
              <div className="sv-30 clear"></div>
              <h4>{lang.t('health:meal.preparation')} ({this.state.recipe.cooking_time} min)</h4>
              <div className="sv-20"></div>
              {this.renderMethod()}
              {this.renderLike()}
              {this.renderSwap()}
            </div>
          </div>
          <div className="col-6 sidebar">
            <h2 className="mb-20">{this.getMeal()}</h2>
            <h3 className="mb-20">{this.renderType()}</h3>
            <div className="mb-50">
              <div className="info">
                <label>{lang.t('health:meal.calories')}</label>
                <p>{parseInt(this.state.meal.data.calories)} cal</p>
              </div>
              <div className="info">
                <label>{lang.t('health:meal.dailytarget')}</label>
                <p>{parseInt(global.nutritionCals)} cal</p>
              </div>
            </div>
            <h3 className="mb-20">{lang.t('health:meal.info')}</h3>
            {this.renderData()}
            <h3 className="mb-20">{lang.t('health:meal.ingredients')}</h3>
            <ul className="links">
              {this.state.recipe.amounts.map(item => (
                <li key={item.ingredient.id}>{this.renderIngredient(item)}</li>
              ))}
            </ul>
          </div>
          <div className="sv-40 clear"></div>
        </div>
      )
    }
  }


  renderLoading() {
    if(this.state.loading) {
      return (
        <div className="loading">
          <div className="loader-box">
            <span className="loader"><span className="loader-inner"></span></span>
          </div>
          <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('message:loading.nutrition')}</p>
        </div>
      )
    }
  }


  goBack() {
    if(this.state.reload) {
      global.nutritionData = null
      global.nutritionWeeks = null
    }
    this.props.history.push('/'+global.uid+'/health/nutrition')
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-sidebar mt-20">
          {this.renderContent()}
        </div>
        <div className="header">
          <div className="content">
            <div className="back lft mr-10" onClick={() => this.goBack()}>
              <div className="circle"></div>
              <div className="arrow"></div>
            </div>
            <h2>{lang.t('health:label.meal')}</h2>
          </div>
        </div>
        <Navigation active='health' />
        {this.renderOverlaySwap()}
        {this.renderLoading()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['header','health','common','modal','message'])(withRouter(withAuthorizationMember(condition)(MemberMealPage)));