import React, { Component } from 'react';
import Connector from '../../data/Connector';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperEmptyImg from '../../helper/EmptyLarge';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberTrainingPlansPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      plans: global.plans
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    if(id !== 'training' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.training')+' - '+global.userBusiness
    window.scrollTo(0, 0)
    global.linkPro = ''

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('plansLoaded', (event) => this.configureData())
    this.configureData()

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.training')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    this.setState({
      plans: global.plans
    })
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }


  getStatus(item, type) {
    var color = ''
    var label = lang.t('training:label.notstarted')
    if(item.data.date !== undefined && item.data.date !== null) {
      color = 'darkblue'
      label = lang.t('training:label.started')+' '+Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM YYYY')
      if(Moment() > Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(item.weeks.length+4, 'weeks')) {
        color = ''
        label = lang.t('training:label.finished')
      }
    }
    if(type === 'color') {
      return color
    } else {
      return label
    }
  }


  calculateCompletion(item) {
    var done = 0
    var total = 0
    var ids = []
    var pids = []

    var start = Moment(item.data.date, 'DD/MM/YYYY HH:mm').startOf('day')
    for(var i=0; i<item.weeks.length; i++) {
      var d1 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(7*i, 'days')
      var d2 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+1, 'days')
      var d3 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+2, 'days')
      var d4 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+3, 'days')
      var d5 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+4, 'days')
      var d6 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+5, 'days')
      var d7 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+6, 'days')

      var ar1 = item.weeks[i].data.day1.split(',')
      var ar2 = item.weeks[i].data.day2.split(',')
      var ar3 = item.weeks[i].data.day3.split(',')
      var ar4 = item.weeks[i].data.day4.split(',')
      var ar5 = item.weeks[i].data.day5.split(',')
      var ar6 = item.weeks[i].data.day6.split(',')
      var ar7 = item.weeks[i].data.day7.split(',')
      ar1.splice(0, 1)
      ar2.splice(0, 1)
      ar3.splice(0, 1)
      ar4.splice(0, 1)
      ar5.splice(0, 1)
      ar6.splice(0, 1)
      ar7.splice(0, 1)

      if(d1 < Moment()) {
        total += ar1.length
      }
      if(d2 < Moment()) {
        total += ar2.length
      }
      if(d3 < Moment()) {
        total += ar3.length
      }
      if(d4 < Moment()) {
        total += ar4.length
      }
      if(d5 < Moment()) {
        total += ar5.length
      }
      if(d6 < Moment()) {
        total += ar6.length
      }
      if(d7 < Moment()) {
        total += ar7.length
      }

      var art = []
      for(var a1 of ar1) { art.push(a1) }
      for(var a2 of ar2) { art.push(a2) }
      for(var a3 of ar3) { art.push(a3) }
      for(var a4 of ar4) { art.push(a4) }
      for(var a5 of ar5) { art.push(a5) }
      for(var a6 of ar6) { art.push(a6) }
      for(var a7 of ar7) { art.push(a7) }

      for(var session of global.sessionsTraining) {
        
        var date = Moment(session.data.date, 'DD/MM/YYYY HH:mm')
        if(date > start && art.indexOf(session.program[0].id) !== -1) {
          if(session.data.attendance === 3 && ids.indexOf(session.id) === -1 && pids.indexOf(session.program[0].id) === -1) {
            done++
            ids.push(session.id)
            pids.push(session.program[0].id)
          }
        }
      }
    }
    var val = parseInt((done/total)*100)
    if(Moment() > Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(item.weeks.length+4, 'weeks')) {
      return '-'
    } else {
      return val+'%'
    }
    
  }



  // Display stuff ------------------------------------------------------------



  renderPlans() {
    if(this.state.plans.length === 0) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('plans')}
          </div>
          <div className="data">
            <h3 className="mb-20">{lang.t('empty:plans.title')}</h3>
            <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('empty:plans.text')}</p>
          </div>
        </div>
      )
    } else {
      var list = this.state.plans.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          {list.map(item => (
            <div className="col-2" key={item.id}>
              <div className="box card medium highlight mb-20">
                <Link to={'/'+global.uid+'/training/plan/'+item.id}>
                  <div className="icon primary mb-20"><p style={{color: '#ffffff'}}>{this.calculateCompletion(item)}</p></div>
                  <h4 className="mb-10 clear">{item.data.name}</h4>
                  <p className="mb-10">{item.weeks.length} {item.weeks.length === 1 ? lang.t('training:label.week') : lang.t('training:label.weeks')}<br/>{this.getStatus(item, 'label')}</p>
                  <div className="sv-30"></div>
                  <button className="btn tertiary small width-12">{lang.t('common:button.viewdetails')}</button>
                  <div className="clear"></div>
                </Link>
              </div>
            </div>
          ))}
          <div className="sv-40"></div>
        </div>
      )
    }
  }


  renderContent() {
    return (
      <div>
        <div className="listheader">
          <h3>{lang.t('header:training.plans')}</h3>
          <div className="clear"></div>
        </div>
        {this.renderPlans()}
      </div>
    )
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content member">
          <div className="member-header mb-10">
            <div className={this.getBg()}>
              <div className={'gradient theme-'+global.spaceTheme}></div>
            </div>
            <div className="member-main">
              <div className="business">
                <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
                <h3>{global.userBusiness}<br/><span>{lang.t('header:title.memberarea')}</span></h3>
              </div>
              <h2 className="mb-30">{lang.t('header:title.training')}</h2>
              <Link to={'/'+global.uid+'/training/sessions'} className="tab">
                <p>{lang.t('nav:training.sessions')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/training/programs'} className="tab">
                <p>{lang.t('nav:training.programs')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/training/plans'} className="tab active">
                <p>{lang.t('nav:training.plans')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/training/progress'} className="tab">
                <p>{lang.t('nav:training.progress')}</p>
                <div className="bg"></div>
              </Link>
            </div>
            <div className="clear"></div>
          </div>
          {this.renderContent()}
        </div>
        <Navigation active='training' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['nav','header','common','empty','training'])(withRouter(withAuthorizationMember(condition)(MemberTrainingPlansPage)));