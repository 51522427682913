import React, { Component } from 'react';
import Firebase from 'firebase';
import Connector from '../../data/Connector';
import Moment from 'moment';
import 'moment/locale/es';
import HelperEmptyImg from '../../helper/EmptyLarge';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberAdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      forms: global.forms
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    if(id !== 'admin' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.admin')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('documentsLoaded', (event) => this.configureData())
    this.configureData()

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.admin')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    this.setState({
      forms: global.forms
    })
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }


  getLink(link) {
    Firebase.storage().ref().child(link).getDownloadURL().then((url) => {
      window.open(url, "_blank");
    })
  }


  getDocClass(item) {
    var label = 'icon primary'
    var icon = 'inner doc'
    if(item === 'pdf') {
      label = 'icon red'
      icon = 'inner pdf'
    } else if(item === 'doc' || item === 'docx') {
      label = 'icon secondary'
      icon = 'inner docx'
    } else if(item === 'jpg' || item === 'png' || item === 'jpeg' || item === 'gif') {
      label = 'icon quarternary'
      icon = 'inner img'
    }
    return [label, icon]
  }



  // Display stuff ------------------------------------------------------------



  renderDocuments() {
    if(global.documents.length > 0) {
      var list = global.documents
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          <div className="listheader">
            <h3>{lang.t('header:admin.documents')}</h3>
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id} onClick={() => this.getLink('/documents/'+global.uid+'/'+item.id+'.'+item.data.ext)}>
              <div className="col-6">
                <div className={this.getDocClass(item.data.ext)[0]}>
                  <div className={this.getDocClass(item.data.ext)[1]}></div>
                </div>
                <p><strong>{item.data.name}</strong></p>
              </div>
              <div className="col-5 center"><p>{lang.t('admin:label.uploaded')} {Moment(item.data.date, 'X').locale(lang.language).format('D MMM YYYY')}</p></div>
              <div className="col-1">
                <button className="btn tertiary small rgt">{lang.t('common:button.view')}</button>
              </div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderContent() {
    if(this.state.forms.length === 0) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('documents')}
          </div>
          <div className="data">
            <h3 className="mb-20">{lang.t('empty:forms.title')}</h3>
            <p>{lang.t('empty:forms.text')}</p>
          </div>
        </div>
      )
    } else {
      var list = this.state.forms
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id}>
              <Link to={'/'+global.uid+'/form/'+item.id}>
                <div className="col-6">
                  <div className="icon quarternary">
                    <div className="inner form"></div>
                  </div>
                  <p><strong>{item.data.name}</strong></p>
                </div>
                <div className="col-5 center"><p>{item.data.date === undefined ? '- '+lang.t('admin:label.notcompleted')+' -' : lang.t('admin:label.completed')+' '+Moment(item.data.date, 'X').locale(lang.language).format('D MMM YYYY')}</p></div>
                <div className="col-1">
                  <button className="btn tertiary small rgt">{lang.t('common:button.view')}</button>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      )
    }
  }


  renderPayment() {
    if(global.userStripeConnect !== '' && global.products.length > 0) {
      return (
        <Link to={'/'+global.uid+'/admin/payment'} className="tab">
          <p>{lang.t('nav:admin.payment')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }


  renderNavMembership() {
    var show = false
    for(var item of global.products) {
      if(item.data.type === 'subscription') {
        show = true
      }
    }
    if(show) {
      return (
        <Link to={'/'+global.uid+'/admin/newmembership'} className="tab">
          <p>{lang.t('nav:admin.membership')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }


  renderNav() {
    if(global.userParent === '' || global.userParent === undefined) {
      return (
        <div>
          <Link to={'/'+global.uid+'/admin/forms'} className="tab active">
            <p>{lang.t('nav:admin.forms')}</p>
            <div className="bg"></div>
          </Link>
          {this.renderPayment()}
          {this.renderNavMembership()}
          <Link to={'/'+global.uid+'/admin/payments'} className="tab">
            <p>{lang.t('nav:admin.history')}</p>
            <div className="bg"></div>
          </Link>
          <Link to={'/'+global.uid+'/admin/card'} className="tab">
            <p>{lang.t('nav:admin.card')}</p>
            <div className="bg"></div>
          </Link>
        </div>
      )
    } else {
      return (
        <Link to={'/'+global.uid+'/admin/forms'} className="tab active">
          <p>{lang.t('nav:admin.forms')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content member">
          <div className="member-header mb-10">
            <div className={this.getBg()}>
              <div className={'gradient theme-'+global.spaceTheme}></div>
            </div>
            <div className="member-main">
              <div className="business">
                <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
                <h3>{global.userBusiness}<br/><span>{lang.t('header:title.memberarea')}</span></h3>
              </div>
              <h2 className="mb-30">{lang.t('header:title.admin')}</h2>
              {this.renderNav()}
            </div>
            <div className="clear"></div>
          </div>
          <div className="listheader">
            <h3>{lang.t('header:admin.forms')}</h3>
            <div className="clear"></div>
          </div>
          {this.renderContent()}
          {this.renderDocuments()}
          <div className="space-40"></div>
        </div>
        <Navigation active='admin' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['nav','header','empty','admin','common'])(withRouter(withAuthorizationMember(condition)(MemberAdminPage)));