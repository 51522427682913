import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Cal from '../../helper/Calendar';
import Connector from '../../data/Connector';

import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorization } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberPlanPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      client: null,
      name: 'Client',
      plans: global.clientPlans,
      planId: '',
      sessionsTraining: global.sessionsTraining,
      archiveTraining: global.archiveTraining,
      show: 'all',
      showOverlay: false,
      hidden: 'hidden',
      link: '',
      showOverlayDelete: false,
      hiddenDelete: 'hidden',
      showOverlayStart: false,
      hiddenStart: 'hidden',
      showOverlayVideo: false,
      hiddenVideo: 'hidden',
      margin: 0,
      url: '',
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    var pid = arr[arr.length-1]
    if(id !== 'training' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.plan')+' - '+global.userBusiness
    window.scrollTo(0, 0)
    global.linkPro = pid

    this.setState({
      done: true,
      planId: pid
    })

    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('trainingLoaded', (event) => this.configureData())
    EventEmitter.subscribe('plansLoaded', (event) => this.configureData())

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    global.planLocked = true
    this.configureData()
  }


  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.plan')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var item = null
    var arr = this.props.location.pathname.split('/')
    var pid = arr[arr.length-1]
    for(var plan of global.plans) {
      if(plan.id === pid) {
        item = plan
      }
    }
    this.setState({
      sessionsTraining: global.sessionsTraining,
      archiveTraining: global.archiveTraining,
      item: item
    })
  }



  // Info stuff ------------------------------------------------------------



  getCompliance() {
    var done = 0
    var total = 0
    var ids = []
    var pids = []

    var start = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').startOf('day')
    for(var i=0; i<this.state.item.weeks.length; i++) {
      var d1 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*i, 'days')
      var d2 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+1, 'days')
      var d3 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+2, 'days')
      var d4 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+3, 'days')
      var d5 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+4, 'days')
      var d6 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+5, 'days')
      var d7 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+6, 'days')

      var ar1 = this.state.item.weeks[i].data.day1.split(',')
      var ar2 = this.state.item.weeks[i].data.day2.split(',')
      var ar3 = this.state.item.weeks[i].data.day3.split(',')
      var ar4 = this.state.item.weeks[i].data.day4.split(',')
      var ar5 = this.state.item.weeks[i].data.day5.split(',')
      var ar6 = this.state.item.weeks[i].data.day6.split(',')
      var ar7 = this.state.item.weeks[i].data.day7.split(',')
      ar1.splice(0, 1)
      ar2.splice(0, 1)
      ar3.splice(0, 1)
      ar4.splice(0, 1)
      ar5.splice(0, 1)
      ar6.splice(0, 1)
      ar7.splice(0, 1)

      if(d1 < Moment()) {
        total += ar1.length
      }
      if(d2 < Moment()) {
        total += ar2.length
      }
      if(d3 < Moment()) {
        total += ar3.length
      }
      if(d4 < Moment()) {
        total += ar4.length
      }
      if(d5 < Moment()) {
        total += ar5.length
      }
      if(d6 < Moment()) {
        total += ar6.length
      }
      if(d7 < Moment()) {
        total += ar7.length
      }
    
      var art = []
      for(var a1 of ar1) { art.push(a1) }
      for(var a2 of ar2) { art.push(a2) }
      for(var a3 of ar3) { art.push(a3) }
      for(var a4 of ar4) { art.push(a4) }
      for(var a5 of ar5) { art.push(a5) }
      for(var a6 of ar6) { art.push(a6) }
      for(var a7 of ar7) { art.push(a7) }

      for(var session of this.state.sessionsTraining) {
        
        var date = Moment(session.data.date, 'DD/MM/YYYY HH:mm')
        if(date > start && art.indexOf(session.program[0].id) !== -1) {
          if(session.data.attendance === 3 && ids.indexOf(session.id) === -1 && pids.indexOf(session.program[0].id) === -1) {
            done++
            ids.push(session.id)
            pids.push(session.program[0].id)
          }
        }
      }
      
      
    }

    var val = parseInt((done/total)*100)
    return val
  }


  getProgramColor(item, num, day) {
    var label = 'pending'
    var text = lang.t('training:label.pending')
    var ids = []

    if(this.state.item.data.date !== undefined) {
      var start = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').startOf('day').format('X')
      var end = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').startOf('day').add(7*this.state.item.weeks.length+1, 'days').format('X')
      
      for(var session of this.state.sessionsTraining) {
        var sdate = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('X')
        
        if(session.program[0] !== undefined) {
          if(session.program[0].id === item && sdate > start && sdate < Moment().format('X') && sdate < end && ids.indexOf(session.program[0].id) === -1) {
            label = 'other'
            text = lang.t('training:label.notdone')
            ids.push(session.program[0].id)
            if(session.data.attendance === 3) {
              label = 'green'
              text = lang.t('training:label.done')
            }
          }
        } 
      }
    }
    return [label, text]
  }


  getLink(num, id, day) {
    var label = '/'+global.uid+'/training/plan-program/'+this.state.item.id+'/'+id
    if(this.state.item.data.date !== undefined) {
      var start = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('X')
      var end = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(7*this.state.item.weeks.length+1, 'days').format('X')
      for(var session of this.state.sessionsTraining) {
        var d1 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add((num-7), 'days').format('DD/MM/YYYY')
        var dt = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
        var sdate = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('X')

        if(session.program[0] !== undefined) {
          if((d1 === dt || session.program[0].id === id) && sdate > start && sdate < end && sdate < Moment().format('X')) {
            label = '/'+global.uid+'/past-session/'+session.id
          }
        }
      }
    }
    return label
  }



  // Delete overlay ------------------------------------------------------------



  showOverlayDelete(id) {
    this.setState({
      showOverlayDelete: true,
      del: id,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenDelete: '',
        margin: -height/2
      });
    }, 100);
  }


  hideOverlayDelete() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenDelete: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayDelete: false});
    }, 500);
  }


  renderOverlayDelete() {
    if(this.state.showOverlayDelete) {
      return (
        <div className={'overlay '+this.state.hiddenDelete}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:deleteplan.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayDelete()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-30">{lang.t('modal:deleteplan.text')}</p>
            <button className="btn primary" onClick={() => this.deletePlan()}>{lang.t('modal:deleteplan.button')}</button>
          </div>
        </div>
      )
    }
  }


  deletePlan() {
    this.hideOverlayDelete()
    Firebase.database().ref('/plans/'+global.uidUser+'/'+this.state.item.id).remove()
    .then((data)=>{
      global.message = lang.t('message:plandeleted')
      this.props.history.push('/'+global.uid+'/training/plans')
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Video overlay ------------------------------------------------------------



  showOverlayVideo(url) {
    this.setState({
      showOverlayVideo: true,
      url: this.state.item.data.video
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenVideo: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayVideo() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenVideo: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayVideo: false});
    }, 500);
  }


  renderPlayer() {
    var arr = this.state.url.split('/')
    if(this.state.url.indexOf('youtu') !== -1) {
      var link = arr[arr.length-1]
      if(arr[arr.length-1].indexOf('?')) {
        var ar1 = arr[arr.length-1].split('=')
        var ar11 = ar1[ar1.length-2].split('&')
        link = ar11[0]
      }
      return (
        <iframe width="100%" height="470" src={'https://www.youtube.com/embed/'+link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      )
    } else {
      var ar2 = arr[arr.length-1].split('?')
      return (
        <iframe src={'https://player.vimeo.com/video/'+ar2[0]+'?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'} frameborder="0" width="100%" height="470" title="Video player"></iframe>
      )
    }
  }


  renderOverlayVideo() {
    if(this.state.showOverlayVideo) {
      return (
        <div className={'overlay '+this.state.hiddenVideo}>
          <div className="box mainvideo auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('common:button.video')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayVideo()}>{lang.t('modal:common.close')}</button>
            <div className="clear"></div>
            {this.renderPlayer()}
          </div>
        </div>
      )
    }
  }



  // Start overlay ------------------------------------------------------------



  showOverlayStart() {
    this.setState({
      showOverlayStart: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenStart: '',
        margin: -height/2
      });
    }, 100);
  }


  hideOverlayStart() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenStart: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayStart: false});
    }, 500);
  }


  renderOverlayStart() {
    if(this.state.showOverlayStart) {
      return (
        <div className={'overlay '+this.state.hiddenStart}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:restartplan.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayStart()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-30">{lang.t('modal:restartplan.text')}</p>
            <button className="btn primary" onClick={() => this.startPlan()}>{lang.t('modal:restartplan.button')}</button>
          </div>
        </div>
      )
    }
  }


  startPlan() {
    var tmp = this.state.item.data.date
    this.hideOverlayStart()
    Firebase.database().ref('/plans/'+global.uidUser+'/'+this.state.item.id).update({
      date: Moment().format('DD/MM/YYYY HH:mm')
    }).then((data)=>{
      global.message = "Training plan successfully started"
      var id = ''
      var ar1 = this.state.item.weeks[0].data.day1.split(',')
      var ar2 = this.state.item.weeks[0].data.day2.split(',')
      var ar3 = this.state.item.weeks[0].data.day3.split(',')
      var ar4 = this.state.item.weeks[0].data.day4.split(',')
      var ar5 = this.state.item.weeks[0].data.day5.split(',')
      var ar6 = this.state.item.weeks[0].data.day6.split(',')
      var ar7 = this.state.item.weeks[0].data.day7.split(',')
      ar1.splice(0, 1)
      ar2.splice(0, 1)
      ar3.splice(0, 1)
      ar4.splice(0, 1)
      ar5.splice(0, 1)
      ar6.splice(0, 1)
      ar7.splice(0, 1)
      var ar = [...ar1, ...ar2, ...ar3, ...ar4, ...ar5, ...ar6, ...ar7]
      if(ar.length > 0) {
        for(var pr of this.state.item.programs) {
          if(pr.id === ar[0]) {
            id = pr.id
          }
        }
      }
      if(tmp === undefined && id !== '') {
        this.props.history.push('/'+global.uid+'/training/plan-program/'+this.state.item.id+'/'+id)
      } else {
        EventEmitter.dispatch('showMessage', lang.t('message:planrestarted'));
      }
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Display stuff ------------------------------------------------------------



  renderStatus() {
    if(this.state.item.data.date === undefined) {
      return (
        <div>
          <p>{lang.t('training:label.notstarted')}</p>
        </div>
      )
    } else {
      var end = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(this.state.item.weeks.length+4, 'weeks')
      var label = this.getCompliance()+'%'
      var line2 = lang.t('training:label.completed')
      if(end < Moment()) {
        label = lang.t('training:label.done')
        line2 = ''+Moment(end).locale(lang.language).format('DD MMM YYYY')
      }
      if(this.state.item.data.date === undefined || this.state.item.data.date === null) {
        label = lang.t('training:label.not')
        line2 = lang.t('training:label.started2')
      }
      return (
        <p>{label} {line2}</p>
      )
    }
  }


  renderProgramData(id) {
    var mins = ''
    var exercises = ''
    var name = ''
    var time = 0
    for(var item of this.state.item.programs) {
      if(item.id === id) {
        name = item.data.name
        exercises = item.data.exercises+' '+lang.t('training:label.movements')
        mins = Cal.getDuration(item.data.time)
        time = item.data.time
        if(item.data.exercises === 1) {
          exercises = item.data.exercises+' '+lang.t('training:label.movement')
        }
      }
    }
    return [name, exercises, mins, time]
  }


  getProgramStatus(item, num, day) {
    var label = 'green'
    var text = lang.t('training:label.pending')
    var ids = []

    if(this.state.item.data.date !== undefined) {
      var start = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').startOf('day').format('X')
      var end = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').startOf('day').add(7*this.state.item.weeks.length+1, 'days').format('X')
      
      for(var session of global.sessionsTraining) {
        var sdate = Moment(session.data.date, 'DD/MM/YYYY HH:mm').format('X')
        
        if(session.program[0] !== undefined) {
          if(session.program[0].id === item && sdate > start && sdate < Moment().format('X') && sdate < end && ids.indexOf(session.program[0].id) === -1) {
            label = 'red'
            text = lang.t('training:label.notdone')
            ids.push(session.program[0].id)
            if(session.data.attendance === 3) {
              label = 'green prominent'
              text = lang.t('training:label.done')
            }
          }
        }
      } 
    }
    return <div className={'status '+label}>{text}</div>
  }


  renderWeekday(id, day, num) {
    var programs = []
    for(var item of this.state.item.weeks) {
      if(item.id === id) {
        if(day === 1) {
          programs = item.data.day1.split(',')
        } else if(day === 2) {
          programs = item.data.day2.split(',')
        } else if(day === 3) {
          programs = item.data.day3.split(',')
        } else if(day === 4) {
          programs = item.data.day4.split(',')
        } else if(day === 5) {
          programs = item.data.day5.split(',')
        } else if(day === 6) {
          programs = item.data.day6.split(',')
        } else if(day === 7) {
          programs = item.data.day7.split(',')
        }
      }
    }
    programs.splice(0,1)
    for(var i=programs.length-1; i>=0; i--) {
      if(programs[i] === undefined) {
        programs.splice(i, 1)
      }
    }
    if(programs.length > 0) {
      return (
        <div>
          {programs.map((item) => (
            <div className="cal-session">
              <Link to={this.getLink(num, item, day)} key={item}>
                {this.getProgramStatus(item, num, day)}
                <div className={'icon clear '+this.getProgramColor(item)}>
                  <p>{this.renderProgramData(item)[3]}'</p>
                </div>
                <p className="lft"><strong>{this.renderProgramData(item)[0]}</strong><br/>{this.renderProgramData(item)[2]}</p>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      )
    } else {
      return (
        <div className="empty small pt-30">
          <h4>{lang.t('training:label.restday')}</h4>
        </div>
      )
    }
  }


  renderToday(num, index) {
    var label = ''
    if(this.state.item.data.date !== null && this.state.item.data.date !== undefined) {
      var d1 = Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').add(num+(index*7), 'days').format('DD/MM/YYYY')
      if(d1 === Moment().format('DD/MM/YYYY')) {
        label = ' '+lang.t('calendar:label.today')
      }
    }
    return label
  }


  renderWeeks() {
    if(this.state.item !== null) {
      return (
        <div>
          {this.renderInfoStart()}
          {this.state.item.weeks.map((item, index) => (
            <div className="box mb-20" key={item.id}>
              <h4 className="mb-20">{item.data.name}</h4>
              <div className="calmain">
                <div style={{minWidth: '1200px'}}>
                  <div className="planweek clear calendar">
                    <div className={'weekday cal-top lft'+this.renderToday(item.data.id*7, index)}>
                      <h3 className="mb-10">{lang.t('calendar:label.day')} 1</h3>
                      {this.renderWeekday(item.id, 1, item.data.id*7)}
                    </div>
                    <div className={'weekday cal-top lft'+this.renderToday(item.data.id*7+1, index)}>
                      <h3 className="mb-10">{lang.t('calendar:label.day')} 2</h3>
                      {this.renderWeekday(item.id, 2, item.data.id*7+1)}
                    </div>
                    <div className={'weekday cal-top lft'+this.renderToday(item.data.id*7+2, index)}>
                      <h3 className="mb-10">{lang.t('calendar:label.day')} 3</h3>
                      {this.renderWeekday(item.id, 3, item.data.id*7+2)}
                    </div>
                    <div className={'weekday cal-top lft'+this.renderToday(item.data.id*7+3, index)}>
                      <h3 className="mb-10">{lang.t('calendar:label.day')} 4</h3>
                      {this.renderWeekday(item.id, 4, item.data.id*7+3)}
                    </div>
                    <div className={'weekday cal-top lft'+this.renderToday(item.data.id*7+4, index)}>
                      <h3 className="mb-10">{lang.t('calendar:label.day')} 5</h3>
                      {this.renderWeekday(item.id, 5, item.data.id*7+4)}
                    </div>
                    <div className={'weekday cal-top lft'+this.renderToday(item.data.id*7+5, index)}>
                      <h3 className="mb-10">{lang.t('calendar:label.day')} 6</h3>
                      {this.renderWeekday(item.id, 6, item.data.id*7+5)}
                    </div>
                    <div className={'weekday cal-top lft'+this.renderToday(item.data.id*7+6, index)}>
                      <h3 className="mb-10">{lang.t('calendar:label.day')} 7</h3>
                      {this.renderWeekday(item.id, 7, item.data.id*7+6)}
                    </div>
                    <div className="clear"></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderSessions() {
    if(this.state.item.data.sessions !== 0) {
      return (
        <p className="mb-10"><span className="pill primary">{this.state.item.data.sessions} {this.state.item.data.sessions === 1 ? lang.t('training:label.sessionincl') : lang.t('training:label.sessionsincl')}</span></p>
      )
    }
  }


  renderBasic() {
    if(this.state.item !== null) {
      return (
        <div className="program-header mb-60">
          <div className="icon large primary lft mr-20">
            <p>{this.state.item.weeks.length}</p>
          </div>
          <div className="main">
            <h2>{this.state.item.data.name}</h2>
            <p className="mb-10">{this.state.item.data.desc}</p>
            {this.renderSessions()}
            {this.renderVideo()}
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderVideo() {
    if(this.state.item.data.video !== undefined && this.state.item.data.video !== '') {
      if(this.state.item.data.video.indexOf('folder') !== -1 || this.state.item.data.video.indexOf('playlist') !== -1) {
        return (
          <a className="btn tertiary width-12" href={this.state.item.data.video} target="_blank" rel="noopener noreferrer">{lang.t('common:button.video')}</a>
        )
      } else {
        return (
          <button className="btn tertiary width-12" onClick={() => this.showOverlayVideo()}>{lang.t('common:button.video')}</button>
        )
      }
    }
  }


  renderStart() {
    if(this.state.item !== null) {
      if(this.state.item.data.date !== undefined) {
        return (
          <div>
            <div className="col-4">&nbsp;</div>
            <div className="col-4">
              <button className="btn primary width-12 close mb-20" onClick={() => this.showOverlayStart()}>{lang.t('training:button.restartplan')}</button>
            </div>
            <div className="clear"></div>
          </div>
        )
      }
    }
  }


  renderDelete() {
    if(this.state.item !== null) {
      return (
        <div>
          <button className="btn tertiary width-12 close" onClick={() => this.showOverlayDelete()}>{lang.t('training:button.deleteplan')}</button>
          <div className="sv-40"></div>
        </div>
      )
    }
  }


  renderInfoStart() {
    if(this.state.item !== null) {
      if(this.state.item.data.date === undefined) {
        return (
          <div className="infobox large primary mb-30">
            <p>{lang.t('training:label.startplan')}</p>
            <button className="btn tertiary rgt">{lang.t('training:button.startplan')}</button>
            <div className="clear"></div>
          </div>
        )
      }
    }
  }


  renderInfo() {
    if(this.state.item !== null) {
      return (
        <div className="mb-50">
          <div className="col-4">&nbsp;</div>
          <div className="box col-4 grey">
            <div className="info">
              <label>{lang.t('training:label.status')}</label>
              {this.renderStatus()}
            </div>
            
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content mt-20">
          {this.renderBasic()}
          {this.renderInfo()}
          {this.renderWeeks()}
          <div className="sv-20"></div>
          {this.renderStart()}
          <div className="clear"></div>
          {this.renderDelete()}
        </div>
        <div className="header">
          <div className="content">
            <Link to={'/'+global.uid+'/training/plans'} className="back lft mr-10">
              <div className="circle"></div>
              <div className="arrow"></div>
            </Link>
            <h2>{lang.t('header:title.plan')}</h2>
          </div>
        </div>
        <Navigation active='training' />
        {this.renderOverlayDelete()}
        {this.renderOverlayStart()}
        {this.renderOverlayVideo()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['header','common','training','calendar','modal','message'])(withRouter(withAuthorization(condition)(MemberPlanPage)));