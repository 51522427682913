import React, { Component } from 'react';


const DataMessageSimple = [
  {
    id: 'loginclient',
    title: "Client user",
    text: "You're logged in as a client. This is the Business Portal and meant for client use. Please download the mobile app to use PT Mate and connect with your trainer.",
    button: 'Got it',
  },
  {
    id: 'clientlimit',
    title: 'Client maximum reached',
    text: "You've reached your clients maximum ($var). To add another client, please delete another one first or upgrade your plan. Do you want to upgrade now?",
    button: 'Upgrade now',
  },
  {
    id: 'clientrestricted',
    title: 'Client under 18',
    text: "This client has been created successfully. Please note: As the client is younger than 18 years of age, they won't be able to connect with you via the Member App/Portal.",
    button: 'Got it',
  },
  {
    id: 'programlimit',
    title: 'Program maximum reached',
    text: "You've reached your clients maximum ($var). To create a new program, please delete another one first or upgrade your plan. Do you want to upgrade now?",
    button: 'Upgrade now',
  },
  {
    id: 'benchmark',
    title: 'Feature unavailable',
    text: "You need to be subscribed to Basic, Pro or Business to be able to create benchmark programs. Do you want to upgrade now?",
    button: 'Upgrade now',
  },
  {
    id: 'movement',
    title: 'Feature unavailable',
    text: "You need to be subscribed to Pro or Business to be able to create your own movement types. Do you want to upgrade now?",
    button: 'Upgrade now',
  },
  {
    id: 'planlimit',
    title: 'Feature unavailable',
    text: "You need a subscription to Unlimited Pro to create your own training plans. Do you want to upgrade now?",
    button: 'Upgrade now',
  },
  {
    id: 'deletehabit',
    title: 'Delete habit',
    text: "Do you want to delete this habit and all of its compliance data?",
    button: 'Delete habit',
  },
  {
    id: 'deleteexpense',
    title: 'Delete expense',
    text: "Do you want to delete this expense? All its data will be permanently deleted.",
    button: 'Delete expense',
  },
  {
    id: 'confirmpay',
    title: 'Use saved card',
    text: "Are you sure you want to make a payment for $var using the saved card?",
    button: 'Yes, make the payment',
  },
  {
    id: 'confirmdebit',
    title: 'Use saved card',
    text: "Are you sure you want to create a membership using your saved card?",
    button: 'Yes, create it now',
  },
  {
    id: 'deleteproduct',
    title: 'Delete product',
    text: "Do you want to delete this product? All data will be permanently deleted. Clients using this product won't be affected.",
    button: 'Delete product',
  },
  {
    id: 'deleteinvoice',
    title: 'Delete invoice',
    text: "Are you sure you want to delete this invoice? This cannot be undone.",
    button: 'Delete invoice',
  },
  {
    id: 'stafflocked',
    title: 'Feature not available',
    text: "You can't add staff members with your current plan. Please upgrade to a Basic, Pro or Business Plan to be able to use this feature.",
    button: 'Upgrade now',
  },
  {
    id: 'stafflocked2',
    title: 'Limit reached',
    text: "You can't add any more staff members with your current plan. Please upgrade to a Pro or Business Plan to be able to add more staff members.",
    button: 'Upgrade now',
  },
  {
    id: 'deleteform',
    title: 'Delete this form',
    text: "Do you want delete this form? Existing client responses won't be affected.",
    button: 'Delete this form',
  },
  {
    id: 'deleteformpre',
    title: 'Delete this form',
    text: "Do you want delete this form? Existing client responses won't be affected. This is your Pre Exercise Questionnaire. Deleting it will remove it from future client onboarding.",
    button: 'Delete this form',
  },
  {
    id: 'deleteresponse',
    title: 'Delete this response',
    text: "Do you want delete this client response?",
    button: 'Delete this response',
  },
  {
    id: 'preexercise',
    title: 'Replace existing questionnaire',
    text: "You currently have a Pre Exercise Questionnaire set up. Switching this on will replace it with this form once you save it.",
    button: 'Got it',
  },
  {
    id: 'recurring',
    title: 'Paid plans only',
    text: "Recurring sessions is not available with your current free Spark plan. You need to upgrade to a paid subscription plan.",
    button: 'Upgrade your subscription',
  },
  {
    id: 'deleterec',
    title: 'Delete template',
    text: "This will delete this template and remove any future sessions. Past sessions won't be affected. Do you want to continue?",
    button: 'Delete template',
  },
  {
    id: 'updaterec',
    title: 'Update template',
    text: "Do you want to save your changes? This will update this template and any future sessions.",
    button: 'Yes, save changes',
  },
  {
    id: 'deleterecevent',
    title: 'Delete template',
    text: "This will delete this template and remove any future events. Past events won't be affected. Do you want to continue?",
    button: 'Delete template',
  },
  {
    id: 'updaterecevent',
    title: 'Update template',
    text: "Do you want to save your changes? This will update this template and any future events.",
    button: 'Yes, save changes',
  },
  {
    id: 'removerec',
    title: 'Remove client',
    text: "Do you want to remove the recurring booking for $var? This will also cancel their bookings for all future sessions using this template.",
    button: 'Remove client',
  },
  {
    id: 'deletesession',
    title: 'Delete this session?',
    text: "Do you want to delete this session? This cannot be undone.",
    button: 'Delete session',
  },
  {
    id: 'sparksession',
    title: 'Paid plans only',
    text: "Creating and editing programs within sessions is not available with your current free Spark plan. You need to upgrade to a paid subscription plan.",
    button: 'Upgrade your subscription',
  },
  {
    id: 'bookableevent',
    title: 'Not available',
    text: "You need to be subscribed to Pro or Business to be able to create bookable events. Do you want to upgrade now?",
    button: 'Upgrade now',
  },
  {
    id: 'deleteevent',
    title: 'Delete this event?',
    text: "Do you want to delete this event? This cannot be undone.",
    button: 'Delete event',
  },
  {
    id: 'deleteclientgroup',
    title: 'Delete client group?',
    text: "Are you sure you want to delete this client group?",
    button: 'Delete client group',
  },
  {
    id: 'confirmlinked',
    title: 'Unlink profile?',
    text: "This will unlink $var from the family profile and cancel any memberships and session packs assigned to them. Do you want to continue?",
    button: 'Unlink profile',
  },
  {
    id: 'deleteprogram',
    title: 'Delete program',
    text: "Do you want delete this program from your client's program list? Note: This won't delete the program from their past training sessions.",
    button: 'Delete program',
  },
  {
    id: 'deleteprogram2',
    title: 'Delete program',
    text: "Do you want delete this program? Clients and sessions this program using it won't be affected.",
    button: 'Delete program',
  },
  {
    id: 'deleteprograms',
    title: 'Delete programs',
    text: "Do you want delete all programs you sent to your client? Note: This won't delete the program from their past training sessions or delete programs that weren't sent by you.",
    button: 'Delete all programs',
  },
  {
    id: 'deleteplan',
    title: 'Delete plan',
    text: "Are you sure you want to delete this plan? This won't affect clients using it.",
    button: 'Yes, delete plan',
  },
  {
    id: 'deleteplanclient',
    title: 'Delete plan',
    text: "Do you want to delete this training plan from your client's list? Note: This won't delete past training sessions.",
    button: 'Delete plan',
  },
  {
    id: 'sendplan',
    title: "Can't send plan",
    text: "Please add at least one program to your training plan before sending it to your clients.",
    button: 'Got it',
  },
  {
    id: 'deleteweek',
    title: "Delete week",
    text: "Deleting this week will also delete all programs assigned to it. This cannot be undone. Do you want to continue?",
    button: 'Yes, delete week',
  },
  {
    id: 'deletepost',
    title: "Delete post",
    text: "Are you sure you want to delete this post? It'll disappear from your clients' feed. This cannot be undone.",
    button: 'Delete post',
  },
  {
    id: 'deletereply',
    title: "Delete reply",
    text: "Are you sure you want to delete this reply? It'll disappear from your clients' feed. This cannot be undone.",
    button: 'Delete reply',
  },
  {
    id: 'branding',
    title: "Feature not available",
    text: "You can't change the brand colour theme with your current plan. Please upgrade to an Unlimited Pro Plan to be able to do so.",
    button: 'Upgrade now',
  },
  {
    id: 'canceldebit',
    title: "Cancel membership?",
    text: "This will cancel the membership after the current billing period. Payments and invoices won't be deleted. Do you want to continue?",
    button: 'Yes, cancel membership',
  },
  {
    id: 'resumedebit',
    title: "Resume membership?",
    text: "This will resume the membership and your client will be charged on the next scheduled date (Estimated $var).",
    button: 'Resume membership',
  },
  {
    id: 'goback',
    title: "Go back",
    text: "Do you want to confirm attendance for this session? This will mark the session as 'done' as well.",
    button: 'Confirm attendance & go back',
  },
  {
    id: 'sessionfull',
    title: "Session full",
    text: "Sorry, can't check you in. The session is already full.",
    button: 'OK',
  },
  {
    id: 'habitslocked',
    title: "Not available",
    text: "You can't create habits with your current plan. Please upgrade to a Business Plan to be able to use this feature.",
    button: 'Upgrade now',
  },
  {
    id: 'deletelog',
    title: "Delete log entry?",
    text: "Do you want to delete this health log entry? This cannot be undone.",
    button: 'Delete log entry',
  },
  {
    id: 'deletebilling',
    title: "Delete card details",
    text: "This will delete all your client's billing details, including saved cards and the Stripe customer reference, as well as cancel all their memberships. Your client's payment history won't be affected. This cannot be undone.",
    button: 'Delete card & billing details',
  },
  {
    id: 'makeactive',
    title: "Make client active",
    text: "Do you want to make this client active again? This will allow you to manage them like you did before.",
    button: 'Make client active again',
  },
  {
    id: 'makeinactive',
    title: "Make client inactive",
    text: "Do you want to make this client inactive? They won't be shown in your client list. Their data, including memberships and billing won't be affected.",
    button: 'Make client inactive',
  },
  {
    id: 'sendemail',
    title: "Send email",
    text: "Do you want to send this email to $var now? You won't be able to edit or resend the email after this.",
    button: 'Send email now',
  },
  {
    id: 'deleteemail',
    title: "Delete email",
    text: "Do you want to delete this email? This cannot be undone.",
    button: 'Delete email',
  },
  {
    id: 'emailinfo',
    title: "Please note",
    text: "Sending emails has an additional cost to your subscription. You are being charged based on your monthly usage, in increments of $5/1000 emails per month. E.g. If you send 1500 emails, you'll be charged $5 at the end of the month. If you send between 1000 and 2000, you'll be charged $10, etc.",
    button: 'Got it',
  },
  {
    id: 'emaillocked',
    title: "Payment method required",
    text: "You need to add a payment method to start sending emails. You will be charged based on your monthly usage, in increments of $5/1000 emails per month. E.g. If you send 1000 emails, you'll be charged $10 at the end of the month. If you send between 1000 and 2000, you'll be charged $10, etc.",
    button: 'Add a payment method',
  },
  {
    id: 'welcomelocked',
    title: "Not available",
    text: "You need to upgrade your subscription to a Business plan to be able to create and send welcome emails to your clients.",
    button: 'Upgrade now',
  },
  {
    id: 'emailsettingsinfo',
    title: "Please note",
    text: "Sending confirmation emails has an additional cost to your subscription. You are being charged based on your monthly usage, in increments of $5/1000 emails per month. E.g. If you send 1000 emails, you'll be charged $5 at the end of the month. If you send between 1000 and 2000, you'll be charged $10, etc. Booking confirmations and cancellations will be sent.",
    button: 'Got it',
  },
  {
    id: 'emailsettingslocked',
    title: "Payment method required",
    text: "You need to add a payment method to enable email confirmations. You will be charged based on your monthly usage, in increments of $5/1000 emails per month. E.g. If you send 1000 emails, you'll be charged $5 at the end of the month. If you send between 1000 and 2000, you'll be charged $10, etc.",
    button: 'Add a payment method',
  },
  {
    id: 'sessionreminder',
    title: "Send session reminder",
    text: "Do you want to send a session reminder via email and push notification to your booked in clients?",
    button: 'Send reminder now',
  },
  {
    id: 'sessionreminder11',
    title: "Send session reminder",
    text: "Do you want to send a session reminder via email and push notification to your client?",
    button: 'Send reminder now',
  },
  {
    id: 'nostock',
    title: "Nothing left in stock",
    text: "You can't purchase this product because your stock is empty. Please go to the products tab to update your stock.",
    button: 'Got it',
  },
  {
    id: 'nostock2',
    title: "Nothing left in stock",
    text: "You can't purchase this product because the stock is empty.",
    button: 'Got it',
  },
]

class ModalMessageSimple extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      var: props.var
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      var: props.var,
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  clickMainButton() {
    this.hide()
    this.props.clickMainButton()
  }


  render() {
    var data = null
    var text = ''
    for(var item of DataMessageSimple) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(this.state.show && data !== null) {
      text = data.text.replace('$var', this.state.var)
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">{data.title}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <p className="clear mb-30">{text}</p>
            <button className="btn primary" onClick={() => this.clickMainButton()}>{data.button}</button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default ModalMessageSimple;