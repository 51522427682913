import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import MemberLoginPage from '../../pages/Landing';
import PasswordPage from '../../pages/Landing/password';

import MemberSignupPage from '../../pages/Signup';
import MemberConfirmPage from '../../pages/Signup/confirm';
import MemberLoadingPage from '../../pages/Signup/loading';

import MemberHomePage from '../../pages/Overview';
import MemberCommunityPage from '../../pages/Overview/community';

import MemberCalendarPage from '../../pages/Calendar';
import MemberSessionPage from '../../pages/Calendar/session';
import MemberEventPage from '../../pages/Calendar/event';

import MemberProgramPage from '../../pages/Training/program';
import MemberResultsPage from '../../pages/Training/results';
import MemberRunningPage from '../../pages/Training/running';
import MemberTrainingSessionsPage from '../../pages/Training';
import MemberTrainingProgramsPage from '../../pages/Training/programs';
import MemberTrainingPlansPage from '../../pages/Training/plans';
import MemberTrainingProgressPage from '../../pages/Training/progress';
import MemberPlanPage from '../../pages/Training/plan';

import MemberNutritionPage from '../../pages/Health';
import MemberMealPage from '../../pages/Health/meal';
import MemberPreferencesPage from '../../pages/Health/preferences';
import MemberHealthPage from '../../pages/Health/healthlog';
import MemberNewAssessmentPage from '../../pages/Health/assessment-new';
import MemberAssessmentPage from '../../pages/Health/assessment';
import MemberHabitsPage from '../../pages/Health/habits';
import MemberHabitPage from '../../pages/Health/habit';

import MemberPaymentsPage from '../../pages/Admin/payments';
import MemberPaymentPage from '../../pages/Admin/payment';
import MemberDebitPage from '../../pages/Admin/debitnew';
import MemberInvoicePage from '../../pages/Admin/invoice';
import MemberAdminPage from '../../pages/Admin';
import MemberDocumentPage from '../../pages/Admin/form';
import MemberCardsPage from '../../pages/Admin/cards';
import MemberAccountPage from '../../pages/Account';
import MemberSpacesPage from '../../pages/Signup/spaces';
import MemberThankyouPage from '../../pages/Signup/thankyou';
import UsersClientsPage from '../../pages/Admin/usersclients';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';



const App = () => {
  return (
  <Router>
    <div style={{height: '100%'}}>
      <Route exact path={ROUTES.LANDING} component={MemberLoginPage} />
      <Route exact path={ROUTES.LOGIN} component={MemberLoginPage} />
      <Route exact path={'/:id/login'} component={MemberLoginPage} />
      <Route exact path={ROUTES.PASSWORD} component={PasswordPage} />
      <Route exact path={ROUTES.SIGNUP} component={MemberSignupPage} />
      <Route exact path={'/:id/signup'} component={MemberSignupPage} />
      <Route exact path={'/complete-signup'} component={MemberSignupPage} />
      <Route exact path={'/:id/complete-signup'} component={MemberSignupPage} />
      <Route exact path={ROUTES.CONFIRM} component={MemberConfirmPage} />
      <Route exact path={'/:id/confirm'} component={MemberConfirmPage} />
      <Route exact path={'/:id/pre-exercise'} component={MemberDocumentPage} />
      <Route exact path={ROUTES.LOADING} component={MemberLoadingPage} />

      <Route exact path={'/:id/home'} component={MemberHomePage} />
      <Route exact path={'/:id/community'} component={MemberCommunityPage} />

      <Route exact path={'/:id/calendar'} component={MemberCalendarPage} />
      <Route exact path={'/:id/session/:id2'} component={MemberSessionPage} />
      <Route exact path={'/:id/session/:id2/program/:id3'} component={MemberProgramPage} />
      <Route exact path={'/:id/results/:id2/'} component={MemberResultsPage} />
      <Route exact path={'/:id/running/:id2/'} component={MemberRunningPage} />
      <Route exact path={'/:id/past-session/:id2'} component={MemberSessionPage} />
      <Route exact path={'/:id/event/:id2'} component={MemberEventPage} />

      <Route exact path={'/:id/training/sessions'} component={MemberTrainingSessionsPage} />
      <Route exact path={'/:id/training/programs'} component={MemberTrainingProgramsPage} />
      <Route exact path={'/:id/training/plans'} component={MemberTrainingPlansPage} />
      <Route exact path={'/:id/training/progress'} component={MemberTrainingProgressPage} />
      <Route exact path={'/:id/training/program/:id2'} component={MemberProgramPage} />
      <Route exact path={'/:id/training/plan-program/:id2/:id3'} component={MemberProgramPage} />
      <Route exact path={'/:id/training/plan/:id2'} component={MemberPlanPage} />

      <Route exact path={'/:id/health/nutrition'} component={MemberNutritionPage} />
      <Route exact path={'/:id/health/meal/:id2'} component={MemberMealPage} />
      <Route exact path={'/:id/health/preferences'} component={MemberPreferencesPage} />
      <Route exact path={'/:id/health/log'} component={MemberHealthPage} />
      <Route exact path={'/:id/health/habits'} component={MemberHabitsPage} />
      <Route exact path={'/:id/health/habit/:id2'} component={MemberHabitPage} />
      <Route exact path={'/:id/health/new-log'} component={MemberNewAssessmentPage} />
      <Route exact path={'/:id/health/log-entry/:id2'} component={MemberAssessmentPage} />

      <Route exact path={'/:id/admin/forms'} component={MemberAdminPage} />
      <Route exact path={'/:id/form/:id2'} component={MemberDocumentPage} />
      <Route exact path={'/:id/admin/payments'} component={MemberPaymentsPage} />
      <Route exact path={'/:id/admin/payment'} component={MemberPaymentPage} />
      <Route exact path={'/:id/admin/newmembership'} component={MemberDebitPage} />
      <Route exact path={'/:id/admin/pay-invoice/:id2'} component={MemberPaymentPage} />
      <Route exact path={'/:id/admin/invoice/:id2'} component={MemberInvoicePage} />
      <Route exact path={'/:id/admin/card'} component={MemberCardsPage} />

      <Route exact path={'/:id/account'} component={MemberAccountPage} />

      <Route exact path={'/:id/spaces'} component={MemberSpacesPage} />
      <Route exact path={'/:id/thankyou'} component={MemberThankyouPage} />
      <Route exact path={'/admin/clients'} component={UsersClientsPage} />
    </div>
  </Router>
  )
};


export default withAuthentication(App);