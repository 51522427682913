import React, { Component } from 'react';
import Connector from '../../data/Connector';
import 'firebase/functions';
import HelperCal from '../../helper/Calendar';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperEmptyImg from '../../helper/EmptyLarge';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberTrainingProgramsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      programs: global.programs,
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    if(id !== 'training' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.training')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.configureData())
    this.configureData()

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.training')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    this.setState({
      programs: global.programs
    })
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }



  // Display stuff ------------------------------------------------------------



  renderPrograms() {
    if(this.state.programs.length === 0) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('programs')}
          </div>
          <div className="data">
            <h3 className="mb-20">{lang.t('empty:programs.title')}</h3>
            <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('empty:programs.text')}</p>
          </div>
        </div>
      )
    } else {
      var list = this.state.programs.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id}>
              <Link to={'/'+global.uid+'/training/program/'+item.id}>
                <div className="col-5">
                  <div className={'icon '+HelperCal.getColor(item.data.time)}>
                    <p>{item.data.time}'</p>
                  </div>
                  <p><strong>{item.data.name}</strong></p>
                </div>
                <div className="col-3">
                  <p>{item.data.exercises} {lang.t('training:label.movements')}</p>
                </div>
                <div className="col-2">
                  <p>{item.data.time} min</p>
                </div>
                <div className="col-2">
                  <button className="btn tertiary small rgt">{lang.t('common:button.viewdetails')}</button>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
          <div className="sv-40"></div>
        </div>
      )
    }
  }


  renderContent() {
    return (
      <div>
        <div className="listheader">
          <h3>{lang.t('header:training.programs')}</h3>
          <div className="clear"></div>
        </div>
        {this.renderPrograms()}
      </div>
    )
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content member">
          <div className="member-header mb-10">
            <div className={this.getBg()}>
              <div className={'gradient theme-'+global.spaceTheme}></div>
            </div>
            <div className="member-main">
              <div className="business">
                <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
                <h3>{global.userBusiness}<br/><span>{lang.t('header:title.memberarea')}</span></h3>
              </div>
              <h2 className="mb-30">{lang.t('header:title.training')}</h2>
              <Link to={'/'+global.uid+'/training/sessions'} className="tab">
                <p>{lang.t('nav:training.sessions')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/training/programs'} className="tab active">
                <p>{lang.t('nav:training.programs')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/training/plans'} className="tab">
                <p>{lang.t('nav:training.plans')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/training/progress'} className="tab">
                <p>{lang.t('nav:training.progress')}</p>
                <div className="bg"></div>
              </Link>
            </div>
            <div className="clear"></div>
          </div>
          {this.renderContent()}
        </div>
        <Navigation active='training' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['nav','header','common','empty','training'])(withRouter(withAuthorizationMember(condition)(MemberTrainingProgramsPage)));