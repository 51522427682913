import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import Resizer from "react-image-file-resizer";
import HelperPro from '../../helper/Programming';
import Navigation from '../../components/Navigation';
import InfoData from '../../components/Form/info';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import * as ROUTES from '../../constants/routes';
import { gql } from "@apollo/client";
import { AuthUserContext, withAuthorization } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberAsessmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: global.spaceClient,
      aid: '',
      item: null,
      image: '',
      values: ['', '', '', '', '', '', '', '', '', '', '', ''],
      file: null,
      fileDisplay: null,
      showOverlay: false,
      hidden: 'hidden',
      deleted: false,
      nutrition: global.userNutritionId,
      image2: '',
      image3: '',
      image4: '',
      file2: null,
      fileDisplay2: null,
      file3: null,
      fileDisplay3: null,
      file4: null,
      fileDisplay4: null,
      showOverlayImages: false,
      hiddenImages: 'hidden',
      list: [],
      item1: null,
      item2: null,
      image11: '',
      image12: '',
      image13: '',
      image14: '',
      image21: '',
      image22: '',
      image23: '',
      image24: '',
      valuesBlood: ['',''],
      valueCustom: '',
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-4]
    if(id !== 'health' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.healthlog')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true,
      aid: arr[arr.length-1]
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    this.configureData()
  }


  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.healthlog')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    this.setState({
      healthlog: global.healthlog,
    })
    this.getClient()
  }


  getClient() {
    var tmp3 = null
    var tmps = []
    var tmpb = ''
    var tmpbv = ['','']
    var tmpc = ''
    var nutrition = ''
    var arr = this.props.location.pathname.split('/')
    var aid = arr[arr.length-1]

    if(global.healthlog.length > 0) {
      for(var ass of global.healthlog) {
        tmps.push(ass)
        if(ass.id === aid) {
          tmp3 = ass
        }
      }

      if(tmp3.data.image !== '') {
        this.getImage(tmp3.data.image, 'image')
      }
      if(tmp3.data.image2 !== '' && tmp3.data.image2 !== undefined) {
        this.getImage(tmp3.data.image2, 'image2')
      }
      if(tmp3.data.image3 !== '' && tmp3.data.image3 !== undefined) {
        this.getImage(tmp3.data.image3, 'image3')
      }
      if(tmp3.data.image4 !== '' && tmp3.data.image4 !== undefined) {
        this.getImage(tmp3.data.image4, 'image4')
      }

      if(tmp3.data.blood1 !== undefined && tmp3.data.blood1 !== '') {
        tmpb = ass.data.blood1+' / '
        tmpbv[0] = ass.data.blood1
      } else {
        tmpb = '- / '
      }
      if(tmp3.data.blood2 !== undefined && tmp3.data.blood2 !== '') {
        tmpb += tmp3.data.blood2
        tmpbv[1] = tmp3.data.blood2
      } else {
        tmpb += '-'
      }
      if(tmp3.data.custom !== undefined) {
        tmpc = tmp3.data.custom
      }

      var heart = 0
      if(tmp3.data.heart !== undefined) {
        heart = tmp3.data.heart
      }
        var vtmp = [HelperPro.showWeight(tmp3.data.weight), tmp3.data.fat, tmp3.data.notes, this.setInitValue(tmp3.data.neck), this.setInitValue(tmp3.data.chest), this.setInitValue(tmp3.data.abdomen), this.setInitValue(tmp3.data.hip), this.setInitValue(tmp3.data.armR), this.setInitValue(tmp3.data.armL), this.setInitValue(tmp3.data.thighR), this.setInitValue(tmp3.data.thighL), heart]
      for(var i=0; i<vtmp.length; i++) {
        if(vtmp[i] === 0 && i !== 2) {
          vtmp[i] = ''
        }
      }

      if(global.userNutritionId !== undefined) {
        nutrition = global.userNutritionId
      }

      this.setState({
        id: global.spaceClient,
        item: tmp3,
        name: global.userName,
        values: vtmp,
        nutrition: nutrition,
        list: tmps,
        item1: tmp3,
        item2: tmps[0],
        blood: tmpb,
        custom: tmpc,
      }, () => {
          //this.configureData()
      });
    }
  }


  setInitValue(val) {
    var label = val
    if(global.userLbs && val !== '0' && val !== 0) {
      label = (val/2.54).toFixed(1)
    }
    return label
  }


  getImage(image, variable) {
    Firebase.storage().ref().child(image).getDownloadURL().then((url) => {
      this.setState({[variable]: url})
    }).catch((error) => {
      // Handle any errors
    })
  }



  // Client update stuff ------------------------------------------------------------



  onChange = event => {
    var tmp = this.state.values
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({values: tmp});
  };


  setImage(file, image) {
    if(image === '1') {
      this.setState({
        file: file,
        fileDisplay:  URL.createObjectURL(file)
      })
    } else if(image === '2') {
      this.setState({
        file2: file,
        fileDisplay2:  URL.createObjectURL(file)
      })
    } else if(image === '3') {
      this.setState({
        file3: file,
        fileDisplay3:  URL.createObjectURL(file)
      })
    } else if(image === '4') {
      this.setState({
        file4: file,
        fileDisplay4:  URL.createObjectURL(file)
      })
    }
  }


  displayImage(file, image, display) {
    if(file === null) {
      if(image !== '') {
        return (
          <div className="form-image" style={{backgroundImage: 'url('+image+')'}}> </div>
        )
      } else {
        return (
          <div className="form-image"></div>
        )
      }
      
    } else {
      return (
        <div className="form-image" style={{backgroundImage: 'url('+display+')'}}> </div>
      )
    }
  }


  updateAssessment() {
    var tmp = []
    var num = 0
    for(var item of this.state.values) {
      if(item === '') {
        tmp.push('0')
      } else {
        if(global.userLbs && num > 2 && num !== 11) {
          tmp.push((item*2.54).toFixed(1))
        } else {
          tmp.push(item)
        }
      }
      num++
    }

    var weight = tmp[0]
    if(global.userLbs) {
      weight = (tmp[0]*global.lbsDown).toFixed(2)
    }

    // DS CONNTECTION HERE
    tmp[2] = this.state.values[2]
    if(this.state.id !== '' && this.state.aid !== '') {
      Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient+'/assessments/'+this.state.aid).update({
        weight: parseFloat(weight),
        fat: parseFloat(tmp[1]),
        notes: tmp[2],
        neck: parseFloat(tmp[3]),
        chest: parseFloat(tmp[4]),
        abdomen: parseFloat(tmp[5]),
        hip: parseFloat(tmp[6]),
        armR: parseFloat(tmp[7]),
        armL: parseFloat(tmp[8]),
        thighR: parseFloat(tmp[9]),
        thighL: parseFloat(tmp[10]),
        heart: parseFloat(tmp[11])
      }).then(() => {
        EventEmitter.dispatch('showMessage', lang.t('message:logupdated'));
        this.setState({
          editing: false
        })
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
      if(this.state.file !== null) {
        this.uploadImage(this.state.file, this.state.item.id, 'image', this.state.item.id)
      }
      if(this.state.file2 !== null) {
        this.uploadImage(this.state.file2, this.state.item.id+'-2', 'image2', this.state.item.id)
      }
      if(this.state.file3 !== null) {
        this.uploadImage(this.state.file3, this.state.item.id+'-3', 'image3', this.state.item.id)
      }
      if(this.state.file4 !== null) {
        this.uploadImage(this.state.file4, this.state.item.id+'-4', 'image4', this.state.item.id)
      }
      if(this.state.item.data.nutrition !== undefined && this.state.item.data.nutrition !== "" && tmp[0] !== '0') {
        this.updateNutrition(parseFloat(weight))
      }
    }
  }


  uploadImage(file, name, img, key) {
    const sr = Firebase.storage().ref()
      const fr = sr.child('images/assessments/'+global.uid+'/'+name+'.jpg')
      Resizer.imageFileResizer(
        file,
        800,
        800,
        "JPEG",
        60,
        0,
        (uri) => {
          var ar = uri.split(',')
          fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
            Firebase.database().ref('/clients/'+global.uid+'/'+global.currentClient+'/assessments/'+key).update({
              [img]: 'images/assessments/'+global.uid+'/'+name+'.jpg',
            })
          }).catch((error)=>{
            EventEmitter.dispatch('showMessageError', error.message);
          })
        },
        "base64",
        200,
        200
      );
  }


  updateNutrition(weight) {
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation updateWeight($id: Int!, $user: Int!, $time: timestamp!, $weight: float8) {
        update_weights_by_pk(pk_columns: {id: $id},
          _set: {
            updated_at: $time,
            user_id: $user,
            weight: $weight,
            u_weight: $weight,
          })
          {
            id
          }
      }
    `;

    client.mutate({
      variables: { id: parseInt(this.state.item.data.nutrition), user: parseInt(this.state.nutrition), time: Moment().format('YYYY-MM-DDTHH:mm:ss'), weight: weight },
      mutation
    }).then((result) => {
      //console.log(result)
      console.log('done')
    }).catch((error) => {
      console.log(error);
    });
  }


  cancelEditing() {
    var tmp = [HelperPro.showWeight(this.state.item.data.weight), this.state.item.data.fat, this.state.item.data.notes, this.state.item.data.neck, this.state.item.data.chest, this.state.item.data.abdomen, this.state.item.data.hip, this.state.item.data.armR, this.state.item.data.armL, this.state.item.data.thighR, this.state.item.data.thighL]
    for(var i=0; i<tmp.length; i++) {
      if(tmp[i] === 0 && i !== 2) {
        tmp[i] = ''
      }
    }
    this.setState({
      values: tmp,
      file: null,
      fileDisplay: null,
      editing: false
    })
  }


  deleteAssessment() {
    this.hideOverlayDelete()
    if(this.state.item.data.image !== '') {
      Firebase.storage().ref('images/assessments/'+global.uid+'/'+this.state.aid+'.jpg').delete()
    }
    if(this.state.item.data.image2 !== '' && this.state.item.data.image2 !== undefined) {
      Firebase.storage().ref('images/assessments/'+global.uid+'/'+this.state.aid+'-2.jpg').delete()
    }
    if(this.state.item.data.image3 !== '' && this.state.item.data.image3 !== undefined) {
      Firebase.storage().ref('images/assessments/'+global.uid+'/'+this.state.aid+'-3.jpg').delete()
    }
    if(this.state.item.data.image4 !== '' && this.state.item.data.image4 !== undefined) {
      Firebase.storage().ref('images/assessments/'+global.uid+'/'+this.state.aid+'-4.jpg').delete()
    }
    if(this.state.item.data.nutrition !== undefined && this.state.item.data.nutrition !== "") {
      this.deleteNutrition()
    }
    Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient+'/assessments/'+this.state.aid).remove()
    global.message = lang.t('message:logdeleted')
    this.props.history.push('/'+global.uid+'/health/log')
  }


  deleteNutrition() {
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation updateWeight($id: Int!) {
        delete_weights_by_pk(id: $id) {
          id
        }
      }
    `;

    client.mutate({
      variables: { id: parseInt(this.state.item.data.nutrition) },
      mutation
    }).then((result) => {
      //console.log(result)
      console.log('done')
    }).catch((error) => {
      console.log(error);
    });
  }



  // Images overlay ------------------------------------------------------------



  showOverlayImages() {
    this.setState({showOverlayImages: true})
    setTimeout(() => {
      this.setState({
        hiddenImages: '',
        image11: '',
        image12: '',
        image13: '',
        image14: '',
        image21: '',
        image22: '',
        image23: '',
        image24: '',
      }, () => {
        this.getOverlayImage(this.state.item1.data.image, 'image11')
        this.getOverlayImage(this.state.item1.data.image2, 'image12')
        this.getOverlayImage(this.state.item1.data.image3, 'image13')
        this.getOverlayImage(this.state.item1.data.image4, 'image14')
        this.getOverlayImage(this.state.item2.data.image, 'image11')
        this.getOverlayImage(this.state.item2.data.image2, 'image12')
        this.getOverlayImage(this.state.item2.data.image3, 'image13')
        this.getOverlayImage(this.state.item2.data.image4, 'image14')
      });
    }, 100);
  }


  hideOverlayImages() {
    this.setState({hiddenImages: 'hidden'})
    setTimeout(() => {
      this.setState({
        showOverlayImages: false,
      });
    }, 500);
  }


  getOverlayImage(image, variable) {
    if(image !== '' && image !== undefined) {
      Firebase.storage().ref().child(image).getDownloadURL().then((url) => {
        this.setState({[variable]: url})
      }).catch((error) => {
        // Handle any errors
      })
    }
  }


  renderOverlayImage(image) {
    if(image !== '' && image !== undefined) {
      return (
        <div className="image" style={{backgroundImage: 'url('+image+')'}}> </div>
      )
    } else {
      return (
        <div className="image"></div>
      )
    }
  }


  renderOverlayImages() {
    if(this.state.showOverlayImages) {
      var list = this.state.list
      list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
      return (
        <div className={'overlay dark '+this.state.hiddenImages}   >
          <div className="btn tertiary close rgt mt-20 mr-20" onClick={() => this.hideOverlayImages()}>{lang.t('modal:common.close')}</div>
          <div className="side clear">
          <select value={this.state.item1.id} onChange={event => this.setItem(event, 'item1')}>
              {list.map(item => (
                <option value={item.id} key={item}>{Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('dddd, D MMMM YYYY')}</option>
              ))}
            </select>
            <div className="col-3 clear">
              {this.renderOverlayImage(this.state.image11)}
            </div>
            <div className="col-3">
              {this.renderOverlayImage(this.state.image12)}
            </div>
            <div className="col-3">
              {this.renderOverlayImage(this.state.image13)}
            </div>
            <div className="col-3">
              {this.renderOverlayImage(this.state.image14)}
            </div>
          </div>
          <div className="side">
            <select value={this.state.item2.id} onChange={event => this.setItem(event, 'item2')}>
              {list.map(item => (
                <option value={item.id} key={item.id}>{Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('dddd, D MMMM YYYY')}</option>
              ))}
            </select>
            <div className="col-3 clear">
              {this.renderOverlayImage(this.state.image21)}
            </div>
            <div className="col-3">
              {this.renderOverlayImage(this.state.image22)}
            </div>
            <div className="col-3">
              {this.renderOverlayImage(this.state.image23)}
            </div>
            <div className="col-3">
              {this.renderOverlayImage(this.state.image24)}
            </div>
          </div>
        </div>
      )
    }
  }


  setItem(event, item) {
    var tmp = null
    for(var ass of this.state.list) {
      if(ass.id === event.target.value) {
        tmp = ass
      }
    }
    
    if(item === 'item1') {
      this.setState({
        item1: tmp,
        image11: '',
        image12: '',
        image13: '',
        image14: '',
      }, () => {
        this.getOverlayImage(this.state.item1.data.image, 'image11')
        this.getOverlayImage(this.state.item1.data.image2, 'image12')
        this.getOverlayImage(this.state.item1.data.image3, 'image13')
        this.getOverlayImage(this.state.item1.data.image4, 'image14')
      })
    } else {
      this.setState({
        item2: tmp,
        image21: '',
        image22: '',
        image23: '',
        image24: '',
      }, () => {
        this.getOverlayImage(this.state.item2.data.image, 'image21')
        this.getOverlayImage(this.state.item2.data.image2, 'image22')
        this.getOverlayImage(this.state.item2.data.image3, 'image23')
        this.getOverlayImage(this.state.item2.data.image4, 'image24')
      })
    }
  }



  // Delete overlay ------------------------------------------------------------



  showOverlayDelete() {
    this.setState({showOverlay: true})
    setTimeout(() => {
      this.setState({
        hidden: '',
        deleted: true
      });
    }, 100);
  }


  hideOverlayDelete() {
    this.setState({hidden: 'hidden'})
    setTimeout(() => {
      this.setState({
        showOverlay: false,
        deleted: false
      });
    }, 500);
  }


  renderOverlayDelete() {
    if(this.state.showOverlay) {
      return (
        <div className={'overlay '+this.state.hidden}   >
          <div className="box text">
            <h2 className="mb-20 lft">{lang.t('modal:deletelog.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayDelete()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-30">{lang.t('modal:deletelog.text')}</p>
            <button className="btn primary" onClick={() => this.deleteAssessment()}>{lang.t('health:button.deletelog')}</button>
          </div>
        </div>
      )
    }
  }



  // Display stuff ------------------------------------------------------------



  renderTitle() {
    if(this.state.item !== null) {
      return Moment(this.state.item.data.date, 'DD/MM/YYYY HH:mm').format('D MMM YYYY - hh:mm a')
    }
  }


  renderButtonEdit() {
    return (
      <button className="btn primary" onClick={() => this.setState({editing: true})}>{lang.t('health:button.editdetails')}</button>
    )
  }


  renderEdit() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-sidebar">

          <div className="col-6 mt-20">
            <h3 className="mb-20">{lang.t('header:health.images')}</h3>
            <div className="col-6 clear">
              <div className="image healthlog edit mb-10">
                {this.displayImage(this.state.file, this.state.image, this.state.fileDisplay)}
              </div>
              <div>
                <label>{lang.t('health:label.front')}</label>
                <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], '1');}}/>
              </div>
            </div>
            <div className="col-6 mb-20">
              <div className="image healthlog edit mb-10">
                {this.displayImage(this.state.file2, this.state.fileDisplay2)}
              </div>
              <div>
                <label>{lang.t('health:label.back')}</label>
                <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], '2');}}/>
              </div>
            </div>
            <div className="col-6 clear mb-20">
              <div className="image healthlog edit mb-10">
                {this.displayImage(this.state.file3, this.state.fileDisplay3)}
              </div>
              <div>
                <label>{lang.t('health:label.left')}</label>
                <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], '3');}}/>
              </div>
            </div>
            <div className="col-6 mb-20">
              <div className="image healthlog edit mb-10">
                {this.displayImage(this.state.file4, this.state.fileDisplay4)}
              </div>
              <div>
                <label>{lang.t('health:label.right')}</label>
                <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], '4');}}/>
              </div>
            </div>
          </div>

          <div className="col-6 mt-20 pl-30">
            <h3 className="mb-20">{lang.t('health:log.overview')}</h3>
            <label className="clear">{lang.t('health:log.weight')} ({global.spaceLbs ? 'lb' : 'kg'})</label>
            <input type="number" placeholder={'Weight in '+(global.spaceLbs ? 'lb' : 'kg')} value={this.state.values[0]} name="0" onChange={this.onChange}/>
            <div className="sv-20"></div>
            <label>{lang.t('health:log.bodyfat')} (%)</label>
            <input type="number" value={this.state.values[1]} name="1" onChange={this.onChange}/>
            <div className="sv-20"></div>
            <label>{lang.t('health:log.heartrate')} (bpm)</label>
            <input type="number" value={this.state.values[11]} name="11" onChange={this.onChange}/>
            <div className="sv-20"></div>
            <label>{lang.t('health:log.bloodpressure')}</label>
            <div style={{width: '50%', float: 'left', padding: '0 5px 0 0'}}>
              <input type="number" placeholder="Systolic" value={this.state.valuesBlood[0]} name="0" onChange={this.onChangeBlood}/>
            </div>
            <div style={{width: '50%', float: 'left', padding: '0 0 0 5px'}}>
              <input type="number" placeholder="Diastolic" value={this.state.valuesBlood[1]} name="1" onChange={this.onChangeBlood}/>
            </div>
            <div className="clear sv-20"></div>
            <label>{lang.t('health:log.custom')}</label>
            <input type="number" placeholder="Enter any additional value to track" value={this.state.valueCustom} onChange={this.onChangeCustom}/>
            <div className="sv-20"></div>
            <label>{lang.t('health:log.notes')}</label>
            <textarea value={this.state.values[2]} name="2" onChange={this.onChange}></textarea>

            <div className="healthlog segmental mt-30">
              <h3 className="mb-20">Segmental Circumference</h3>
              <div className="clear info">
                <div className="circle lft mr-10">1</div>
                <div className="main lft">
                  <label>{lang.t('health:log.neck')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[3]} name="3" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear info">
                <div className="circle lft mr-10">2</div>
                <div className="main lft">
                  <label>{lang.t('health:log.chest')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[4]} name="4" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear info">
                <div className="circle lft mr-10">3</div>
                <div className="main lft">
                  <label>{lang.t('health:log.abdomen')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[5]} name="5" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear info">
                <div className="circle lft mr-10">4</div>
                <div className="main lft">
                  <label>{lang.t('health:log.hip')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[6]} name="6" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear info">
                <div className="circle lft mr-10">5</div>
                <div className="main lft">
                  <label>{lang.t('health:log.rightarm')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[7]} name="7" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear info">
                <div className="circle lft mr-10">6</div>
                <div className="main lft">
                  <label>{lang.t('health:log.leftarm')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[8]} name="8" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear info">
                <div className="circle lft mr-10">7</div>
                <div className="main lft">
                  <label>{lang.t('health:log.rightthigh')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[9]} name="9" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear info">
                <div className="circle lft mr-10">8</div>
                <div className="main lft">
                  <label>{lang.t('health:log.leftthigh')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[10]} name="10" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
            </div>
            
            <div className="box healthlog form none">
              <h3>{lang.t('health:log.overview')}</h3>
              <label className="clear">{lang.t('health:log.weight')} ({global.userLbs ? 'lb' : 'kg'})</label>
              <input type="text" placeholder={'Weight in '+(global.userLbs ? 'lb' : 'kg')} value={this.state.values[0]} name="0" onChange={this.onChange}/>
              <label>{lang.t('health:log.bodyfat')} (%)</label>
              <input type="number" value={this.state.values[1]} name="1" onChange={this.onChange}/>
              <label>{lang.t('health:log.heartrate')} (bpm)</label>
              <input type="number" value={this.state.values[11]} name="11" onChange={this.onChange}/>
              <label>{lang.t('health:log.notes')}</label>
              <textarea value={this.state.values[2]} name="2" onChange={this.onChange}></textarea>
            </div>
          </div>




          <div className="col-3 none">
            <div className="box healthlog form">
              <h3>{lang.t('health:log.overview')}</h3>
              <label className="clear">{lang.t('health:log.weight')} ({global.userLbs ? 'lb' : 'kg'})</label>
              <input type="text" placeholder={'Weight in '+(global.userLbs ? 'lb' : 'kg')} value={this.state.values[0]} name="0" onChange={this.onChange}/>
              <label>{lang.t('health:log.bodyfat')} (%)</label>
              <input type="number" value={this.state.values[1]} name="1" onChange={this.onChange}/>
              <label>{lang.t('health:log.heartrate')} (bpm)</label>
              <input type="number" value={this.state.values[11]} name="11" onChange={this.onChange}/>
              <label>{lang.t('health:log.notes')}</label>
              <textarea value={this.state.values[2]} name="2" onChange={this.onChange}></textarea>
            </div>
          </div>

          <div className="col-5 none">
            <div className="box healthlog segmental">
              <h3>{lang.t('header:health.segmental')}</h3>
              <div className="form clear">
                <label><span>1</span>{lang.t('health:log.neck')} ({global.userLbs ? 'in' : 'cm'})</label>
                <input type="number" value={this.state.values[3]} name="3" onChange={this.onChange}/>
                <label><span>2</span>{lang.t('health:log.chest')} ({global.userLbs ? 'in' : 'cm'})</label>
                <input type="number" value={this.state.values[4]} name="4" onChange={this.onChange}/>
                <label><span>3</span>{lang.t('health:log.abdomen')} ({global.userLbs ? 'in' : 'cm'})</label>
                <input type="number" value={this.state.values[5]} name="5" onChange={this.onChange}/>
                <label><span>4</span>{lang.t('health:log.hip')} ({global.userLbs ? 'in' : 'cm'})</label>
                <input type="number" value={this.state.values[6]} name="6" onChange={this.onChange}/>
                <label><span>5</span>{lang.t('health:log.rightarm')} ({global.userLbs ? 'in' : 'cm'})</label>
                <input type="number" value={this.state.values[7]} name="7" onChange={this.onChange}/>
                <label><span>6</span>{lang.t('health:log.leftarm')} ({global.userLbs ? 'in' : 'cm'})</label>
                <input type="number" value={this.state.values[8]} name="8" onChange={this.onChange}/>
                <label><span>7</span>{lang.t('health:log.rightthigh')} ({global.userLbs ? 'in' : 'cm'})</label>
                <input type="number" value={this.state.values[9]} name="9" onChange={this.onChange}/>
                <label><span>8</span>{lang.t('health:log.leftthigh')} ({global.userLbs ? 'in' : 'cm'})</label>
                <input type="number" value={this.state.values[10]} name="10" onChange={this.onChange}/>
              </div>
            </div>
          </div>

          <div className="clear sv-40"></div>
          <div className="width-4">
            <button className="btn primary edit" onClick={() => this.updateAssessment()}>{lang.t('health:button.updatelog')}</button>
          </div>
          <div className="clear sv-20"></div>
          <button className="btn tertiary close width-12" onClick={() => this.showOverlayDelete()}>{lang.t('health:button.deletelog')}</button>
          <div className="clear sv-20"></div>
          <button className="btn tertiary close width-12" onClick={() => this.cancelEditing()}>{lang.t('common:button.cancel')}</button>
          <div className="clear sv-40"></div>
        </div>

        <div className="header">
          <div className="content">
            <Link to={'/'+global.uid+'/health/log'} className="back lft mr-10">
              <div className="circle"></div>
              <div className="arrow"></div>
            </Link>
            <h2>{lang.t('header:title.healthlog')} - {this.renderTitle()}</h2>
          </div>
        </div>
        <Navigation active='health' />
        {this.renderOverlayDelete()}
      </div>
    )
  }


  renderProgress() {
    if(this.state.list.length > 1) {
      return (
        <button className="btn tertiary small rgt" onClick={() => this.showOverlayImages()}>{lang.t('health:button.viewprogress')}</button>
      )
    }
  }


  renderMeasure(value) {
    var label = value.toFixed(1)
    if(global.userLbs) {
      label = (value/2.54).toFixed(1)
    }
    return label
  }


  renderInfoCustom() {
    if(this.state.custom !== '') {
      return <InfoData label='Notes' value={this.state.item.data.notes === '' ? '-' : this.state.item.data.notes}/>
    }
  }


  renderInfo() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-sidebar">

          <div className="col-6 mt-20">
            <h3 className="mb-20 lft">{lang.t('header:health.images')}</h3>
            {this.renderProgress()}
            <div className="col-6 mb-20 clear">
              <div className="healthlog image mb-10">
                {this.displayImage(this.state.file, this.state.image, this.state.fileDisplay)}
              </div>
              <label>{lang.t('health:label.front')}</label>
            </div>
            <div className="col-6 mb-20">
              <div className="healthlog image mb-10">
                {this.displayImage(this.state.file2, this.state.image2, this.state.fileDisplay2)}
              </div>
              <label>{lang.t('health:label.back')}</label>
            </div>
            <div className="col-6 mb-20">
            <div className="healthlog image mb-10">
                {this.displayImage(this.state.file3, this.state.image3, this.state.fileDisplay3)}
              </div>
              <label>{lang.t('health:label.left')}</label>
            </div>
            <div className="col-6 mb-20">
            <div className="healthlog image mb-10">
                {this.displayImage(this.state.file4, this.state.image4, this.state.fileDisplay4)}
              </div>
              <label>{lang.t('health:label.right')}</label>
            </div>
          </div>

          <div className="col-6 pl-30 mt-20">
            <h3 className="mb-20">Overview</h3>
            <div className="clear"></div>
            <InfoData label='Weight' value={this.state.item.data.weight === 0 ? '-' : HelperPro.showWeight(this.state.item.data.weight.toFixed(1))+' '+(global.spaceLbs ? 'lb' : 'kg')}/>
            <InfoData label='Body fat percentage' value={this.state.item.data.fat === 0 ? '-' : this.state.item.data.fat.toFixed(1)+'%'}/>
            <InfoData label='Resting heart rate' value={(this.state.item.data.heart === undefined || this.state.item.data.heart === 0) ? '-' : this.state.item.data.heart.toFixed(0)+' bpm'}/>
            <InfoData label='Blood pressure' value={(this.state.blood === undefined || this.state.blood === '') ? '-' : this.state.blood}/>
            {this.renderInfoCustom()}
            <InfoData label='Notes' value={this.state.item.data.notes === '' ? '-' : this.state.item.data.notes}/>
            <div className="sv-30"></div>
            <div className="healthlog segmental">
              <h3 className="mb-20">{lang.t('header:health.segmental')}</h3>
              <div className="info clear">
                <div className="circle lft mr-10">1</div>
                  <div className="main">
                  <label>{lang.t('health:log.neck')}</label>
                  <p>{this.state.item.data.neck === 0 ? '-' : this.renderMeasure(this.state.item.data.neck)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
              <div className="info clear">
                <div className="circle lft mr-10">2</div>
                  <div className="main">
                  <label>{lang.t('health:log.chest')}</label>
                  <p>{this.state.item.data.chest === 0 ? '-' : this.renderMeasure(this.state.item.data.chest)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
              <div className="info clear">
                <div className="circle lft mr-10">3</div>
                  <div className="main">
                  <label>{lang.t('health:log.abdomen')}</label>
                  <p>{this.state.item.data.abdomen === 0 ? '-' : this.renderMeasure(this.state.item.data.abdomen)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
              <div className="info clear">
                <div className="circle lft mr-10">4</div>
                  <div className="main">
                  <label>{lang.t('health:log.hip')}</label>
                  <p>{this.state.item.data.hip === 0 ? '-' : this.renderMeasure(this.state.item.data.hip)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
              <div className="info clear">
                <div className="circle lft mr-10">5</div>
                  <div className="main">
                  <label>{lang.t('health:log.rightarm')}</label>
                  <p>{this.state.item.data.armR === 0 ? '-' : this.renderMeasure(this.state.item.data.armR)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
              <div className="info clear">
                <div className="circle lft mr-10">6</div>
                  <div className="main">
                  <label>{lang.t('health:log.leftarm')}</label>
                  <p>{this.state.item.data.armL === 0 ? '-' : this.renderMeasure(this.state.item.data.armL)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
              <div className="info clear">
                <div className="circle lft mr-10">7</div>
                  <div className="main">
                  <label>{lang.t('health:log.rightthigh')}</label>
                  <p>{this.state.item.data.thighR === 0 ? '-' : this.renderMeasure(this.state.item.data.thighR)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
              <div className="info clear">
                <div className="circle lft mr-10">8</div>
                  <div className="main">
                  <label>{lang.t('health:log.leftthigh')}</label>
                  <p>{this.state.item.data.thighL === 0 ? '-' : this.renderMeasure(this.state.item.data.thighL)+' '+(global.spaceLbs ? 'in' : 'cm')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="clear sv-40"></div>

          <div className="width-4">
            {this.renderButtonEdit()}
          </div>
          <div className="clear sv-40"></div>
        </div>

        <div className="header">
          <div className="content">
            <Link to={'/'+global.uid+'/health/log'} className="back lft mr-10">
              <div className="circle"></div>
              <div className="arrow"></div>
            </Link>
            <h2>{lang.t('header:title.healthlog')} - {this.renderTitle()}</h2>
          </div>
        </div>
        <Navigation active='health' />
        {this.renderOverlayImages()}
      </div>
    )
  }


  renderBase() {
    if(this.state.editing && this.state.item !== null) {
      return this.renderEdit()
    } else if(!this.state.editing && this.state.item !== null) {
      return this.renderInfo()
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content"></div>
          <div className="header">
            <div className="content">
              <Link to={'/'+global.uid+'/health/log'} className="back lft mr-10">
                <div className="circle"></div>
                <div className="arrow"></div>
              </Link>
              <h2>{lang.t('header:title.healthlog')}</h2>
            </div>
          </div>
          <Navigation active='health' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['header','health','common','modal','message'])(withRouter(withAuthorization(condition)(MemberAsessmentPage)));