import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
  apiKey: "AIzaSyDnHw6SJ6jtDvHnpNEEnQCRD1y3_pDGmCE",
  authDomain: "ptmate-c6f70.firebaseapp.com",
  databaseURL: "https://ptmate-c6f70.firebaseio.com",
  projectId: "ptmate-c6f70",
  storageBucket: "ptmate-c6f70.appspot.com",
  messagingSenderId: "834489825060",
  appId: "1:834489825060:web:67ca4f0438f02d3b"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.database();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    //this.auth.signInWithPopup(this.googleProvider);
    this.auth.signInWithRedirect(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  
  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** User API ***
  user = uid => this.db.ref('users/'+uid);
  users = () => this.db.ref('users');

  // *** Message API ***
  message = uid => this.db.ref(`messages/${uid}`);
  plans = () => this.db.ref('admin/au');
}

export default Firebase;