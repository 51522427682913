import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import HelperEmptyImg from '../../helper/EmptyLarge';
import { gql } from "@apollo/client";
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperPro from '../../helper/Programming';
import InfoBar from '../../components/Form/info-bar';
import WidgetChartGraph from '../../components/Widgets/chart-graph';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberHealthPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      nutrition: null,
      nutritionWeek: null,
      nutritionMeal: null,
      num: global.num,
      healthlog: global.healthlog,
      dataGraph: [
        {
          "id": "japan",
          "color": "#81DB24",
          "data": []
        },
      ],
      currentGraph: 'weight',
    };
  }


  componentDidMount() {
    //global.nutritionData = null
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    if(id !== 'health' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.health')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    this.configureData()

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.health')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    this.setState({
      healthlog: global.healthlog,
    })
    if(global.userNutritionId !== undefined) {
      if(global.nutritionData === null) {
        this.getNutritionData()
      } else {
        this.processNutritionData(global.nutritionData)
      }
      
    }
    this.createGraph(this.state.currentGraph)
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }


  getNutritionData() {
    const client = Connector.createApolloClient()
    const query = gql`
    query getUserData($id: Int!) {
      users(where: { id: { _eq: $id} }) {
        id
        sex
        bmr
        weight
        weight_unit
        u_weight
        u_goal_weight
        u_current_weight
        goal
        goal_weight
        current_weight
        activity_level
        form_status
        current_period_start
        current_period_end
        v_type
        v_meals
        
        dinner_serves
        leftover_lunches
        cheat_meal
        dessert
        snack_morning
        snack_afternoon
        snack_evening
        cold_breakfast
        cold_lunch
        rotate_breakfast
        
        gluten
        wheat
        lactose
        milk
        egg
        nuts
        shellfish
        fish
        red_meat
        pork
        chicken
        turkey
        fruit
        vegetable
        grain
        herb_spice
        legume
        berry
        lamb
        preference_length
        preference_string
        
        alt_vol_in_recipes
        last_protein
        
        plan_type
        snack_morning_protein
        snack_afternoon_protein
        snack_evening_protein

        weights {
          date_recorded
          weight
        }

        plans(limit: 3, order_by: {created_at: desc}) {
          id
          start_date
          stop_date
          daily_cals
          starting_weight
          bmr
          target_fat
          target_carbs
          target_protein
          meals {
            id
            recipe_id
            next_meal_id
          }
          shopping_lists {
            id
            start_date
            stop_date
            list_items {
              id
              shopping_list_id
              name
              checked
              amount
              base_amount
              shopping_cat
              kind
              measurement
              gluten
            }
          }
        }
        recipe_preferences {
          id
          user_id
          recipe_id
          rating
        }
      }
    }`;

    client.query({
      variables: { id: parseInt(global.userNutritionId) },
      query
    }).then((result) => {
      this.processNutritionData(result.data)
    }).catch((error) => {
      console.log(error);
    });
  }


  processNutritionData(data) {
    var weights = []
    if(data.users[0].weights !== null) {
      for(var wgt of data.users[0].weights) {
        weights.push({"y": wgt.weight, "x": Moment(wgt.date_recorded, 'YYYY-MM-DD').format('DD/MM')})
      }
    }
    var tmp = [{
      "id": "",
      "color": "#81DB24",
      data: weights
    }]
    this.setState({
      userPassed: true,
      nutrition: data,
      graph: tmp
    }, () => {
      if(this.state.nutrition.users[0].form_status !== global.userNutritionTrial && this.state.nutrition.users[0].form_status !== null) {
        Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient).update({
          nutritionStatus: this.state.nutrition.users[0].form_status
        })
      }
      var cps = Moment(this.state.nutrition.users[0].current_period_start, 'YYYY-MM-DDTHH:mm:ss.SSS')
      var cpe = Moment(this.state.nutrition.users[0].current_period_end, 'YYYY-MM-DDTHH:mm:ss.SSS')
      if(cps !== Moment(global.userNutritionStart, 'X') && this.state.nutrition.users[0].current_period_start !== null && this.state.nutrition.users[0].current_period_end !== null) {
        Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient).update({
          nutritionStart: parseInt(cps.format('X')),
          nutritionEnd: parseInt(cpe.format('X'))
        })
      }
    })
  }


  updateGraph(event) {
    this.setState({
      currentGraph: event.target.value,
      dataGraph: [
        {
          "id": "japan",
          "color": "#81DB24",
          "data": []
        },
      ],
    })
    this.createGraph(event.target.value)
  }


  createGraph(value) {
    if(this.state.item !== null) {
      var tmp = []
      var list = this.state.healthlog
      list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));
      for(var ass of list) {
        var val = 0
        if(ass.data.weight !== undefined) {
          val = HelperPro.showWeight(ass.data.weight.toFixed(1))
        }
        if(value === 'fat') {
          val = ass.data.fat.toFixed(1)
        } else if(value === 'neck') {
          val = ass.data.neck.toFixed(1)
        } else if(value === 'abdomen') {
          val = ass.data.abdomen.toFixed(1)
        } else if(value === 'hip') {
          val = ass.data.hip.toFixed(1)
        } else if(value === 'chest') {
          val = ass.data.chest.toFixed(1)
        } else if(value === 'rarm') {
          val = ass.data.armR.toFixed(1)
        } else if(value === 'larm') {
          val = ass.data.armL.toFixed(1)
        } else if(value === 'rthigh') {
          val = ass.data.thighR.toFixed(1)
        } else if(value === 'lthigh') {
          val = ass.data.thighL.toFixed(1)
        } else if(value === 'rhr') {
          if(ass.data.heart !== undefined) {
            val = ass.data.heart.toFixed(0)
          } else {
            val = 0
          }
        }

        tmp.push({
          "x": Moment(ass.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YY'),
          "y": val,
        })
      }

      var tmp2 = [{
        "id": "",
        "color": "#81DB24",
        data: tmp
      }]

      setTimeout(() => {
        this.setState({dataGraph: tmp2});
      }, 100);
    }
  }



  // Sidebar calculations ------------------------------------------------------------



  getBmr() {
    var label = '-'
    if(this.state.nutrition !== null) {
      if(this.state.nutrition.users.length > 0) {
        if(this.state.nutrition.users[0].plans.length > 0) {
          if(this.state.nutrition.users[0].plans[0].bmr === undefined) {
            label = '-'
          } else {
            label = this.state.nutrition.users[0].plans[0].bmr.toFixed(0)+'cal'
          }
        }
      }
      if(Moment(global.userNutritionEnd, 'X') < Moment()) {
        label = '-'
      }
    }
    return label
  }


  getDayProtein(type) {
    var label = '-'
    if(type === 'bar') {
      label = '100%'
    }
    if(this.state.nutrition !== null) {
      if(this.state.nutrition.users.length > 0) {
        if(this.state.nutrition.users[0].plans.length > 0) {
          if(this.state.nutrition.users[0].plans[0].target_protein === undefined) {
            label = '-'
            if(type === 'bar') {
              label = '100%'
            }
          } else {
            if(type === 'bar') {
              label = (100-this.state.nutrition.users[0].plans[0].target_protein*100)+'%'
            } else {
              label = (this.state.nutrition.users[0].plans[0].target_protein*100)+'%'
            }
          }
        }
      }
      if(Moment(global.userNutritionEnd, 'X') < Moment() && type !== 'bar') {
        label = '-'
      }
      if(Moment(global.userNutritionEnd, 'X') < Moment() && type === 'bar') {
        label = '100%'
      }
    }
    return label
  }


  getDayCarbs(type) {
    var label = '-'
    if(type === 'bar') {
      label = '100%'
    }
    if(this.state.nutrition !== null) {
      if(this.state.nutrition.users.length > 0) {
        if(this.state.nutrition.users[0].plans.length > 0) {
          if(this.state.nutrition.users[0].plans[0].target_carbs === undefined) {
            label = '-'
            if(type === 'bar') {
              label = '100%'
            }
          } else {
            if(type === 'bar') {
              label = (100-this.state.nutrition.users[0].plans[0].target_carbs*100)+'%'
            } else {
              label = (this.state.nutrition.users[0].plans[0].target_carbs*100)+'%'
            }
            
          }
        }
      }
      if(Moment(global.userNutritionEnd, 'X') < Moment() && type !== 'bar') {
        label = '-'
      }
      if(Moment(global.userNutritionEnd, 'X') < Moment() && type === 'bar') {
        label = '100%'
      }
    }
    return label
  }


  getDayFat(type) {
    var label = '-'
    if(type === 'bar') {
      label = '100%'
    }
    if(this.state.nutrition !== null) {
      if(this.state.nutrition.users.length > 0) {
        if(this.state.nutrition.users[0].plans.length > 0) {
          if(this.state.nutrition.users[0].plans[0].target_fat === undefined) {
            label = '-'
            if(type === 'bar') {
              label = '100%'
            }
          } else {
            if(type === 'bar') {
              label = (100-this.state.nutrition.users[0].plans[0].target_fat*100)+'%'
            } else {
              label = (this.state.nutrition.users[0].plans[0].target_fat*100)+'%'
            }
          }
        }
      }
      if(Moment(global.userNutritionEnd, 'X') < Moment() && type !== 'bar') {
        label = '-'
      }
      if(Moment(global.userNutritionEnd, 'X') < Moment() && type === 'bar') {
        label = '100%'
      }
    }
    return label
  }


  getBpm(type) {
    var label = '-'
    if(type === 'bar') {
      label = '0px'
    } else if(type === 'color') {
      label = ''
    }
    var date = Moment('01/01/1900', 'DD/MM/YYYY')
    for(var item of this.state.healthlog) {
      if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > date && item.data.heart !== undefined && item.data.heart !== 0) {
        label = item.data.heart.toFixed(0)
        date = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
        if(type === 'bar') {
          label += '%'
        }
        if(type === 'color' && item.data.heart < 61) {
          label = 'green'
        }
        if(type === 'color' && item.data.heart > 60 && item.data.heart < 66) {
          label = 'yellow'
        }
        if(type === 'color' && item.data.heart > 65 && item.data.heart < 76) {
          label = 'orange'
        }
        if(type === 'color' && item.data.heart > 75) {
          label = 'red'
        }
      }
    }
    return label
  }


  getFatColor(value) {
    var label = 'green'
    var sex = 'f'
    if(this.state.nutrition !== null) {
      sex = this.state.nutrition.users[0].sex
    }
    if((value < 14 && sex === 'f') || (value > 25 && sex === 'f')) {
      label = 'orange'
    }
    if((value < 10 && sex === 'f') || (value > 32 && sex === 'f')) {
      label = 'red'
    }
    if((value < 6 && sex === 'm') || (value > 18 && sex === 'm')) {
      label = 'orange'
    }
    if((value < 2 && sex === 'm') || (value > 25 && sex === 'm')) {
      label = 'red'
    }
    return label
  }


  getFat(type) {
    var label = '-'
    if(type === 'bar') {
      label = '0px'
    } else if(type === 'color') {
      label = ''
    }
    var date = Moment('01/01/1900', 'DD/MM/YYYY')
    for(var item of this.state.healthlog) {
      if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > date && item.data.fat !== undefined && item.data.fat !== 0) {
        label = item.data.fat.toFixed(1)
        date = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
        if(type === 'bar') {
          label = (item.data.fat.toFixed(1)*2)+'%'
        }
        if(type === 'color') {
          label = this.getFatColor(item.data.fat)
        }
      }
    }
    return label
  }


  getBlood1(type) {
    var label = '-'
    if(type === 'bar') {
      label = '0px'
    } else if(type === 'color') {
      label = ''
    }
    var date = Moment('01/01/1900', 'DD/MM/YYYY')
    for(var item of global.healthlog) {
      if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > date && item.data.blood1 !== undefined) {
        if(item.data.blood1 !== '') {
          label = parseInt(item.data.blood1)
          date = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
          if(type === 'bar') {
            label = label*0.7
            label += '%'
          }
          if(type === 'color' && item.data.blood1 < 121) {
            label = 'green'
          }
          if(type === 'color' && item.data.blood1 > 120 && item.data.blood1 < 130) {
            label = 'yellow'
          }
          if(type === 'color' && item.data.blood1 > 129 && item.data.blood1 < 140) {
            label = 'orange'
          }
          if(type === 'color' && item.data.blood1 > 140) {
            label = 'red'
          }
        }
        if(type === 'value' && label) {
          if(item.data.blood2 !== undefined) {
            if(item.data.blood2 !== '') {
              label += '/'+item.data.blood2
            } else {
              label += '/-'
            }
          } else {
            label += '/-'
          }
        }
      }
    }
    
    return label
  }



  // Display stuff ------------------------------------------------------------



  renderWeightBar() {
    if(this.state.nutrition !== null) {
      if(this.state.nutrition.users[0].goal_weight !== null) {
        var w1 = 0
        var w2 = this.state.nutrition.users[0].goal_weight
        var w3 = 0
        var date = Moment('01/01/1900', 'DD/MM/YYYY')
        for(var item of this.state.healthlog) {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > date && item.data.weight !== undefined && item.data.weight !== 0) {
            w1 = item.data.weight
            date = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
          }
        }
        if(this.state.healthlog.length > 0) {
          w3 = this.state.healthlog[0].data.weight
        }
        var range = w3-w2
        var current = w3-w1
        var per = current/range*100
        if(per < 0 || w3 === 0) {
          per = 0
        }
        return (
          <div className="inner default" style={{width: per+'%'}}></div>
        )
      }
    }
  }


  renderWeight(type) {
    if(this.state.nutrition !== null && type === 'goal') {
      if(type === 'goal') {
        if(this.state.nutrition.users[0].goal_weight !== null) {
          return (
            <p>{HelperPro.showWeight(this.state.nutrition.users[0].goal_weight)}<span>{global.userLbs ? 'lb' : 'kg'}</span></p>
          )
        } else {
          return <p>-</p>
        }
      }
    } else {
      var label = '-'
      var date = Moment('01/01/1900', 'DD/MM/YYYY')
      for(var item of this.state.healthlog) {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > date && item.data.weight !== undefined && item.data.weight !== 0) {
          label = item.data.weight.toFixed(1)
          date = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
        }
      }
      if(label === '-') {
        return <p>-</p>
      } else {
        return <p>{HelperPro.showWeight(label)}<span>{global.userLbs ? 'lb' : 'kg'}</span></p>
      }
    }
  }


  renderCalories() {
    if(this.state.nutrition !== null) {
      if(this.state.nutrition.users[0].plans.length > 0) {
        if(this.state.nutrition.users[0].plans[0].daily_cals !== null) {
          if(Moment(global.userNutritionEnd, 'X') < Moment()) {
            return <p>-</p>
          } else {
            return (
              <p>{this.state.nutrition.users[0].plans[0].daily_cals}<span>cal</span></p>
            )
          }
        } else {
          return <p>-</p>
        }
      } else {
        return <p>-</p>
      }
    } else {
      return <p>-</p>
    }
  }


  renderHistory() {
    if(this.state.healthlog.length === 0) {
      return (
        <div>
          <div className="lsitheader clear">
            <h3>{lang.t('header:health.healthlog')}</h3>
          </div>
          <div className="clear empty large">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('health')}
            </div>
            <div className="data">
              <h3 className="mb-20">{lang.t('empty:log.title')}</h3>
              <p>{lang.t('empty:log.text')}</p>
              <Link to={'/'+global.uid+'/health/new-log'} className="btn tertiary add width-12">{lang.t('health:button.newlogentry')}</Link>
            </div>
          </div>
        </div>
      )
    } else {
      var list = this.state.healthlog
      list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));
      return (
        <div>
          <div className="listheader clear">
            <h3 className="lft">{lang.t('header:health.healthlog')}</h3>
            <Link to={'/'+global.uid+'/health/new-log'} className="btn tertiary add rgt">{lang.t('health:button.newlogentry')}</Link>
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <div className="col-2" key={item.id}>
              <div className="box card small highlight mb-20">
                <Link to={'/'+global.uid+'/health/log-entry/'+item.id}>
                  <div className="icon secondary mb-10">
                    <div className="inner healthlog"></div>
                  </div>
                  <h4 className="mb-10 clear">{Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('D MMM YYYY')}</h4>
                  <p>{item.data.weight !== undefined ? HelperPro.showWeight(item.data.weight.toFixed(1)) : '-'} {global.userLbs ? 'lb' : 'kg'}</p>
                  <div className="sv-20"></div>
                  <button className="btn tertiary small width-12">{lang.t('common:button.viewdetails')}</button>
                  <div className="clear"></div>
                </Link>
              </div>
            </div>
          ))}
          <div className="col-2">
            <button className="inline-add card small mb-20" onClick={() => this.props.history.push('/'+global.uid+'/health/new-log')}></button>
          </div>
          <div className="clear sv-20"></div>
        </div>
      )
    }
  }


  renderContent() {
    return (
      <div>
        <div className="col-9 withsidebar">
          <div className="lft mt-5">
            <h3 className="mb-20">{lang.t('header:health.progress')}</h3>
          </div>
          <div className="rgt" style={{width: 200}}>
            <select className="light rgt" value={this.state.currentGraph} onChange={event => this.updateGraph(event)}>
              <option value='weight'>{lang.t('health:log.weight')}</option>
              <option value='fat'>{lang.t('health:log.bodyfat')}</option>
              <option value='rhr'>{lang.t('health:log.heartrate')}</option>
              <option value='neck'>{lang.t('health:log.neck')}</option>
              <option value='chest'>{lang.t('health:log.chest')}</option>
              <option value='abdomen'>{lang.t('health:log.abdomen')}</option>
              <option value='hip'>{lang.t('health:log.hip')}</option>
              <option value='rarm'>{lang.t('health:log.rightarm')}</option>
              <option value='larm'>{lang.t('health:log.leftarm')}</option>
              <option value='lthigh'>{lang.t('health:log.rightthigh')}</option>
              <option value='rthigh'>{lang.t('health:log.leftthigh')}</option>
            </select>
          </div>
          <div className="clear graph">
            <WidgetChartGraph data={this.state.dataGraph} curve='linear'/>
          </div>
          <div className="clear"></div>
          {this.renderHistory()}
        </div>

        <div className="col-3">
          <h3 className="mb-20 mt-5">{lang.t('header:health.weight')}</h3>
          <div className="clear info">
            <label className="lft">{lang.t('health:log.current')}</label>
            <label className="rgt">{lang.t('health:log.goal')}</label>
            <div className="clear"></div>
            <div className="bar">
              {this.renderWeightBar()}
            </div>
            <p className="lft">{this.renderWeight('current')}</p>
            <p className="rgt">{this.renderWeight('goal')}</p>
            <div className="clear"></div>
          </div>
          <div className="clear sv-30"></div>

          <div className="mb-50">
            <h3 className="mb-20">{lang.t('header:health.health')}</h3>
            <InfoBar label='Resting heart rate' value={this.getBpm('value')+(this.getBpm('value') === '-' ? '' : 'bpm')} color={this.getBpm('color')} width={this.getBpm('bar')}/>
            <InfoBar label='Body fat' value={this.getFat('value')+(this.getFat('value') === '-' ? '' : '%')} color={this.getFat('color')} width={this.getFat('bar')}/>
            <InfoBar label='Blood pressure' value={this.getBlood1('value')} color={this.getBlood1('color')} width={this.getBlood1('bar')}/>
          </div>

          <div className="mb-50">
            <h3 className="mb-20">{lang.t('header:health.targets')}</h3>
            <div className="info">
              <div className="icon primary lft mr-10">
                <div className="inner cal"></div>
              </div>
              <div className="main lft">
                <label>{lang.t('health:log.calories')}</label>
                <p>{this.renderCalories()}</p>
              </div>
              <div className="clear"></div>
            </div>
            <div className="info">
              <div className="icon primary lft mr-10">
                <div className="inner training"></div>
              </div>
              <div className="main lft">
                <label>{lang.t('health:log.basal')}</label>
                <p>{this.getBmr()}</p>
              </div>
              <div className="clear"></div>
            </div>
            <div className="info">
              <div className="icon quarternary lft mr-10">
                <div className="inner protein"></div>
              </div>
              <div className="main lft">
                <label>{lang.t('health:log.protein')}</label>
                <p>{this.getDayProtein('%')}</p>
              </div>
              <div className="clear"></div>
            </div>
            <div className="info">
              <div className="icon quarternary lft mr-10">
                <div className="inner carbs"></div>
              </div>
              <div className="main lft">
                <label>{lang.t('health:log.carbs')}</label>
                <p>{this.getDayCarbs('%')}</p>
              </div>
              <div className="clear"></div>
            </div>
            <div className="info">
              <div className="icon quarternary lft mr-10">
                <div className="inner fat"></div>
              </div>
              <div className="main lft">
                <label>{lang.t('health:log.fat')}</label>
                <p>{this.getDayFat('%')}</p>
              </div>
              <div className="clear"></div>
            </div>
          </div>
        </div>
        <div className="sv-40 clear"></div>
      </div>
    )
  }


  renderHabits() {
    if(global.spaceAllowHabits) {
      return (
        <Link to={'/'+global.uid+'/health/habits'} className="tab">
          <p>{lang.t('nav:health.habits')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content member">
          <div className="member-header mb-10">
            <div className={this.getBg()}>
              <div className={'gradient theme-'+global.spaceTheme}></div>
            </div>
            <div className="member-main">
              <div className="business">
                <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
                <h3>{global.userBusiness}<br/><span>{lang.t('header:title.memberarea')}</span></h3>
              </div>
              <h2 className="mb-30">{lang.t('header:title.health')}</h2>
              <Link to={'/'+global.uid+'/health/nutrition'} className="tab">
                <p>{lang.t('nav:health.meal')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/health/log'} className="tab active">
                <p>{lang.t('nav:health.log')}</p>
                <div className="bg"></div>
              </Link>
              {this.renderHabits()}
            </div>
            <div className="clear"></div>
          </div>
          <div className="clear sv-20"></div>
          {this.renderContent()}
        </div>
        <Navigation active='health' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['nav','header','empty','health','common'])(withRouter(withAuthorizationMember(condition)(MemberHealthPage)));