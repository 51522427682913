import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import EventEmitter from '../../helper/Emitter';
import HelperPage from '../../helper/Page';

import Message from '../Message';
import Chat from '../Message/chat';
import Location from '../Navigation/location';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class Navigation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
      name: global.userName,
      showMenu: false,
      mobile: '',
      class: 'submenu out none',
      showOverlayTrainer: false,
      hiddenTrainer: 'hidden',
      margin: 0,
    };
  }


  componentDidMount() {
    EventEmitter.subscribe('userLoaded', (event) => {
      this.setState({
        name: global.userName,
      })
    })
  }


  getImage() {
    if(global.userImage === '' && global.userName !== null) {
      var inits = ''
      let arr = global.userName.split(' ')
      if(arr.length > 1) {
        inits = arr[0].charAt(0)+arr[1].charAt(0)
      } else {
        inits = arr[0].charAt(0)
      }
      if(global.userAvatar !== '') {
        return <div className={'avatar '+global.userAvatar}></div>
      } else {
        return (
          <div className="avatar">
            <p>{inits}</p>
          </div>
        )
      }
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+global.userImage+')'}}> </div>
      )
    }
  }


  showSubmenu() {
    if(!this.state.showing) {
      this.setState({
        class: 'submenu out',
      })
      setTimeout(() => {
        this.setState({
          class: 'submenu',
          showing: true
        })
      }, 10);
    }
  }


  hideSubmenu() {
    this.setState({
      class: 'submenu out',
      showing: false,
    })
    setTimeout(() => {
      this.setState({
        class: 'submenu out none',
        submenu: '',
      })
    }, 260);
  }


  logoutUser() {
    HelperPage.signOut()
  }


  rener


  render() {
    return (
      <div onMouseLeave={() => this.hideSubmenu()}>
        <div className={this.state.class}>
          <div className="menus">
            <div className="menu account">
              <h4>{global.userName}</h4>
              <Link to={'/'+global.uid+'/account'} className="item">
                <div className="bg"></div>
                <div className="text">{lang.t('nav:account.details')}</div>
              </Link>
              <Link to={'/'+global.uid+'/spaces'} className="item">
                <div className="bg"></div>
                <div className="text">{lang.t('nav:account.managespaces')}</div>
              </Link>
              <a href="https://help.ptmate.net" target="_blank" rel="noopener noreferrer" className="item">
                <div className="bg"></div>
                <div className="text">{lang.t('nav:account.help')}</div>
              </a>
              <div className="item" onClick={() => this.logoutUser()}>
                <div className="bg"></div>
                <div className="text">{lang.t('nav:account.logout')}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="nav">
          <div className="logo">
            <img src="/img/ptmate.svg" alt="PT Mate"/>
          </div>
          <div className="nav-items">
            <Link to={'/'+global.uid+'/home'} className={this.state.active === 'overview' ? 'nav-item home mb-10 active' : 'nav-item home mb-10'}></Link>
            <Link to={'/'+global.uid+'/calendar'} className={this.state.active === 'calendar' ? 'nav-item calendar mb-10 active' : 'nav-item calendar mb-10'}></Link>
            <Link to={'/'+global.uid+'/training/sessions'} className={this.state.active === 'training' ? 'nav-item programming mb-10 active' : 'nav-item programming mb-10'}></Link>
            <Link to={'/'+global.uid+'/health/nutrition'} className={this.state.active === 'health' ? 'nav-item health mb-10 active' : 'nav-item health mb-10'}></Link>
            <Link to={'/'+global.uid+'/admin/forms'} className={this.state.active === 'admin' ? 'nav-item admin mb-10 active' : 'nav-item admin mb-10'}></Link>
          </div>
          <Link to={'/'+global.uid+'/account'} onMouseEnter={() => this.showSubmenu()}>
            {this.getImage()}
          </Link>
        </div>
        <Chat/>
        <Location/>
        <Message/>
      </div>
    )
  }
}


export default  withTranslation(['nav'])(withFirebase(Navigation));