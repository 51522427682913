import HelperCal from '../helper/Calendar';



class HelperProgramming {


  // Block type

  getBlockType(item) {
    var label = ''
    var s = ''
    var time1 = (parseInt(item.min)*60)+parseInt(item.sec)
    var time2 = (parseInt(item.min2)*60)+parseInt(item.sec2)
    if(item.rounds > 1) {
      s = 's'
    }
    if(item.type === 0) {
      label = ' - '+HelperCal.getDurationMin(time1)
    }
    if(item.type === 1) {
      label = ' - '+item.rounds+' round'+s+' of '+HelperCal.getDurationMinAlt(time1)
      if(item.emom) {
        label = ' - '+item.rounds+' round'+s+' of '+HelperCal.getDurationMinAlt(time1)+' (All movements at the same time)'
      }
    }
    if(item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' rounds'
      if(item.rounds === 1) {
        label = ' - 1 round'
      }
      if(item.type === 2 && item.emom) { 
        if(time2 === 0) {
          label += ' of '+HelperCal.getDurationMinAlt(time1)+' work & no rest'
        } else {
          label += ' of '+HelperCal.getDurationMinAlt(time1)+' work & '+HelperCal.getDurationMinAlt(time2)+' rest'
        }
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' sets'
      if(item.rounds === 1) {
        label = ' - 1 set'
      }
    }
    if(item.type === 5) {
      label = ' - '+HelperCal.getDurationMin(time1)
      if(item.rounds > 1) {
        label = ' - '+item.rounds+' rounds - Cap: '+HelperCal.getDurationMin(time1)
      }
    }
    return label
  }


  // Display weight

  showWeight(weight) {
    var label = weight
    if(global.userLbs) {
      if(!isNaN(+weight)) {
        label = (weight*global.lbsUp).toFixed(0)
      } else {
        var ar = weight.split('-')
        label = ''
        for(var a of ar) {
          if(!isNaN(+a)) {
            label += (a*global.lbsUp).toFixed(1)+'-'
          }
        }
        if(ar.length > 0) {
          label = label.slice(0, -1)
        }
      }
    }
    return label
  }


  // Calculate weight to save

  calculateWeight(weight) {
    var label = weight
    if(global.userLbs) {
      if(!isNaN(+weight)) {
        label = (weight*global.lbsDown).toFixed(2)
      } else {
        var ar = weight.split('-')
        label = ''
        for(var a of ar) {
          if(!isNaN(+a) && a !== '') {
            label += (a*global.lbsDown).toFixed(2)+'-'
          }
        }
        if(ar.length > 0) {
          label = label.slice(0, -1)
        }
      }
    }
    return label
  }

}


const b = new HelperProgramming();
export default b;