import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import { gql } from "@apollo/client";
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperEmptyImg from '../../helper/EmptyLarge';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberPreferencesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      weight: 0,
      weight2: 0,
      lowCarb: false,
      portions: 1,
      vtype: 0,
      vmeals: 0,
      cheat: 0,
      leftovers: false,
      dessert: false,
      snack: false,
      gluten: false,
      proteins: [false, false, false, false, false, false],
      ingredients: [false, false, false],
      psnacks: [false, false, false],
      breakfast: false,
      details: [global.coupon, 'm', global.userBday, '3-5'],
      otype: 0,
      loading: false,
      new: false,
      error: [false, false, false, false, false, false, false]
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    if(id !== 'health' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.preferences')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())

    if(global.nutritionData === null) {
      this.getNutritionData()
    } else {
      this.configureData()
    }

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.preferences')+' - '+global.userBusiness
        if(global.nutritionData === null) {
          this.getNutritionData()
        } else {
          this.configureData()
        }
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  getNutritionData() {
    const client = Connector.createApolloClient()
    const query = gql`
    query getUserData($id: Int!) {
      users(where: { id: { _eq: $id} }) {
        id
        sex
        bmr
        weight
        weight_unit
        u_weight
        u_goal_weight
        u_current_weight
        goal
        goal_weight
        current_weight
        activity_level
        form_status
        current_period_start
        current_period_end
        v_type
        v_meals
        
        dinner_serves
        leftover_lunches
        cheat_meal
        dessert
        snack_morning
        snack_afternoon
        snack_evening
        cold_breakfast
        cold_lunch
        rotate_breakfast
        
        gluten
        wheat
        lactose
        milk
        egg
        nuts
        shellfish
        fish
        red_meat
        pork
        chicken
        turkey
        fruit
        vegetable
        grain
        herb_spice
        legume
        berry
        lamb
        preference_length
        preference_string
        
        alt_vol_in_recipes
        last_protein
        
        plan_type
        snack_morning_protein
        snack_afternoon_protein
        snack_evening_protein

        weights {
          date_recorded
          weight
        }

        plans(limit: 3, order_by: {created_at: desc}) {
          id
          start_date
          stop_date
          daily_cals
          starting_weight
          bmr
          target_fat
          target_carbs
          target_protein
          meals {
            id
            recipe_id
            next_meal_id
          }
          shopping_lists {
            id
            start_date
            stop_date
            list_items {
              id
              shopping_list_id
              name
              checked
              amount
              base_amount
              shopping_cat
              kind
              measurement
              gluten
            }
          }
        }
        recipe_preferences {
          id
          user_id
          recipe_id
          rating
        }
      }
    }`;

    client.query({
      variables: { id: parseInt(global.userNutritionId) },
      query
    }).then((result) => {
      global.nutritionData = result.data
      if(global.nutritionData.users[0].form_status !== global.userNutritionTrial && global.nutritionData.users[0].form_status !== null) {
        Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient).update({
          nutritionStatus: global.nutritionData.users[0].form_status
        })
      }
      this.configureData()
    }).catch((error) => {
      console.log(error);
    });
  }


  configureData() {
    if(global.nutritionData !== null) {
      var data = global.nutritionData.users[0]
      var height = global.coupon
      if(global.userLbs && global.coupon !== 0) {
        var h = global.coupon/2.54
        var h1 = parseInt(h/12)
        var h2 = (h-(h1*12)).toFixed(0)
        height = h1+"."+h2
      }
      var wgt1 = data.goal_weight
      var wgt2 = data.current_weight
      if(global.userLbs) {
        wgt1 = (data.goal_weight*global.lbsUp).toFixed(1)
        wgt2 = (data.current_weight*global.lbsUp).toFixed(1)
      }
      this.setState({
        weight: wgt1,
        weight2: wgt2,
        lowCarb: data.plan_type,
        portions: data.dinner_serves,
        vtype: parseInt(data.v_type),
        vmeals: parseInt(data.v_meals),
        cheat: parseInt(data.cheat_meal),
        leftovers: data.leftover_lunches,
        dessert: data.dessert,
        snack: data.snack_evening,
        gluten: data.gluten,
        proteins: [data.red_meat, data.pork, data.chicken, data.turkey, data.fish, data.shellfish],
        ingredients: [data.lactose, data.nuts, data.egg],
        psnacks: [data.snack_morning_protein, data.snack_afternoon_protein, data.snack_evening_protein],
        breakfast: data.cold_breakfast,
        details: [height, data.sex, global.userBday, data.activity_level],
      })
    }
  }


  toggleProteins(num) {
    var tmp = this.state.proteins
    tmp[num] = !tmp[num]
    this.setState({
      proteins: tmp
    })
  }


  toggleIngredients(num) {
    var tmp = this.state.ingredients
    tmp[num] = !tmp[num]
    this.setState({
      ingredients: tmp
    })
  }


  toggleSnacks(num) {
    var tmp = this.state.psnacks
    tmp[num] = !tmp[num]
    this.setState({
      psnack: tmp
    })
  }


  toggleCarb() {
    var tmp = this.state.lowCarb
    if(tmp === 0) {
      this.setState({
        lowCarb: 1,
        vtype: 0,
      })
      if(this.state.vtype > 1) {
        this.showOverlayCarb(0)
      }
    } else {
      this.setState({
        lowCarb: 0
      })
    }
  }


  selectVegetarian(event) {
    this.setState({
      vtype: parseInt(event.target.value)
    })
    if(parseInt(event.target.value) > 1 && this.state.lowCarb === 1) {
      this.setState({
        lowCarb: 0
      })
      this.showOverlayCarb(1)
    }
    if(parseInt(event.target.value) === 2) {
      this.setState({
        proteins: [true, true, true, true, true, true]
      })
    }
    if(parseInt(event.target.value) === 3) {
      var tmp = this.state.ingredients
      tmp[0] = true
      tmp[2] = true
      this.setState({
        proteins: [true, true, true, true, true, true],
        ingredients: tmp
      })
    }
  }


  toggleDetails(num, value) {
    var tmp = this.state.details
    tmp[num] = value
    this.setState({
      details: tmp
    })
  }


  onChange = event => {
    if(event.target.name === 'weight') {
      this.setState({
        weight: event.target.value
      })
    }
    if(event.target.name === 'weight2') {
      this.setState({
        weight2: event.target.value
      })
    }
    if(event.target.name === 'portions') {
      this.setState({
        portions: event.target.value
      })
    }
    if(event.target.name === 'veg') {
      this.setState({
        vmeals: event.target.value
      })
    }
    if(event.target.name === 'height') {
      var tmp = this.state.details
      tmp[0] = event.target.value
      this.setState({
        details: tmp
      })
    }
  };


  onChangeDate = event => {
    var tmp = this.state.details
    tmp[3] = Moment(event.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY')
    this.setState({details: tmp});
  };


  checkUpdate() {

    var passed = true
    var tmp = [false, false, false, false, false, false, false]
    this.setState({
      error: [false, false, false, false, false, false, false]
    })
    if(this.state.weight === 0 || this.state.weight === '' || this.state.weight === undefined) {
      tmp[0] = true
      passed = false
    }
    if(this.state.portions === 0 || this.state.portions === '' || this.state.portions === undefined) {
      tmp[1] = true
      passed = false
    }
    if(this.state.details[0] === 0 || this.state.details[0] === '' || this.state.details[0] === undefined) {
      tmp[2] = true
      passed = false
    }
    if(this.state.details[1] !== 'm' && this.state.details[1] !== 'f' && this.state.details[1] !== 'o') {
      tmp[3] = true
      passed = false
    }
    if(this.state.details[3] === '' || this.state.details[3] === undefined) {
      tmp[4] = true
      passed = false
    }
    if(global.userNutritionTrial !== "active" && (this.state.weight === 0 || this.state.weight === '' || this.state.weight === undefined)) {
      tmp[5] = true
      passed = false
    }
    if(this.state.details[2] === '' || this.state.details[2] === undefined) {
      tmp[6] = true
      passed = false
    }
    if(passed) {
      this.setState({
        loading: true
      })
      if(global.userNutritionTrial === 'active' || global.userNutritionTrial === 'finished') {
        this.updatePreferences()
      } else {
        this.updatePreferences()
        this.createLog()
      }
    } else {
      this.setState({
        error: tmp
      })
    }
  }


  createLog() {
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation createWeight($id: Int!, $date: date, $time: timestamp!, $weight: float8) {
        insert_weights_one(object: {created_at: $time, date_recorded: $date, updated_at: $time, u_weight: $weight, user_id: $id, weight: $weight}) {
          id
        }
      }
    `;

    var wgt2 = this.state.weight2
    if(global.userLbs) {
      wgt2 = this.state.weight2*global.lbsDown
    }

    client.mutate({
      variables: { id: parseInt(global.userNutritionId), date: Moment().format('DD MMMM YYYY'), time: Moment().format('YYYY-MM-DDTHH:mm:ssZ'), weight: parseFloat(wgt2) },
      mutation
    }).then((result) => {
      Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient+'/assessments').push({
        weight: parseFloat(wgt2),
        fat: 0,
        notes: '',
        neck: 0,
        chest: 0,
        abdomen: 0,
        hip: 0,
        armR: 0,
        armL: 0,
        thighR: 0,
        thighL: 0,
        date: Moment().format('DD/MM/YYYY HH:mm'),
        image: '',
        image2: '',
        image3: '',
        image4: '',
        uid: global.uid,
        heart: 0,
        nutrition: result.data.insertWeightsOne.id.toString(),
    })
    }).catch((error) => {
      console.log(error);
    });
  }


  updatePreferences() {
    const client = Connector.createApolloClient()
    const mutation = gql`
    mutation updatePreferences($id: Int!, $sex: String!, $height: Int!, $birth: date, $gluten: Boolean!, $dinner: Int!, $goal: float8, $current: float8, $leftover: Boolean!, $cheat: Int!, $dessert: Boolean, $snack: Boolean!, $vegetarian: Int!, $cold: Boolean!, $meat: Boolean!, $pork: Boolean!, $chicken: Boolean!, $turkey: Boolean!, $fish: Boolean!, $shellfish: Boolean!, $lactose: Boolean!, $nuts: Boolean!, $eggs: Boolean!, $activity: Int!, $status: String!, $vegmeals: Int!, $weight: float8, $plantype: Int!, $protein1: Boolean!, $protein2: Boolean!, $protein3: Boolean!) {
      update_users_by_pk(pk_columns: {id: $id},
        _set: {
          sex: $sex,
          height: $height,
          date_of_birth: $birth,
          gluten: $gluten,
          dinner_serves: $dinner,
          goal_weight: $goal,
          u_goal_weight: $goal,
          current_weight: $current,
          u_current_weight: $current,
          weight: $weight,
          u_weight: $weight,
          leftover_lunches: $leftover,
          cheat_meal: $cheat,
          dessert: $dessert,
          snack_evening: $snack,
          snack_morning: true,
          snack_afternoon: true,
          weight_unit: "kg",
          alt_vol_in_recipes: false,
          v_type: $vegetarian,
          v_meals: $vegmeals,
          cold_breakfast: $cold,
          
          red_meat: $meat,
          pork: $pork,
          chicken: $chicken,
          turkey: $turkey,
          fish: $fish,
          shellfish: $shellfish,
          lactose: $lactose,
          nuts: $nuts,
          egg: $eggs,
          
          activity_level: $activity,
          form_status: $status,
          
          plan_type: $plantype,
          snack_morning_protein: $protein1,
          snack_afternoon_protein: $protein2,
          snack_evening_protein: $protein3,
        })
      {
        id
        gluten
        dinner_serves
      }
    }
    `;

    var wgt1 = this.state.weight
    var wgt2 = this.state.weight2
    if(global.userLbs) {
      wgt1 = this.state.weight*global.lbsDown
      wgt2 = this.state.weight2*global.lbsDown
    }

    var cw = parseFloat(global.nutritionData.users[0].current_weight)
    var wg = parseFloat(global.nutritionData.users[0].weight)
    if(global.userNutritionTrial !== 'active' && global.userNutritionTrial !== 'finished') {
      cw = parseFloat(wgt2)
      wg = parseFloat(wgt2)
    }

    if(this.state.details[0] !== global.userCoupon || this.state.details[2] !== global.userBday) {
      
      var height = parseInt(this.state.details[0])
      if(global.userLbs) {
        var ar = this.state.details[0].split(".")
        var h1 = parseInt((ar[0])*12)*2.54
        var h2 = 0
        if(ar.length > 1) {
          h2 = parseInt(ar[1])*2.54
        }
        height = parseInt(h1+h2)
      }

      Firebase.database().ref('/usersClients/'+global.uidUser).update({
        height: height,
        birth: Moment(this.state.details[2]).format('DD/MM/YYYY')
      })
      Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClients).update({
        height: height,
        birth: Moment(this.state.details[2]).format('DD/MM/YYYY')
      })
    }
    client.mutate({
      variables: { id: parseInt(global.userNutritionId), sex: this.state.details[1].toString(), height: parseInt(height), birth: Moment(this.state.details[2], 'DD/MM/YYYY').format('YYYY-MM-DD'), gluten: this.state.gluten, dinner: this.state.portions, goal: parseFloat(wgt1), current: cw, leftover: this.state.leftovers, cheat: parseInt(this.state.cheat), dessert: this.state.dessert, snack: this.state.snack, vegetarian: parseInt(this.state.vtype), cold: this.state.breakfast, meat: this.state.proteins[0], pork: this.state.proteins[1], chicken: this.state.proteins[2], turkey: this.state.proteins[3], fish: this.state.proteins[4], shellfish: this.state.proteins[5], lactose: this.state.ingredients[0], nuts: this.state.ingredients[1], eggs: this.state.ingredients[2], activity: parseInt(this.state.details[3]), status: global.userNutritionTrial, vegmeals: parseInt(this.state.vmeals), weight: wg, plantype: this.state.lowCarb, protein1: this.state.psnacks[0], protein2: this.state.psnacks[1], protein3: this.state.psnacks[2] },
      mutation
    }).then((result) => {
      if(Moment(global.userNutritionEnd, 'X') > Moment()) {
        this.createPlan()
      } else {
        global.nutritionData = null
        global.nutritionWeeks = null
        global.message = lang.t('message:preferences')
        this.props.history.push('/'+global.uid+'/health/nutrition/')
      }
      
    }).catch((error) => {
      console.log(error);
    });
  }


  createPlan() {
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation createPlan($id: Int!) {
        createPlan(userId: $id) {
          response
        }
      }
    `;

    client.mutate({
      variables: { id: parseInt(global.userNutritionId) },
      mutation
    }).then((result) => {
      if(global.userNutritionTrial !== 'active' && global.userNutritionTrial !== 'finished') {
        this.setState({
          new: true
        })
        setTimeout(() => {
          this.checkStatus()
        }, 4000);
      } else {
        this.setState({
          loading: false,
        })
        this.showOverlayInfo()
      }
    }).catch((error) => {
      console.log(error);
    });
  }


  checkStatus() {
    global.userNutritionTrial = 'created'
    const client = Connector.createApolloClient()
    const query = gql`
      query getStatus($id: Int!) {
        users_by_pk(id: $id) {
          form_status
        }
      }
    `;

    client.mutate({
      variables: { id: parseInt(global.userNutritionId) },
      query
    }).then((result) => {
      if(result.data.form_status === 'active') {
        this.setState({
          loading: false
        })
        global.userNutritionTrial = result.data.form_status
        Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient).update({
          nutritionStatus: result.data.form_status
        })
        global.nutritionData = null
        global.nutritionWeeks = null
        global.message = lang.t('message:preferencesset')
        this.props.history.push('/'+global.uid+'/health/nutrition/')
      } else {
        setTimeout(() => {
          this.checkStatus()
        }, 4000);
      }
    }).catch((error) => {
      console.log(error);
    });
  }



  // Card overlay ------------------------------------------------------------



  showOverlayInfo(type) {
    this.setState({
      showOverlayInfo: true,
      otype: type
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenInfo: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayInfo() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenInfo: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayInfo: false});
      global.nutritionData = null
      global.nutritionWeeks = null
      global.message = lang.t('message:preferences')
      this.props.history.push('/'+global.uid+'/health/nutrition/')
    }, 500);
  }


  renderOverlayInfo() {
    if(this.state.showOverlayInfo) {
      var title = lang.t('common:pref.title1')
      var desc = lang.t('common:pref.text1')
      if(this.state.new) {
        title = lang.t('common:pref.title2')
        desc = lang.t('common:pref.text2')
      }
      return (
        <div className={'overlay '+this.state.hiddenInfo}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{title}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayInfo()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-30">{desc}</p>
            <button className="btn primary" onClick={() => this.hideOverlayInfo()}>{lang.t('common:button.gotit')}</button>
          </div>
        </div>
      )
    }
  }



  // Card overlay ------------------------------------------------------------



  showOverlayCarb(type) {
    this.setState({
      showOverlayCarb: true,
      otype: type
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenCarb: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayCarb() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenCarb: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayCarb: false});
    }, 500);
  }


  renderOverlayCarb() {
    if(this.state.showOverlayCarb) {
      return (
        <div className={'overlay '+this.state.hiddenCarb}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:carb.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayCarb()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-30">{this.state.otype === 1 ? lang.t('modal:carb.text1') : lang.t('modal:carb.text2')}</p>
            <button className="btn primary" onClick={() => this.hideOverlayCarb()}>{lang.t('common:button.gotit')}</button>
          </div>
        </div>
      )
    }
  }



  // Display stuff ------------------------------------------------------------



  renderError(num) {
    var message = [lang.t('health:pref.error1'), lang.t('health:pref.error2'), lang.t('health:pref.error3'), lang.t('health:pref.error4'), lang.t('health:pref.error4'), lang.t('health:pref.error5'), lang.t('health:pref.error6')]
    if(this.state.error[num]) {
      return (
        <div className="form">
          <p className="error">{message[num]}</p>
        </div>
      )
    }
  }


  getPlanInfo() {
    if(global.userNutritionId !== '' && global.userNutritionId !== undefined) {
      if(global.userNutritionTrial !== 'active') {
        return (
          <div className="infobox">
            <h5>{lang.t('health:pref.note1')}</h5>
            <p>{lang.t('health:pref.note2a')} {Moment(global.userNutritionStart, 'X').locale(lang.language).format('D MMMM')} {lang.t('health:pref.note2b')} {Moment(global.userNutritionStart, 'X').add(1, 'days').locale(lang.language).format('D MMMM')}.</p>
          </div>
        )
      } else {
        return (
          <div className="infobox">
            <h5>{lang.t('health:pref.note1')}</h5>
            <p>{lang.t('health:pref.note3')}</p>
          </div>
        )
      }
    }
  }


  renderVeg() {
    if(this.state.vtype === 1) {
      return (
        <div className="mt-20">
          <label>{lang.t('health:pref.vegdinners')}</label>
          <input type="number" value={this.state.vmeals} name='veg' onChange={this.onChange}/>
        </div>
      )
    }
  }


  renderWeight() {
    if(global.userNutritionTrial !== 'active') {
      return (
        <div>
          <div className="sv-20"></div>
          <label>{lang.t('health:pref.currentweight')} kg</label>
          <input type="number" value={this.state.weight2} name='weight2' onChange={this.onChange}/>
          {this.renderError(5)}
        </div>
      )
    }
  }


  renderProtein() {
    if(this.state.vtype < 2) {
      return (
        <div>
          <div className="clear sv-30"></div>
          <label>{lang.t('health:pref.proteins')}</label>
          <div className={this.state.proteins[0] ? 'toggle active' : 'toggle'} onClick={() => this.toggleProteins(0)}>
            <div className="mover"></div>
          </div>
          <p className="toggle-label">{lang.t('health:pref.redmeat')}</p>
          <div className="clear sv-10"></div>
          <div className={this.state.proteins[1] ? 'toggle active' : 'toggle'} onClick={() => this.toggleProteins(1)}>
            <div className="mover"></div>
          </div>
          <p className="toggle-label">{lang.t('health:pref.pork')}</p>
          <div className="clear sv-10"></div>
          <div className={this.state.proteins[2] ? 'toggle active' : 'toggle'} onClick={() => this.toggleProteins(2)}>
            <div className="mover"></div>
          </div>
          <p className="toggle-label">{lang.t('health:pref.chicken')}</p>
          <div className="clear sv-10"></div>
          <div className={this.state.proteins[3] ? 'toggle active' : 'toggle'} onClick={() => this.toggleProteins(3)}>
            <div className="mover"></div>
          </div>
          <p className="toggle-label">{lang.t('health:pref.turkey')}</p>
          <div className="clear sv-10"></div>
          <div className={this.state.proteins[4] ? 'toggle active' : 'toggle'} onClick={() => this.toggleProteins(4)}>
            <div className="mover"></div>
          </div>
          <p className="toggle-label">{lang.t('health:pref.fish')}</p>
          <div className="clear sv-10"></div>
          <div className={this.state.proteins[5] ? 'toggle active' : 'toggle'} onClick={() => this.toggleProteins(5)}>
            <div className="mover"></div>
          </div>
          <p className="toggle-label">{lang.t('health:pref.shellfish')}</p>
        </div>
      )
    }
  }


  renderLoading() {
    if(this.state.loading) {
      return (
        <div className="loading">
          <div className="loader-box">
            <span className="loader"><span className="loader-inner"></span></span>
          </div>
          <p>Setting you up<br/>Please wait</p>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-sidebar">
          <div className="col-6 mt-20">
            <div className="box">
              <div className="form">
                <label>{lang.t('health:pref.goalweight')} {global.userLbs ? 'lb' : 'kg'}</label>
                <input type="number" placeholder={'Weight in '+(global.userLbs ? 'lb' : 'kg')} value={this.state.weight} name='weight' onChange={this.onChange}/>
                {this.renderError(0)}
                <div className="clear sv-30"></div>
                <h4>{lang.t('health:pref.dietary')}</h4>
                <div className="clear sv-10"></div>
                <label>{lang.t('health:pref.veg.label')}</label>
                <select value={this.state.vtype} onChange={event => this.selectVegetarian(event)}>
                  <option value={0}>{lang.t('health:pref.no')}</option>
                  <option value={1}>{lang.t('health:pref.veg.sometimes')}</option>
                  <option value={2}>{lang.t('health:pref.veg.vegetarian')}</option>
                  <option value={3}>{lang.t('health:pref.veg.vegan')}</option>
                </select>
                {this.renderVeg()}
                <div className="sv-20"></div>
                <label>{lang.t('health:pref.lowcarb.title')}</label>
                <div className={this.state.lowCarb === 1 ? 'toggle active' : 'toggle'} onClick={() => this.toggleCarb()}>
                  <div className="mover"></div>
                </div>
                <p className="toggle-label">{lang.t('health:pref.lowcarb.text')}</p>
                <div className="clear sv-20"></div>
                <label>{lang.t('health:pref.people')}</label>
                <input type="number" placeholder="Number of people" value={this.state.portions} name='portions' onChange={this.onChange}/>
                {this.renderError(1)}
              </div>
              <div className="sv-20"></div>
              <label>{lang.t('health:pref.cheat')}</label>
              <div className="radios">
                <button className={this.state.cheat === 0 ? 'radio active' : 'radio'} style={{width: '12.5%'}} onClick={() => this.setState({cheat: 0})}>{lang.t('health:pref.no')}</button>
                <button className={this.state.cheat === 2 ? 'radio active' : 'radio'} style={{width: '12.5%'}} onClick={() => this.setState({cheat: 2})}>{lang.t('calendar:label.mon')}</button>
                <button className={this.state.cheat === 3 ? 'radio active' : 'radio'} style={{width: '12.5%'}} onClick={() => this.setState({cheat: 3})}>{lang.t('calendar:label.tue')}</button>
                <button className={this.state.cheat === 4 ? 'radio active' : 'radio'} style={{width: '12.5%'}} onClick={() => this.setState({cheat: 4})}>{lang.t('calendar:label.wed')}</button>
                <button className={this.state.cheat === 5 ? 'radio active' : 'radio'} style={{width: '12.5%'}} onClick={() => this.setState({cheat: 5})}>{lang.t('calendar:label.thu')}</button>
                <button className={this.state.cheat === 6 ? 'radio active' : 'radio'} style={{width: '12.5%'}} onClick={() => this.setState({cheat: 6})}>{lang.t('calendar:label.fri')}</button>
                <button className={this.state.cheat === 7 ? 'radio active' : 'radio'} style={{width: '12.5%'}} onClick={() => this.setState({cheat: 7})}>{lang.t('calendar:label.sat')}</button>
                <button className={this.state.cheat === 1 ? 'radio active' : 'radio'} style={{width: '12.5%'}} onClick={() => this.setState({cheat: 1})}>{lang.t('calendar:label.sun')}</button>
              </div>
              <div className="sv-20"></div>
              <label>{lang.t('health:pref.leftover.title')}</label>
              <div className={this.state.leftovers ? 'toggle active' : 'toggle'} onClick={() => this.setState({leftovers: !this.state.leftovers})}>
                <div className="mover"></div>
              </div>
              <p className="toggle-label">{lang.t('health:pref.leftover.text')}</p>

              <div className="clear sv-30"></div>
              <label>{lang.t('health:pref.dessert.title')}</label>
              <div className={this.state.dessert ? 'toggle active' : 'toggle'} onClick={() => this.setState({dessert: !this.state.dessert})}>
                <div className="mover"></div>
              </div>
              <p className="toggle-label">{lang.t('health:pref.dessert.text')}</p>

              <div className="clear sv-30"></div>
              <label>{lang.t('health:pref.evening.title')}</label>
              <div className={this.state.snack ? 'toggle active' : 'toggle'} onClick={() => this.setState({snack: !this.state.snack})}>
                <div className="mover"></div>
              </div>
              <p className="toggle-label">{lang.t('health:pref.evening.text')}</p>

              <div className="clear sv-30"></div>
              <label>{lang.t('health:pref.gluten.title')}</label>
              <div className={this.state.gluten ? 'toggle active' : 'toggle'} onClick={() => this.setState({gluten: !this.state.gluten})}>
                <div className="mover"></div>
              </div>
              <p className="toggle-label">{lang.t('health:pref.gluten.text')}</p>

              {this.renderProtein()}

              <div className="clear sv-30"></div>
              <label>{lang.t('health:pref.avoid')}</label>
              <div className={this.state.ingredients[0] ? 'toggle active' : 'toggle'} onClick={() => this.toggleIngredients(0)}>
                <div className="mover"></div>
              </div>
              <p className="toggle-label">{lang.t('health:pref.dairy')}</p>
              <div className="clear sv-10"></div>
              <div className={this.state.ingredients[1] ? 'toggle active' : 'toggle'} onClick={() => this.toggleIngredients(1)}>
                <div className="mover"></div>
              </div>
              <p className="toggle-label">{lang.t('health:pref.nuts')}</p>
              <div className="clear sv-10"></div>
              <div className={this.state.ingredients[2] ? 'toggle active' : 'toggle'} onClick={() => this.toggleIngredients(2)}>
                <div className="mover"></div>
              </div>
              <p className="toggle-label">{lang.t('health:pref.eggs')}</p>

              <div className="clear sv-30"></div>
              <label>{lang.t('health:pref.snacks')}</label>
              <div className={this.state.psnacks[0] ? 'toggle active' : 'toggle'} onClick={() => this.toggleSnacks(0)}>
                <div className="mover"></div>
              </div>
              <p className="toggle-label">{lang.t('health:pref.morning')}</p>
              <div className="clear sv-10"></div>
              <div className={this.state.psnacks[1] ? 'toggle active' : 'toggle'} onClick={() => this.toggleSnacks(1)}>
                <div className="mover"></div>
              </div>
              <p className="toggle-label">{lang.t('health:pref.afternoon')}</p>
              <div className="clear sv-10"></div>
              <div className={this.state.psnacks[2] ? 'toggle active' : 'toggle'} onClick={() => this.toggleSnacks(2)}>
                <div className="mover"></div>
              </div>
              <p className="toggle-label">{lang.t('health:pref.evening')}</p>

              <div className="clear sv-30"></div>
              <label>{lang.t('health:pref.breakfast')}</label>
              <div className={this.state.breakfast ? 'toggle active' : 'toggle'} onClick={() => this.setState({breakfast: !this.state.breakfast})}>
                <div className="mover"></div>
              </div>
              <p className="toggle-label">{lang.t('health:pref.cold')}</p>

              <div className="clear sv-30"></div>
              <h4>{lang.t('health:pref.details')}</h4>
              <div className="clear sv-30"></div>
              <label>{lang.t('health:pref.height')} {global.userLbs ? 'ft' : 'cm'}</label>
              <input type="number" placeholder={'Height in '+(global.userLbs ? 'ft' : 'cm')} value={this.state.details[0]} name='height' onChange={this.onChange}/>
              {this.renderError(2)}

              <div className="sv-20"></div>
              <label>{lang.t('health:pref.gender')}</label>
              <div className="radios">
                <button className={this.state.details[1] === 'm' ? 'radio active col-4' : 'radio col-4'} onClick={() => this.toggleDetails(1, 'm')}>{lang.t('health:pref.male')}</button>
                <button className={this.state.details[1] === 'f' ? 'radio active col-4' : 'radio col-4'} onClick={() => this.toggleDetails(1, 'f')}>{lang.t('health:pref.female')}</button>
                <button className={this.state.details[1] === 'o' ? 'radio active col-4' : 'radio col-4'} onClick={() => this.toggleDetails(1, 'o')}>{lang.t('health:pref.other')}</button>
              </div>
              {this.renderError(3)}
              <div className="sv-20"></div>
              <label>{lang.t('health:pref.dob')}</label>
              <input type="date" value={Moment(this.state.details[2], 'DD/MM/YYYY').format('YYYY-MM-DD')} onChange={this.onChangeDate}/>
              {this.renderError(6)}
              <div className="sv-20"></div>
              <label>{lang.t('health:pref.training')}</label>
              <div className="radios">
                <button className={this.state.details[3] === 0 ? 'radio active' : 'radio'} style={{width: '20%'}} onClick={() => this.toggleDetails(3, 0)}>{lang.t('health:pref.none')}</button>
                <button className={this.state.details[3] === 1 ? 'radio active' : 'radio'} style={{width: '20%'}} onClick={() => this.toggleDetails(3, 1)}>1-3</button>
                <button className={this.state.details[3] === 2 ? 'radio active' : 'radio'} style={{width: '20%'}} onClick={() => this.toggleDetails(3, 2)}>3-5</button>
                <button className={this.state.details[3] === 3 ? 'radio active' : 'radio'} style={{width: '20%'}} onClick={() => this.toggleDetails(3, 3)}>5-7</button>
                <button className={this.state.details[3] === 4 ? 'radio active' : 'radio'} style={{width: '20%'}} onClick={() => this.toggleDetails(3, 4)}>7+</button>
              </div>
              {this.renderError(4)}
              {this.renderWeight()}
              <div className="sv-40"></div>
              <button className="btn primary" onClick={() => this.checkUpdate()}>{global.userNutritionTrial === 'active' ? lang.t('health:button.updatepreferences') : lang.t('health:button.setpreferences')}</button>
            </div>
          </div>

          <div className="col-6 mt-20 sidebar">
            <div className="image empty large mb-30">
              <div className="illustration">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmptyImg.renderImage('nutrition')}
              </div>
            </div>
            <p style={{marginBottom: 30}}>{lang.t('health:pref.sidebar')}</p>
            {this.getPlanInfo()}
          </div>
          <div className="clear sv-40"></div>
        </div>

        <div className="header">
          <div className="content">
            <Link to={'/'+global.uid+'/health/nutrition'} className="back lft mr-10">
              <div className="circle"></div>
              <div className="arrow"></div>
            </Link>
            <h2>{lang.t('header:title.preferences')}</h2>
          </div>
        </div>
        <Navigation active='health' />
        {this.renderOverlayCarb()}
        {this.renderOverlayInfo()}
        {this.renderLoading()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;

export default withTranslation(['header','health','common','modal','calendar','message'])(withRouter(withAuthorizationMember(condition)(MemberPreferencesPage)));

