import React, { Component } from 'react';
import EventEmitter from '../../helper/Emitter';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: global.locations,
      show: false,
      hidden: 'hidden none'
    };
  }

  componentDidMount() {
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationsLoaded', (event) => this.configureData())
    this.configureData()
  }


  configureData() {
    this.setState({
      locations: global.locations
    })
  }


  toggleSidebar() {
    var tmp = this.state.show
    if(!tmp) {
      this.setState({
        show: true,
        hidden: 'hidden'
      })
      setTimeout(() => {
        this.setState({
          hidden: '',
        });
      }, 100);
    } else {
      this.setState({
        show: false,
        hidden: 'hidden'
      })
      setTimeout(() => {
        this.setState({
          hidden: 'hidden none',
        });
      }, 300);
    }
  }


  setLocation(item) {
    global.loc = item
    this.toggleSidebar()
    EventEmitter.dispatch('locationSet', 'updated');
  }


  getLocation() {
    var label = lang.t('nav:location.all')
    for(var item of global.locations) {
      if(item.id === global.loc) {
        label = item.data.name
      }
    }
    if(global.loc === 'notset') {
      label = lang.t('nav:location.notset')
    }
    return label
  }



  // Display stuff ------------------------------------------------------------



  renderAllLocations() {
    if(global.locations.length === global.spaceLocations.length) {
      return (
        <div className={'list bare '+(global.loc === '' ? 'active' : '')} onClick={() => this.setLocation('')}>
          <div className={'icon '+(global.loc === '' ? 'quarternary' : 'grey')}>
            <div className="inner location"></div>
          </div>
          <div className="main">
            <h4>{lang.t('nav:location.all')}</h4>
            <p>{lang.t('nav:location.click')}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }

  
  renderList() {
    var list = this.state.locations
    list.sort((a,b) => a.data.name.localeCompare(b.data.name))
    return (
      <div className={'location-list '+this.state.hidden}>
        {this.renderAllLocations()}
        {list.map(item => (
          <div className={'list bare '+(global.loc === item.id ? 'active' : '')} onClick={() => this.setLocation(item.id)} key={item.id}>
            <div className={'icon '+(global.loc === item.id ? 'quarternary' : 'grey')}>
              <div className="inner location"></div>
            </div>
            <div className="main">
              <h4>{item.data.name}</h4>
              <p>{lang.t('nav:location.click')}</p>
            </div>
            <div className="clear"></div>
          </div>
        ))}
      </div>
    )
  }


  render() {
    if(global.spaceLocations.length > 0) {
      return (
        <div className="location-info">
          <div className="location-inner" onClick={() => this.toggleSidebar()}>{this.getLocation()}</div>
          {this.renderList()}
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}


export default withTranslation(['nav'])(Location);