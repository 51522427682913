import Moment from 'moment';
import React from 'react';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class Cal {


  // Stats amount

  getAmount(sessions, archive, type, range) {
    var minDate = Moment(this.getDateRange(), 'X')
    var count = 0
    for(var item of sessions) {
      if((!item.data.group && type === 'sessions') || (item.data.group && type === 'group')) {
        if(range === '7days' || range === '30days' || range === '60days') {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > minDate && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
            count++
          }
        } else if(range === 'month') {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
            count++
          }
        } else if(range === 'week') {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(1, 'days').startOf('day')) {
            count++
          }
        } else {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') < Moment().format('X')) {
            count++
          }
        }
      }
    }
    return count
  }



  // Stats difference badges

  getDifference(sessions, group, range, type) {
    var arr = ['', '+0']
    var today = Moment().format('X')
    var minDate = parseInt(this.getDateRangeDiff()[0])
    var minDate2 = parseInt(this.getDateRangeDiff()[1])
    var prevDate = parseInt(this.getDateRangeDiff()[2])

    var count1 = 0
    var count2 = 0
    for(var item of sessions) {
      var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
      if(item.data.timestamp !== undefined) {
        idate = Moment(item.data.timestamp, 'X')
      }
      if(item.data.group === group) {
        var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
        if(range === '7days' || range === '30days' || range === '60days') {
          if(idate > minDate && idate < today) {
            count1++
          }
          if(idate > prevDate && idate < minDate2) {
            count2++
          }
        } else if(range === 'month') {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
            count1++
          }
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().add(-1, 'month').format('MM/YYYY')) {
            count2++
          }
        } else if(range === 'week') {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(1, 'days').startOf('day')) {
            count1++
          }
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week').add(-7, 'days') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().startOf('week')) {
            count2++
          }
        }
      }
    }

    arr[1] = String(count1-count2)
    if(count2 < count1) {
      arr[0] = 'green'
      arr[1] = '+'+arr[1]
    } else if(count2 > count1) {
      arr[0] = 'red'
    }

    if(type === 'badge') {
      if(range !== '60days') {
        return (
          <div className={'badge '+arr[0]} title='Change compared to previous period'>{arr[1]}</div>
        )
      } else {
        return (
          <div className={'badge'} title='Change compared to previous period'>N/A</div>
        )
      }
    } else {
      return count2
    }
    
  }



  // Stats difference badges

  getDifferenceTotal(sessions, range, type) {
    var arr = ['', '+0', '0']
    var today = Moment().format('X')
    var minDate = parseInt(this.getDateRangeDiff()[0])
    var minDate2 = parseInt(this.getDateRangeDiff()[1])
    var prevDate = parseInt(this.getDateRangeDiff()[2])

    var count1 = 0
    var count2 = 0
    for(var item of sessions) {
      var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
      if(item.data.timestamp !== undefined) {
        idate = Moment(item.data.timestamp, 'X')
      }
      if(range === '7days' || range === '30days' || range === '60days') {
        if(idate > minDate && idate < today) {
          count1++
        }
        if(idate > prevDate && idate < minDate2) {
          count2++
        }
      } else if(range === 'month') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          count1++
        }
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().add(-1, 'month').format('MM/YYYY')) {
          count2++
        }
      } else if(range === 'week') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(1, 'days').startOf('day')) {
          count1++
        }
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week').add(-7, 'days') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().startOf('week')) {
          count2++
        }
      }
    }

    arr[1] = String(count1-count2)
    if(count2 < count1) {
      arr[0] = 'green'
      arr[1] = '+'+arr[1]
    } else if(count2 > count1) {
      arr[0] = 'red'
    }
    arr[2] = count2

    if(type === 'badge') {
      if(range !== '60days') {
        return (
          <div className={'badge '+arr[0]} title='Change compared to previous period'>{arr[1]}</div>
        )
      } else {
        return (
          <div className={'badge'} title='Change compared to previous period'>N/A</div>
        )
      }
    } else {
      return count2
    }
  }



  // Stats amount per client

  getAmountClient(sessions, archive, type, range, client) {
    var minDate = this.getDateRange()
    var count = 0
    for(var item of sessions) {
      var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
      if(item.data.timestamp !== undefined) {
        idate = Moment(item.data.timestamp, 'X')
      }
      if((!item.data.group && type === 'sessions') || (item.data.group && type === 'group')) {
        var isGroup = false
        if(item.data.clients !== undefined && type === 'group') {
          if(item.data.clients.indexOf(client) !== -1) { isGroup = true }
        }
        if(range === '7days' || range === '30days' || range === '60days') {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') > minDate && Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') < Moment().format('X') && (item.data.client === client || item.data.uid === client || isGroup)) {
            count++
          }
        } else if(range === 'month') {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') < Moment().format('X') && (item.data.client === client || item.data.uid === client || isGroup)) {
            count++
          }
        } else if(range === 'week') {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(1, 'days').startOf('day') && (item.data.client === client || item.data.uid === client || isGroup)) {
            count++
          }
        } else {
          if((item.data.client === client || item.data.uid === client || isGroup) && Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') < Moment().format('X')) {
            count++
          }
        }
      }
    }
    return count
  }



  // Stats difference badges per client

  getDifferenceClient(sessions, group, range, client, type) {
    var arr = ['', '+0']
    var today = Moment().format('X')
    var minDate = parseInt(this.getDateRangeDiff()[0])
    var minDate2 = parseInt(this.getDateRangeDiff()[1])
    var prevDate = parseInt(this.getDateRangeDiff()[2])

    var count1 = 0
    var count2 = 0
    for(var item of sessions) {
      var isGroup = false
      var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
      if(item.data.timestamp !== undefined) {
        idate = Moment(item.data.timestamp, 'X')
      }
      if(item.data.clients !== undefined) {
        if(item.data.clients.indexOf(client) !== -1) { isGroup = true }
      }
      if(item.data.group === group) {
        var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
        if(range === '7days' || range === '30days' || range === '60days') {
          if(idate > minDate && idate < today && (item.data.client === client || isGroup)) {
            count1++
          }
          if(idate > prevDate && idate < minDate2 && (item.data.client === client || isGroup)) {
            count2++
          }
        } else if(range === 'month') {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment() && (item.data.client === client || isGroup)) {
            count1++
          }
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().add(-1, 'month').format('MM/YYYY') && (item.data.client === client || isGroup)) {
            count2++
          }
        } else if(range === 'week') {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(1, 'days').startOf('day') && (item.data.client === client || isGroup)) {
            count1++
          }
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week').add(-7, 'days') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().startOf('week') && (item.data.client === client || isGroup)) {
            count2++
          }
        }
      }
    }

    arr[1] = String(count1-count2)
    if(count2 < count1) {
      arr[0] = 'green'
      arr[1] = '+'+arr[1]
    } else if(count2 > count1) {
      arr[0] = 'red'
    }

    if(type === 'badge') {
      if(range !== '60days') {
        return (
          <div className={'badge '+arr[0]} title='Change compared to previous period'>{arr[1]}</div>
        )
      } else {
        return (
          <div className={'badge'} title='Change compared to previous period'>N/A</div>
        )
      }
    } else if(type === 'num') {
      return count2
    } else {
      if(count2 === count1) {
        arr[1] = '+0'
      }
      return (
        <span className={'change '+arr[0]}> {arr[1]}</span>
      )
    }
    
  }



  // Date range

  getDateRange() {
    var ds1 = Moment().add(-30, 'days').format('DD/MM/YYYY')
    var minDate = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    if(global.dateRange === '7days') {
      ds1 = Moment().add(-7, 'days').format('DD/MM/YYYY')
      minDate = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    } else if(global.dateRange === 'month') {
      ds1 = Moment().format('MM/YYYY')
      var ds2 = '01/'+ds1+'00:00'
      minDate = Moment(ds2, 'DD/MM/YYYY HH:mm').format('X')
    } else if(global.dateRange === 'week') {
      ds1 = Moment().startOf('week').format('DD/MM/YYYY')
      minDate = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    } else if(global.dateRange === '60days') {
      ds1 = Moment().add(-90, 'days').format('DD/MM/YYYY')
      minDate = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    }
    return minDate
  }



  // Date range

  getDateRangeDiff() {
    var ds1 = Moment().add(-30, 'days').format('DD/MM/YYYY')
    var ds2 = Moment().add(-31, 'days').format('DD/MM/YYYY')
    var dsp = Moment().add(-60, 'days').format('DD/MM/YYYY')
    var minDate1 = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    var minDate2 = Moment(ds2+' 23:59', 'DD/MM/YYYY HH:mm').format('X')
    var prevDate = Moment(dsp+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    if(global.dateRange === '7days') {
      ds1 = Moment().add(-7, 'days').format('DD/MM/YYYY')
      ds2 = Moment().add(-8, 'days').format('DD/MM/YYYY')
      dsp = Moment().add(-14, 'days').format('DD/MM/YYYY')
      minDate1 = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
      minDate2 = Moment(ds2+' 23:59', 'DD/MM/YYYY HH:mm').format('X')
      prevDate = Moment(dsp+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    } else if(global.dateRange === '60days') {
      ds1 = Moment().add(-60, 'days').format('DD/MM/YYYY')
      ds2 = Moment().add(-61, 'days').format('DD/MM/YYYY')
      dsp = Moment().add(-120, 'days').format('DD/MM/YYYY')
      minDate1 = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
      minDate2 = Moment(ds2+' 23:59', 'DD/MM/YYYY HH:mm').format('X')
      prevDate = Moment(dsp+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
    } else if(global.dateRange === 'month') {
      ds1 = Moment().format('MM/YYYY')
      ds2 = Moment().format('DD/MM/YYYY HH:mm')
      var ds11 = '01/'+ds1+'00:00'
      minDate1 = Moment(ds11+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
      minDate2 = Moment(ds2, 'DD/MM/YYYY HH:mm').format('X')
      prevDate = Moment(ds11+' 00:00', 'DD/MM/YYYY HH:mm').add(-1, 'months').format('X')
    } else if(global.dateRange === 'week') {
      ds1 = Moment().startOf('week').format('DD/MM/YYYY')
      ds2 = Moment().format('DD/MM/YYYY HH:mm')
      minDate1 = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').format('X')
      minDate2 = Moment(ds2, 'DD/MM/YYYY HH:mm').add(-7, 'days').format('X')
      prevDate = Moment(ds1+' 00:00', 'DD/MM/YYYY HH:mm').add(-7, 'days').format('X')
    } else if(global.dateRange === 'all') {
      minDate1 = Moment().add(-30, 'years').format('X')
    }
    return [minDate1, minDate2, prevDate]
  }



  // Session duration

  getDuration(num) {
    var label = num+' min'
    let hrs = parseInt(num/60)
    let min = num-hrs*60
    if(hrs === 0) {
      label = num+' min'
    }
    if(hrs !== 0 && min === 0) {
      label = hrs+' h'
    }
    if(hrs !== 0 && min !== 0) {
      label = hrs+' h '+min+' min'
    }
    return label
  }


  // Duration in minutes

  getDurationMin(num) {
    var label = num+' sec'
    if(lang.language === 'es') {
      label = num+' seg'
    }
    let hrs = parseInt(num/60)
    let min = num-hrs*60
    if(hrs === 0) {
      label = num+' sec'
      if(lang.language === 'es') {
        label = num+' seg'
      }
    }
    if(hrs !== 0 && min === 0) {
      label = hrs+' min'
    }
    if(hrs !== 0 && min !== 0) {
      label = hrs+' min '+min+' sec'
      if(lang.language === 'es') {
        label = hrs+' min '+min+' seg'
      }
    }
    return label
  }


  // Duration in minutes short

  getDurationMinAlt(num) {
    var label = ''
    let hrs = parseInt(num/60)
    let min = num-hrs*60
    label = hrs+':'+min.toLocaleString('en-US', {minimumIntegerDigits: 2})+' min'
    return label
  }


  // Format date

  getSpecialDate(item, short) {
    var dt = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
    var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
    if(item.data.timestamp !== undefined) {
      dt = Moment(item.data.timestamp, 'X').format('DD/MM/YYYY')
      date = Moment(item.data.timestamp, 'X').format('X')
    }
    var tdy = Moment().format('DD/MM/YYYY')
    var tmr = Moment().add(1, 'day').format('DD/MM/YYYY')
    var ytd = Moment().add(-1, 'day').format('DD/MM/YYYY')
    if(dt === tdy) {
      if(lang.language === 'es') {
        return 'Hoy '+Moment(date, 'X').format('LT')
      } else {
        return 'Today '+Moment(date, 'X').format('LT')
      }
    } else if(dt === tmr) {
      if(lang.language === 'es') {
        return 'Mañana '+Moment(date, 'X').format('LT')
      } else {
        return 'Tomorrow '+Moment(date, 'X').format('LT')
      }
    } else if(dt === ytd) {
      if(lang.language === 'es') {
        return 'Ayer '+Moment(date, 'X').format('LT')
      } else {
        return 'Yesterday '+Moment(date, 'X').format('LT')
      }
    } else {
      if(short) {
        return Moment(date, 'X').locale(lang.language).format('D MMM')+' - '+Moment(date, 'X').locale(lang.language).format('LT')
      } else {
        return Moment(date, 'X').locale(lang.language).format('ddd, D MMM YYYY')+' - '+Moment(date, 'X').locale(lang.language).format('LT')
      }
    }
  }


  // Session color

  getColor(dur) {
    var color = 'primary'
    if(dur > 30 && dur < 46) {
      color = 'secondary'
    } else if(dur > 45 && dur < 61) {
      color = 'tertiary'
    } else if(dur > 60 && dur < 76) {
      color = 'quarternary'
    } else if(dur > 75) {
      color = 'red'
    }
    if(dur === 0) {
      color = ''
    }
    return color
  }


  // Program time

  calcBlockTime(ex, type, rounds, emom, time, time2) {
    var num = 0
    if(type === 0 || type === 5) {
      num = rounds
    } else if(type === 1) {
      var work = parseInt(ex[0].wmin)*60+parseInt(ex[0].wsec)
      num = rounds*ex.length*work
      if(emom) {
        num = rounds*work
      }
    } else if(type === 2) {
      var tmp = 0
      if(emom) {
        tmp += time
        tmp += time2
      } else {
        for(let exx of ex) {
          var work2 = parseInt(exx.wmin)*60+parseInt(exx.wsec)
          var rest = parseInt(exx.rmin)*60+parseInt(exx.rsec)
          tmp += work2+rest
        }
      }
      num = rounds*tmp
    } else if(type === 3) {
      num = rounds*240
    } else if(type === 4) {
      for(var exr of ex) {
        if(exr.tool !== 6 && exr.tool !== 7 && exr.tool !== 9) {
          var reps = 0
          var test = String(exr.reps)
          if(exr.reps !== '') {
            reps = parseInt(exr.reps)
          }
          if(test.indexOf('-') !== -1) {
            reps = 0
            var ar = exr.reps.split('-')
            for(var a of ar) {
              if(a !== '') {
                reps += parseInt(a)
              }
            }
          }
          num += (reps*3)*rounds
          if(reps === 0 || reps === undefined) {
            num += 60*rounds
          }
        } else {
          if(reps === 0 || reps === undefined) {
            num += 60*rounds
          } else {
            num += (reps/10)*rounds
          }
        }
      }
      if(time !== 0) {
        num += time*rounds
      }
      //num = 1
    }
    return parseInt(num)
  }


  // Date for push notification

  getPushDate(date) {
    var label = 'on '+Moment(date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('llll')
    var d1 = Moment().format('DD/MM/YYYY')
    var d2 = Moment().add(1, 'days').format('DD/MM/YYYY')
    var ds = Moment(date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
    if(ds === d1) {
      label = 'today '+Moment(date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
      if(lang.language === 'es') {
        label = 'hoy '+Moment(date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
      }
    }
    if(ds === d2) {
      label = 'tomorrow '+Moment(date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
      if(lang.language === 'es') {
        label = 'mañana '+Moment(date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('LT')
      }
    }
    return label
  }

}


const b = new Cal();
export default b;