import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Firebase from 'firebase';
import { withRouter } from 'react-router-dom';
import HelperEmptyImg from '../../helper/EmptyLarge';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberThankyouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ''
    }
  }


  componentDidMount() {
    Connector.setUser()
    document.title = lang.t('header:title.welcomepage')+' - PT Mate'
    window.scrollTo(0, 0)
    this.configureData()
  }
  

  configureData() {
    setTimeout(() => {
      this.logoutUser()
    }, 10000);
  }


  logoutUser() {
    global.uidUser = "";
    global.userName = '';
    global.userBusiness = '';
    global.userEmail = '';
    global.userPhone = '';
    global.userStripe = '';
    global.userMessage = 0;
    global.userCard = '';
    global.userSubPlanId = '';
    global.userSubPlan = 'spark';
    global.userSubId = '';
    global.userSubStatus = 'trialing';
    global.userSubBilling = '';
    global.userSubCancel = false;
    global.userSubEnd = 0;
    global.userSubItemId = '';
    global.userStripeConnect = '';
    global.userStripeBank = '';
    global.userStripeAddress = '';
    global.timerStart = 5;
    global.userTemplates = [];
    global.userImage = '';
    global.userEmailsSent = [];
    global.userVerified = false;
    global.userOnboarding = ['', ''];
    global.userBadges = [];
    global.userComments = true;
    global.userShowBooked = true;
    global.userSubdomain = '';
    global.userLimitBooking = false;

    global.cards = [];
    global.coupon = [];
    global.userSignup = false;

    global.userRole = '';
    global.userStaff = [];
    global.spaces = [];
    global.clientPlans = [];

    global.publicData = [];
    global.publicRequests = [];
    global.showOnboarding = false;
    global.userConnectClient = null;
    global.clientToken = '';
    global.clientTokens = [];
    global.spaceClient = '';
    global.spaceTheme = 'blue';

    // Data arrays

    global.clients = [];
    global.clientGroups = [];
    global.clientsImages = [];
    global.clientsInactive = [];
    global.sessions = [];
    global.archive = [];
    global.events = []
    global.clientBest = [];
    global.clientBestGroup = [];
    global.chats = [];
    global.chatsGroup = [];
    global.activity = [];

    global.sessionsTraining = [];
    global.archiveTraining = [];
    global.recurring = [];

    global.programs = [];
    global.programsClient = [];
    global.plans = [];
    global.exercises = [];

    global.payments = [];
    global.invoices = [];
    global.products = [];

    global.userLog = [];
    global.syncDate = 0;
    global.maxClients = 0;
    global.maxPrograms = 0;
    global.maxPlans = 0;
    global.maxTrainers = 0;

    global.userDataLoaded = false;
    Firebase.auth().signOut()
  }



  // Display stuff ------------------------------------------------------------



  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-container">
          <div className="empty large pt-30 clear">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('clients')}
            </div>
          </div>
          <div className="center">
            <h2 className="mb-20" style={{textAlign: 'center'}}>{lang.t('auth:title.allset')}</h2>
            <p style={{textAlign: 'center'}}>{lang.t('auth:label.finish')}</p>
          </div>
          <div className="sv-40"></div>
          <button className="btn primary" onClick={() => this.logoutUser()}>{lang.t('auth:button.finish')}</button>
        </div>
      </div>
    )
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['auth','header'])(withRouter(withAuthorizationMember(condition)(MemberThankyouPage)));