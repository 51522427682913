import React, { Component } from 'react';
import Firebase from 'firebase';
import Connector from '../../data/Connector';
import EventEmitter from '../../helper/Emitter';
import HelperBusiness from '../../helper/Business';
import ListEmpty from '../List/empty';
import Moment from 'moment';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class ChatMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chats: global.chats,
      chatsGroup: [],
      clients: global.clients,
      show: false,
      hidden: 'hidden',
      mode: 'list',
      name: 'Client',
      chat: '',
      messages: [],
      text: '',
      token: global.clientToken,
      client: '',
      images: [],
      // Form
      available: [],
      selected: [],
      selectedInit: [],
      value: '',
      error: ['', '']
    };
  }

  componentDidMount() {
  	EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('chatLoaded', (event) => this.configureData())
    EventEmitter.subscribe('chatsGroupLoaded', (event) => this.configureGroups())
    EventEmitter.subscribe('tokenLoaded', (event) => this.setState({token: global.clientToken}))
    EventEmitter.subscribe('chatRequest', (event) => this.receiveOpen())
    this.configureGroups()
  }


  configureGroups() {
    var tmp = []
    for(var item of global.chatsGroup) {
      for(var cl of item.clients) {
        if(cl.id === global.uidUser) {
          tmp.push(item)
        }
      }
    }
    this.setState({
      chatsGroup: tmp
    }, () => {
      this.configureData()
    })
  }


  configureData() {
    var tmp = []
    var tmp2 = []
    var messages = []
    if(this.state.mode === 'chat' || this.state.mode === 'edit') {
      for(var chat of global.chats) {
        if(chat.id === this.state.chat) {
          messages = chat.messages
        }
      }
      for(var chat2 of global.chatsGroup) {
        if(chat2.id === this.state.chat) {
          messages = chat2.messages
        }
      }
    }
    for(var msg of messages) {
      msg.date = Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss')
      if(msg.data.timestamp !== undefined) {
        msg.date = Moment(msg.data.timestamp, 'X')
      }
    }
    if(global.clientTokens.length === 0) {
      for(var client of global.clients) {
        if(client.data.uid !== '') {
          Connector.getClientTokenAll(client.data.uid, client.id)
        }
      }
    }
    this.setState({
      clients: tmp,
      chats: global.chats,
      chatsGroup: global.chatsGroup,
      messages: messages,
      available: tmp2
    }, () => {
      if(this.state.mode === 'chat' && this.state.chat !== '') {
        this.scrollToBottom()
      }
    })
  }


  receiveOpen() {
    if(global.chats.length > 0) {
      this.setState({
        show: true,
      })
      if(this.state.chat !== '' && this.state.mode === 'chat') {
        this.closeChat()
      }
      setTimeout(() => {
        this.setState({
          hidden: ''
        });
      }, 100);
      this.selectChat(global.chats[0])
    }
  }


  toggleChat() {
    var tmp = this.state.show
    if(!tmp) {
      this.setState({
        show: true,
        mode: 'list',
        hidden: ''
      })
    } else {
      if(this.state.chat !== '' && this.state.mode === 'chat') {
        this.closeChat()
      }
      this.setState({
        hidden: 'hidden',
        show: false
      })
    }
  }


  getInfo(item) {
    var hasDate = false
    var label = ''
    for(var chat of this.state.chats) {
      if(chat.data.client === item) {
        var date = Moment('01/01/1900', 'DD/MM/YYYY').format('X')
        for(var msg of chat.messages) {
          var mdate = Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss').format('X')
          var ddate = Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss')
          if(msg.data.timestamp !== undefined) {
            mdate = Moment(msg.data.timestamp, 'X').format('X')
            ddate = Moment(msg.data.timestamp, 'X')
          }
          if(mdate > date) {
            hasDate = true
            date = mdate
            label = this.getDate(ddate)
          }
        }
      }
    }
    if(hasDate) {
      return (
        <p>{lang.t('message:chat.last')}<br/>{label}</p>
      )
    } else {
      return (
        <p>{lang.t('message:chat.chat1')}<br/>{lang.t('message:chat.chat2')}</p>
      )
    }
  }


  getInfoGroup(item) {
    var hasDate = false
    var label = ''
    var date = Moment('01/01/1900', 'DD/MM/YYYY').format('X')
    for(var msg of item.messages) {
      var mdate = Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss').format('X')
      var ddate = Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss')
      if(msg.data.timestamp !== undefined) {
        mdate = Moment(msg.data.timestamp, 'X').format('X')
        ddate = Moment(msg.data.timestamp, 'X')
      }
      if(mdate > date) {
        hasDate = true
        date = mdate
        label = this.getDate(ddate)
      }
    }
    if(hasDate) {
      return (
        <p>{item.clients.length+1} {lang.t('message:chat.members')}<br/>{lang.t('message:chat.last2')}: {label}</p>
      )
    } else {
      return (
        <p>{item.clients.length+1} {lang.t('message:chat.members')}<br/>{lang.t('message:chat.chat3')}</p>
      )
    }
  }


  getBullet(item) {
    var show = false
    for(var chat of this.state.chats) {
      if(chat.id === item.id) {
        for(var msg of chat.messages) {
          if(parseInt(Moment(msg.data.timestamp, 'X').format('X')) > parseInt(Moment(chat.data.timeClient, 'X').format('X')) && msg.data.sender !== global.uidUser) {
            show = true
          }
        }
      }
    }
    for(var chat2 of this.state.chatsGroup) {
      if(chat2.id === item.id) {
        var date = Moment().format('X')
        for(var cl of chat2.clients) {
          if(cl.id === global.uidUser) {
            date = Moment(cl.data.timestamp, 'X').format('X')
          }
        }
        for(var msg2 of chat2.messages) {
          if(parseInt(Moment(msg2.data.date, 'DD/MM/YYYY HH:mm:ss').format('X')) > parseInt(date) && msg2.data.sender !== global.uidUser) {
            show = true
          }
        }
      }
    }
    if(show) {
      return (
        <div className="dot"></div>
      )
    }
  }


  getDate(date) {
    var label =  Moment(date).locale(lang.language).format('llll')
    var dt = Moment(date, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY') 
    var tdy = Moment().format('DD/MM/YYYY')
    var yst = Moment().add(-1, 'days').format('DD/MM/YYYY')
    if(dt === tdy) {
      label = lang.t('calendar:label.today2')+' '+Moment(date, 'DD/MM/YYYY HH:mm:ss').locale(lang.language).format('LT')
    }
    if(dt === yst) {
      label = lang.t('calendar:label.yesterday2')+' '+Moment(date, 'DD/MM/YYYY HH:mm:ss').locale(lang.language).format('LT')
    }
    return label
  }


  getImage(name) {
    var tmp = this.state.images
    var found = false
    var label = ''
    for(var image of this.state.images) {
      if(image.indexOf(name) !== -1) {
        found = true
        label = image
      }
    }
    if(!found) {
      var img = '/images/messaging/'+this.state.chat+'/'+name+'.jpg'
      Firebase.storage().ref().child(img).getDownloadURL().then((url) => {
        tmp.push(url)
        this.setState({images: tmp})
      }).catch((error) => {})
    }

    return label
  }



  // Interactive stuff ------------------------------------------------------------



  selectChat(item) {
    var id = item.id
    var messages = []
    for(var chat of this.state.chats) {
      if(chat.id === id) {
        messages = chat.messages
      }
    }
    for(var msg of messages) {
      if(Moment(msg.data.timestamp, 'X') < Moment().add(-30, 'days')) {
        Firebase.database().ref('/messaging/'+id+'/messages/'+msg.id).remove().then(() => {
          this.setMessages()
        }).catch((error)=>{})
      }
    }
    this.setState({
      mode: 'chat',
      name: global.spaceOwner,
      chat: id,
      messages: messages,
      text: '',
      client: global.uidUser,
      images: [],
      token: '',
    }, () => {
      setTimeout(() => {
        this.scrollToBottom()
      }, 50);
    })
  }


  selectChatGroup(id) {
    var messages = []
    for(var chat of this.state.chatsGroup) {
      if(chat.id === id) {
        messages = chat.messages
      }
    }
    for(var msg of messages) {
      if(Moment(msg.data.timestamp, 'X') < Moment().add(-30, 'days')) {
        Firebase.database().ref('/messagingGroup/'+global.uid+'/'+id+'/messages/'+msg.id).remove().then(() => {
          this.setMessages()
        }).catch((error)=>{})
      }
    }
    this.setState({
      mode: 'chat',
      name: lang.t('message:chat.groupchat'),
      chat: id,
      messages: messages,
      client: 'group',
      text: '',
      images: [],
    }, () => {
      setTimeout(() => {
        this.scrollToBottom()
      }, 50);
    })
  }


  scrollToBottom() {
    const scrollHeight = this.divElement.scrollHeight;
    const height = this.divElement.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.divElement.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }


  setMessages() {
    var messages = []
    for(var chat of this.state.chats) {
      if(chat.id === this.state.chat) {
        messages = chat.messages
      }
    }
    for(var chat2 of this.state.chatsGroup) {
      if(chat2.id === this.state.chat) {
        messages = chat2.messages
      }
    }
    this.setState({
      messages: messages
    })
  }


  closeChat() {
    this.setState({
      mode: 'list',
      token: '',
      chat: ''
    })
    if(this.state.client !== 'group') {
      Firebase.database().ref('/messaging/'+this.state.chat).update({
        dateClient: Moment().format('DD/MM/YYYY HH:mm:ss'),
        timeClient: parseInt(Moment().format('X')),
      })
      var hasChat = false
      for(var item of this.state.chats) {
        if(item.id === this.state.chat) {
          hasChat = true
        }
      }
      if(!hasChat) {
        Firebase.database().ref('/messaging/'+this.state.chat).update({
          dateClient: Moment().format('DD/MM/YYYY HH:mm:ss'),
          dateTrainer: '01/01/1900 00:00:00',
          timeTrainer: parseInt(Moment().format('X')),
          timeClient: parseInt(Moment().format('X')),
          nameTrainer: global.userName,
          nameClient: global.userName,
          trainer: global.uid,
          client: global.uidUser,
          id: this.state.chat
        })
      } else {
        this.updateItem()
      }
    } else {
      Firebase.database().ref('/messagingGroup/'+global.uid+'/'+this.state.chat+'/clients/'+global.uidUser).update({
        date: Moment().format('DD/MM/YYYY HH:mm:ss'),
        timestamp: parseInt(Moment().format('X')),
      })
    }
    
  }


  onChange(event) {
    this.setState({text: event.target.value});
  };


  sendMessage() {
    if(this.state.text !== '' && this.state.client !== 'group') {
      var text = this.state.text
      var hasChat = false
      for(var item of this.state.chats) {
        if(item.id === this.state.chat) {
          hasChat = true
        }
      }
      if(!hasChat) {
        Firebase.database().ref('/messaging/'+this.state.chat).update({
          dateTrainer: Moment().format('DD/MM/YYYY HH:mm:ss'),
          timeTrainer: parseInt(Moment().format('X')),
          dateClient: '01/01/1900 00:00:00',
          nameTrainer: global.userName,
          nameClient: this.state.name,
          trainer: global.uidUser,
          client: this.state.client,
          id: this.state.chat
        })
      }
      Firebase.database().ref('/messaging/'+this.state.chat+'/messages').push({
        date: Moment().format('DD/MM/YYYY HH:mm:ss'),
        timestamp: parseInt(Moment().format('X')),
        image: '',
        sender: global.uidUser,
        text: text
      })
      if(global.clientToken !== undefined && global.clientToken !== '') {
        Connector.sendPushNotification(global.clientToken, global.userName, text, 'chat', this.state.chat)
      }
      this.setState({
        text: ''
      })
    } else if(this.state.text !== '' && this.state.client === 'group') {
      var text2 = this.state.text
      Firebase.database().ref('/messagingGroup/'+global.uid+'/'+this.state.chat+'/messages').push({
        date: Moment().format('DD/MM/YYYY HH:mm:ss'),
        timestamp: parseInt(Moment().format('X')),
        image: '',
        sender: global.uidUser,
        text: text2,
        name: global.userName
      })
      var sent = []
      for(var cht of this.state.chatsGroup) {
        if(cht.id === this.state.chat) {
          if(global.clientToken !== undefined && global.clientToken !== '') {
            Connector.sendPushNotification(global.clientToken, global.userName+' @ '+cht.data.name, text2, 'chat', this.state.chat)
          }
          for(var client of cht.clients) {
            for(var item2 of global.clients) {
              if(client.id === item2.data.uid && sent.indexOf(item2.data.pushToken) === -1 && item2.data.pushToken !== undefined && item2.data.pushToken !== '') {
                sent.push(item2.data.pushToken)
                Connector.sendPushNotification(item2.data.pushToken, global.userName+' @ '+cht.data.name, text2, 'chat', this.state.chat)
              }
            }
          }
        }
      }

      this.setState({
        text: ''
      })
      
    }
  }


  updateItem() {
    var tmp = this.state.chats
    for(var item of tmp) {
      if(item.id === this.state.chat) {
        item.data.dateClient = Moment().format('DD/MM/YYYY HH:mm:ss')
        item.data.timeClient = Moment().format('X')
      }
    }
    this.setState({
      chats: tmp
    })
  }



  // Display stuff ------------------------------------------------------------



  renderMessageContent(item) {
    if(item.data.image === '') {
      return (
        <div className="message-text">{item.data.text}</div>
      )
    } else {
      return (
        <div className="message-text">
          <img src={this.getImage(item.data.image)} alt="Chat"/>
        </div>
      )
    }
  }


  renderMessageUser(item) {
    var label = ''
    if(item.data.name !== undefined && item.data.sender !== global.uidUser) {
      label = item.data.name+' - '
    }
    return label
  }


  renderMessages() {
    if(this.state.messages.length === 0) {
      return (
        <div className="chat-messages" ref={ (divElement) => { this.divElement = divElement }}>
          <ListEmpty id='chat' type='simple'/>
        </div>
      )
    } else {
      var list = this.state.messages
      list.sort((a,b) => Moment(a.date, 'DD/MM/YYYY HH:mm:aa') - Moment(b.date, 'DD/MM/YYYY HH:mm:aa'));
      return (
        <div className="chat-messages" ref={ (divElement) => { this.divElement = divElement }}>
          {list.map(item => (
            <div className={item.data.sender === global.uidUser ? 'chat-message user' : 'chat-message '} key={item.id}>
              <p className="date">{this.renderMessageUser(item)}{Moment(item.data.date, 'DD/MM/YYYY HH:mm:aa').locale(lang.language).format('lll')}</p>
              {this.renderMessageContent(item)}
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderChat() {
    return (
      <div className="chat-base">
        <div className="chat-header">
          <h4 className="lft">{this.state.name}</h4>
          <button className="btn tertiary small rgt close" onClick={() => this.closeChat()}>Close</button>
        </div>
        {this.renderMessages()}
        <div className="chat-control">
          <textarea placeholder={lang.t('message:chat.typesomething')} value={this.state.text} onChange={(event) => this.onChange(event)}></textarea>
          <div className="col-6 pt-10">
            <button className={this.state.text === '' ? 'none' : 'btn primary'} onClick={() => this.sendMessage()}>{lang.t('message:chat.send')}</button>
          </div>
        </div>
      </div>
    )
  }


  renderList() {
    var list = this.state.chats
    return (
      <div>
        {list.map((item, index) => (
          <div className="list bare" key={item.id} onClick={() => this.selectChat(item)}>
            {HelperBusiness.renderLogo()}
            <div className="main">
              <h4><span className="lft">{global.spaceOwner}</span>{this.getBullet(item)}</h4>
              <div className="clear"></div>
              {this.getInfo(item.data.client)}
            </div>
          </div>
        ))}
      </div>
    )
  }


  renderListGroup() {
    var list = this.state.chatsGroup
    list.sort((a,b) => a.data.name.localeCompare(b.data.name))
    return (
      <div>
        {list.map((item, index) => (
          <div className="list bare" key={item.id} onClick={() => this.selectChatGroup(item.id)}>
            <div className="icon primary">
              <div className="inner group"></div>
            </div>
            <div className="main">
            <h4><span className="lft">{item.data.name}</span>{this.getBullet(item)}</h4>
              <div className="clear"></div>
              {this.getInfoGroup(item)}
            </div>
          </div>
        ))}
      </div>
    )
  }


  renderContent() {
    if(this.state.mode === 'list') {
      if(this.state.chats.length === 0 && this.state.chatsGroup.length === 0) {
        return (
          <div className="chat-base">
            <div className="empty small pt-60">
              <h4>{lang.t('message:chat.nochats')}</h4>
            </div>
          </div>
        )
      } else {
        return (
          <div className="chat-base">
            <div className="chat-content">
              {this.renderListGroup()}
              {this.renderList()}
            </div>
          </div>
        )
      }
    } else {
      return (
        this.renderChat()
      )
    }
  }


  renderBar() {
    return (
      <div className={'chat-container '+this.state.hidden}>
        <h3 className="mb-10 lft">{lang.t('header:title.messaging')}</h3>
        <div className="clear"></div>
        {this.renderContent()}
      </div>
    )
  }


  renderMainBullet() {
    var show = false
    for(var item of this.state.chats) {
      for(var msg of item.messages) {
        var dt = Moment(msg.data.date, 'DD/MM/YYYY HH:mm:ss').format('X')
        if(msg.data.timestamp !== undefined) {
          dt = Moment(msg.data.timestamp, 'X').format('X')
        }
        if(parseInt(dt) > parseInt(Moment(item.data.timeClient, 'X').format('X')) && msg.data.sender !== global.uidUser) {
          show = true
        }
      }
    }
    for(var item2 of this.state.chatsGroup) {
      for(var msg2 of item2.messages) {
        var date = Moment().format('X')
        for(var cl of item2.clients) {
          if(cl.id === global.uidUser) {
            date = Moment(cl.data.timestamp, 'X').format('X')
          }
        }
        var dt2 = Moment(msg2.data.date, 'DD/MM/YYYY HH:mm:ss').format('X')
        if(msg2.data.timestamp !== undefined) {
          dt2 = Moment(msg2.data.timestamp, 'X').format('X')
        }
        if(parseInt(dt2) > parseInt(date) && msg2.data.sender !== global.uidUser) {
          show = true
        }
      }
    }
    if(show) {
      return (
        <div className="dot"></div>
      )
    }
  }


  render() {
    if(!global.userRestricted) {
      return (
        <div>
          {this.renderBar()}
          <div className={this.state.show ? 'chat active' : 'chat'} onClick={() => this.toggleChat()}>
            {this.renderMainBullet()}
          </div>
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}


export default withTranslation(['message','common','calendar','header'])(ChatMember);