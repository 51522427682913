import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperEmptyImg from '../../helper/EmptyLarge';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberSpacesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      spaces: global.userStaff,
      showOverlay: false,
      hidden: 'hidden',
      showOverlayConnect: false,
      hiddenConnect: 'hidden',
      margin: 0,
      selected: null,
      img: null,
      error: false,
      add: false,
      search: '',
      allspaces: [],
      list: [],
      value: ''
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-2]
    if(id !== 'spaces' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.memberarea')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300); 
    }
    Connector.loadAllSpaces((result) => {})

    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('spaceInfoLoaded', (event) => this.configureData())
    EventEmitter.subscribe('allSpacesLoaded', (event) => this.setTrainers())
    this.configureData()

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.memberarea')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    this.setState({
      spaces: global.userStaff,
    }, () => {
      for(var item of global.userStaff) {
        if(item.owner === 'Trainer') {
          Connector.getSpaceInfo(item)
        }
      }
    })
  }


  setTrainers() {
    this.setState({
      allspaces: global.spaces
    })
  }


  onChange = event => {
    this.setState({
      search: event.target.value
    });
  };


  filterList() {
    var tmp = []
    if(this.state.search !== '') {
      var val = this.state.search.toLowerCase()
      for(var item of this.state.allspaces) {
        if(item.data.name !== undefined && item.data.owner !== undefined && item.data.name !== '' && item.data.owner !== '') {
          var l1 = String(item.data.name).toLowerCase()
          var l2 = String(item.data.owner).toLowerCase()
          var num = parseInt(Math.random()*6+1)
          item.num = num
          if(l1.indexOf(val) !== -1 || l2.indexOf(val) !== -1) {
            tmp.push(item)
          }
          if(item.data.image !== '' && item.img === '') {
            this.getImageSpace(item)
          }
        }
        
      }
    }
    this.setState({
      list: tmp
    })
  }


  switchSpace(item) {
    global.uid = item.id
    global.userBusiness = item.name
    global.spaceOwner = item.owner
    global.spaceImage = item.image
    global.spaceClient = item.client
    global.userStripeConnect = item.stripe
    global.clientToken = item.token
    global.spaceTheme = item.theme
    global.userCommunity = item.community
    global.userCommunityPost = item.communityPost
    global.userLimitBooking = item.limitBooking
    global.userAllowBooking = item.allowBooking
    global.spaceAllowRecurring = item.allowRecurring
    global.spaceEmailReminder = item.emailReminder ?? false

    global.clients = [];
    global.clientGroups = [];
    global.clientsImages = [];
    global.clientsInactive = [];
    global.sessions = [];
    global.archive = [];
    global.events = []
    global.clientBest = [];
    global.clientBestGroup = [];
    global.chats = [];
    global.chatsGroup = [];
    global.activity = [];

    global.sessionsTraining = [];
    global.archiveTraining = [];
    global.recurring = [];

    global.programs = [];
    global.programsClient = [];
    global.plans = [];
    global.exercises = [];

    global.payments = [];
    global.invoices = [];
    global.locations = [];

    Connector.loadSessions((result) => {})
    Connector.loadEvents((result) => {})
    Connector.loadClient((result) => {
      if(global.userParent !== '' && global.userParent !== undefined) {
        Connector.loadFamilyBilling(global.userParent)
      }
    })
    Connector.loadClientGroups((result) => {})
    Connector.loadPayments((result) => {})
    Connector.loadInvoices((result) => {})
    Connector.loadLog((result) => {})
    Connector.loadChat((result) => {})
    Connector.loadChatsGroup((result) => {})
    Connector.loadClients((result) => {})
    Connector.loadPrograms((result) => {})
    Connector.loadCommunity((result) => {})
    Connector.loadRecurring((result) => {})
    Connector.loadHabits((result) => {})
    Connector.loadDocuments((result) => {})
    Connector.loadTraining((result) => {})
    Connector.loadExercises((result) => {})
    Connector.loadLocations((result) => {})

    this.props.history.push(ROUTES.LOADING)

  }



  // Overlay stuff ------------------------------------------------------------



  showOverlay(item) {
    this.setState({
      showOverlay: true,
      selected: item,
      img: null,
      value: ''
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlay() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlay: false});
    }, 500);
  }


  renderImageOverlay() {
    if(this.state.selected.image !== '') {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.selected.image+')'}}></div>
      )
    } else {
      var inits = ''
      let arr = this.state.selected.name.split(' ')
      if(arr.length > 1) {
        inits = arr[0].charAt(0)+arr[1].charAt(0)
      } else {
        inits = arr[0].charAt(0)
      }
      return (
        <div className="avatar">
          <p>{inits}</p>
        </div>
      )
    }
  }


  renderOverlay() {
    if(this.state.showOverlay) {
      return (
        <div className={'overlay '+this.state.hidden}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:leave.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlay()}>{lang.t('modal:common.close')}</button>
            <div className="client static">
              {this.renderImageOverlay()}
              <div className="text">
                <h4>{this.state.selected.name}</h4>
                <p>{this.state.selected.owner}</p>
              </div>
            </div>
            <div className="clear sv-20"></div>
            <p className="clear">{lang.t('modal:leave.text1')} {this.state.selected.name}{lang.t('modal:leave.text2')}</p>
            <div className="sv-30"></div>
            <button className="btn primary" onClick={() => this.leaveSpace()}>{lang.t('auth:button.leave')}</button>
          </div>
        </div>
      )
    }
  }


  leaveSpace() {
    this.hideOverlay()
    EventEmitter.dispatch('showMessage', 'You left '+this.state.selected.name);
    setTimeout(() => {
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.selected.client).update({
        uid: ''
      })
      Firebase.database().ref('/usersClients/'+global.uidUser+'/trainers/'+this.state.selected.id).remove()
      for(var i=global.userStaff.length-1; i>-1; i--) {
        if(global.userStaff[i] === this.state.selected.id) {
          global.userStaff.splice(i, 1)
        }
      }
      global.uid = ''
      this.props.history.push(ROUTES.LOADING)
    }, 1000);
  }



  // Overlay stuff ------------------------------------------------------------



  showOverlayConnect(item) {
    this.setState({
      showOverlayConnect: true,
      selected: item,
      img: null,
      value: ''
    })
    if(global.connect.length === 0) {
      Connector.loadConnect((result) => {})
    }
    setTimeout(() => {
      const height = this.divElement.clientHeight
      if(this.state.selected.data.image !== '') {
        this.getImageConnect()
      }
      this.setState({
        hiddenConnect: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayConnect() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenConnect: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayConnect: false});
    }, 500);
  }


  getImageConnect() {
    if(this.state.selected.data.image !== '') {
      Firebase.storage().ref().child(this.state.selected.data.image).getDownloadURL().then((url) => {
        this.setState({
          img: url
        })
      }).catch((error) => {
        // Handle any errors
      })
    }
  }


  renderImageConnect() {
    if(this.state.img !== null) {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.img+')'}}></div>
      )
    } else {
      if(this.state.img !== null) {
        return (
          <div className="avatar" style={{backgroundImage: 'url('+this.state.img+')'}}></div>
        )
      } else {
        var inits = ''
        let arr = this.state.selected.data.name.split(' ')
        if(arr.length > 1) {
          inits = arr[0].charAt(0)+arr[1].charAt(0)
        } else {
          inits = arr[0].charAt(0)
        }
        return (
          <div className="avatar">
            <p>{inits}</p>
          </div>
        )
      }
    }
  }


  renderError() {
    if(this.state.error) {
      return (
        <p className="error">{lang.t('modal:connect.error')}</p>
      )
    }
  }


  renderOverlayConnect() {
    if(this.state.showOverlayConnect) {
      return (
        <div className={'overlay '+this.state.hiddenConnect}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:connect.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayConnect()}>{lang.t('modal:common.close')}</button>
            <div className="client static">
              {this.renderImageConnect()}
              <div className="text">
                <h4>{this.state.selected.data.name}</h4>
                <p>{this.state.selected.data.owner}<br/>{this.state.selected.data.email}</p>
              </div>
            </div>
            <div className="clear sv-20"></div>
            <p className="clear mb-30">{lang.t('modal:connect.text')}</p>
            <div className="form-item">
            <label>{lang.t('modal:connect.label')}</label>
              <input type="number" placeholder={lang.t('modal:connect.label')} value={this.state.value} onChange={this.onChangePIN}/>
              {this.renderError()}
            </div>
            <div className="sv-20"></div>
            <button className="btn primary" onClick={() => this.checkConnect()}>{lang.t('modal:connect.button')}</button>
          </div>
        </div>
      )
    }
  }


  onChangePIN = event => {
    this.setState({
      value: event.target.value
    });
  };


  checkConnect() {
    if(this.state.value === this.state.selected.data.pin) {
      this.connectSpace()
    } else {
      this.setState({
        error: true
      })
    }
  }


  connectSpace() {
    this.hideOverlayConnect()
    var obj =  Firebase.database().ref('/clients/'+this.state.selected.id).push()
    var image = global.userImage
    var height = 0
    if(global.coupon !== '' && global.coupon !== undefined) {
      height = global.coupon
    }
    if(height === 'NaN') {
      height = 0
    }
    Firebase.database().ref('/usersClients/'+Firebase.auth().currentUser.uid+'/trainers/'+this.state.selected.id).update({
      client: obj.key,
      trainer: global.uid
    })
    global.userVerified = true
    Firebase.database().ref('activity/'+this.state.selected.id).push({
      type: 'newclient',
      data: global.uidUser+','+obj.key,
      date: Moment().format('DD/MM/YYYY HH:mm')
    })
    Firebase.database().ref('/clients/'+this.state.selected.id+'/'+obj.key).update({
      uid: global.uidUser,
      ecName: global.emergency[0],
      ecType: parseInt(global.emergency[1]),
      ecPhone: global.emergency[2],
      name: global.userName,
      phone: global.userPhone,
      birth: Moment(global.userBday, 'DD/MM/YYYY').format('DD/MM/YYYY'),
      height: height,
      image: image,
      country: 'au',
      email: global.userEmail,
      imageDate: Moment().format('DD/MM/YYYY HH:mm')
    }).then(() => {
      EventEmitter.dispatch('showMessage', lang.t('message:connected'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Display stuff ------------------------------------------------------------



  getImageSpace(item) {
    Firebase.storage().ref().child(item.data.image).getDownloadURL().then((url) => {
      //global.spaceImage = url
      //EventEmitter.dispatch('userLoaded', 'loaded');
      var tmp = this.state.list
      for(var sp of tmp) {
        if(sp.id === item.id) {
          sp.img = url
        }
      }
      this.setState({
        list: tmp
      })
    }).catch((error) => {
      // Handle any errors
    })
  }


  renderListImg(item) {
    if(item.img === '') {
      return (
        <div className={'member-image bg'+item.num}>
          <div className={'gradient theme-'+item.data.theme}></div>
        </div>
      )
    } else {
      return (
        <div className="member-image" style={{backgroundImage: 'url('+item.img+')'}}></div>
      )
    }
  }


  renderImage(item) {
    if(item.image === '') {
      return (
        <div className={'member-image bg'+item.num}>
          <div className={'gradient theme-'+item.theme}></div>
        </div>
      )
    } else {
      return (
        <div className="member-image" style={{backgroundImage: 'url('+item.image+')'}}></div>
      )
    }
  }


  renderSwitch(item) {
    if(item.id !== global.uid) {
      return (
        <button className="btn tertiary small rgt" onClick={() => this.switchSpace(item)}>{lang.t('auth:button.switch')}</button>
      )
    }
  }


  renderContent() {
    if(this.state.spaces.length === 0) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('groups')}
          </div>
          <div className="data">
            <h3 className="mb-20">{lang.t('header:auth.nospaces')}</h3>
            <p className="mb-20" style={{whiteSpace: 'pre-wrap'}}>{lang.t('auth:label.addspace')}</p>
          </div>
        </div>
      )
    } else {
      var list = this.state.spaces
      list.sort((a, b) => a.name.localeCompare(b.name))
      return (
        <div>
          {list.map(item => (
            <div key={item.id}>
              <div className="box list simple highlight mb-10">
                {this.renderImage(item)}
                <div className="text lft">
                  <h4>{item.name}</h4>
                  <p>{item.owner}</p>
                </div>
                <button className="btn tertiary small close rgt ml-20" onClick={() => this.showOverlay(item)}>{lang.t('auth:button.leave')}</button>
                {this.renderSwitch(item)}
                <div className="clear"></div>
              </div>
              <div className="clear sv-20"></div>
            </div>
          ))}
          <div className="clear sv-20"></div>
        </div>
      )
    }
  }


  renderList() { 
    if(this.state.allspaces.length > 0 && this.state.search !== '') {
      var list = this.state.list
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          {list.map(item => (
              <div key={item.id} onClick={() => this.showOverlayConnect(item)}>
                <div className="box list simple highlight mb-10">
                  {this.renderListImg(item)}
                  <div className="text lft">
                    <h4>{item.data.name}</h4>
                    <p>{item.data.owner}<br/>{lang.t('auth:label.clicktoconnect')}</p>
                  </div>
                  <div className="clear"></div>
                </div>
              </div>
            ))}
        </div>
      )
    }
  }


  renderMain() {
    if(this.state.add) {
      return (
        <div>
          <div className="listheader">
            <h2>{lang.t('header:auth.addspace')}</h2>
            <div className="clear"></div>
          </div>
          <div className="sv-20 clear"></div>
          <div className="col-8">
            <input type="text" placeholder={lang.t('auth:form.search.placeholder')} value={this.state.search} onChange={this.onChange}/>
          </div>
          <div className="col-4">
            <button className="btn primary" onClick={() => this.filterList()}>{lang.t('common:button.search')}</button>
          </div>
          <div className="clear sv-40"></div>
          {this.renderList()}
          <div className="sv-20"></div>
          <button className="btn tertiary close width-12" onClick={() => this.setState({add: false})}>{lang.t('common:button.cancel')}</button>
        </div>
      )
    } else {
      return (
        <div>
          <div className="listheader">
            <h2>{this.state.spaces.length > 1 ? lang.t('header:auth.spaces') : lang.t('header:auth.space')}</h2>
            <div className="clear"></div>
          </div>
          {this.renderContent()}
          <button className="btn tertiary add width-12 mt-30" onClick={() => this.setState({add: true, search: '', list: []})}>{lang.t('auth:button.addspace')}</button>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-sidebar">
          
          {this.renderMain()}
        </div>
        <Navigation active='' />
        {this.renderOverlay()}
        {this.renderOverlayConnect()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['auth','header','modal','common'])(withRouter(withAuthorizationMember(condition)(MemberSpacesPage)));