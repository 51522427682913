import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import Connector from '../../data/Connector';
import Navigation from '../../components/Navigation';
import HelperBilling from '../../helper/Billing';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperEmptyImg from '../../helper/EmptyLarge';
import InfoPrice from '../../components/Form/price';
import WidgetCard from '../../components/Widgets/card';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { AuthUserContext, withAuthorization } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberPaymentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      num: global.num,
      products: global.products,
      product: null,
      productId: '',
      group: false,
      loading: false,
      card: false,
      valuesCard: ['', '', '', '', ''],
      errorCard: [false, false, false, false, false],
      showOverlay: false,
      hidden: 'hidden',
      log: global.userLog,
      family: '',
      showOverlayDesc: false,
      hiddenDesc: 'hidden',
      margin: 0,
      invoice: '',
      preselectedProd: false,
      showModalStock: false,
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    if(id !== 'admin' && id !== 'payment') {
      global.uid = id
    }

    Connector.setUser()
    document.title = lang.t('header:title.payment')+' - '+global.userBusiness
    window.scrollTo(0, 0)
    
    this.setState({
      done: true
    })

    if(global.uidUser === '') {

    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('logLoaded', (event) => this.configureLog())
    EventEmitter.subscribe('invoicesLoaded', (event) => this.configureData())
    this.configureData()
  }


  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.payment')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var pid = ''
    var product = null
    var pre = false
    var invoice = ''
    var arr = this.props.location.pathname.split('/')
    if(arr[arr.length-1] !== 'pay-invoice') {
      for(var inv of global.invoices) {
        if(inv.id === arr[arr.length-1]) {
          invoice = inv.id
          pid = inv.data.product
          pre = true
        }
      }
    }
    for(var prod of global.products) {
      if(prod.id === pid) {
        product = prod
      }
    }
    this.setState({
      products: global.products,
      productId: pid,
      product: product,
      invoice: invoice,
      preselectedProd: pre
    })
  }


  configureLog() {
    this.setState({
      log: global.userLog
    }, () => {
      if(this.state.loading) {
        for(var item of this.state.log) {
          if(item.data.title === 'chargeerror') {
            EventEmitter.dispatch('showMessageError', item.data.message);
            Firebase.database().ref('/log/'+global.uid+'/'+item.id).remove()
            this.setState({
              loading: false,
            })
          }
          if(item.data.title === 'charge' || item.data.type === 'charge') {
            EventEmitter.dispatch('showMessage', lang.t('message:payment'));
            this.setState({
              loading: false,
            })
          }
        }
      }
    })
  }



  // Interactive stuff ------------------------------------------------------------



  selectProduct(event) {
    if(event.target.value !== '') {
      var tmp = null
      for(var item of this.state.products) {
        if(item.id === event.target.value) {
          tmp = item
        }
      }
      var card = true
      if(global.userCard !== '') {
        card = false
      }
      this.setState({
        card: card
      });
      this.setState({
        productId: event.target.value,
        product: tmp,
        group: tmp.data.group
      });
    }
  }


  selectFamily(event) {
    this.setState({
      family: event.target.value
    });
  }


  getFamilyName(id) {
    var label = 'Client'
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    return label
  }


  onChangeCard = event => {
    var tmp = this.state.valuesCard
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({valuesCard: tmp});
  };


  checkMakePayment() {
    this.setState({errorCard: [false, false, false, false, false]})
    var tmp = [false, false, false, false, false]
    var passed = true
    if(this.state.valuesCard[0] === '') {
      tmp[0] = true
      passed = false
    }
    if(this.state.valuesCard[1] === '') {
      tmp[1] = true
      passed = false;
    }
    if(this.state.valuesCard[2] === '') {
      tmp[2] = true
      passed = false;
    }
    if(this.state.valuesCard[3] === '') {
      tmp[3] = true
      passed = false;
    }
    if(this.state.valuesCard[4] === '') {
      tmp[4] = true
      passed = false;
    }
    this.setState({errorCard: tmp})
    var passed2 = false
    var stock = -1
    if(this.state.product.data.stock !== undefined && this.state.product.data.stock !== -1) {
      if(this.state.product.data.stock === 0) {
        this.setState({
          showModalStock: true
        })
      } else {
        passed2 = true
        stock = this.state.product.data.stock-1
      }
    } else {
      passed2 = true
    }
    if(passed && passed2) {
      this.showOverlayConfirm()
    } else if(passed && !passed2) {
      this.setState({
        showModalStock: true
      })
    }
  }


  paymentSwitch() {
    this.hideOverlayConfirm()
    var passed = false
    var stock = -1
    if(this.state.product.data.stock !== undefined && this.state.product.data.stock !== -1) {
      if(this.state.product.data.stock === 0) {
        this.setState({
          showModalStock: true
        })
      } else {
        passed = true
        stock = this.state.product.data.stock-1
      }
    } else {
      passed = true
    }
    if(passed) {
      if(!this.state.card) {
        this.makePayment()
      } else {
        this.setState({
          loading: true,
        })
        var fee = this.state.product.data.price*0.5
        var email = global.userEmail

        var product = this.state.product.data.name
        if(product.length > 21) {
          product = this.state.product.data.name.slice(0, 20)
        }

        var idGS = ''
        var paidGS = this.state.product.data.sessions
        var id11 = ''
        var paid11 = this.state.product.data.sessions11
        var sessions11 = this.state.product.data.sessions11
        if(this.state.product.data.stype === '11') {
          paid11 = this.state.product.data.sessions
          sessions11 = this.state.product.data.sessions
        }

        var ppaidGS = 0
        var psessionsGS = 0
        var pidGS = ''
        var ppaid11 = 0
        var psessions11 = 0
        var pid11 = ''
        var expires = 0

        if(this.state.product.data.expires !== undefined) {
          expires = parseInt(Moment().add(this.state.product.data.expires, this.state.product.data.expType).format('X'))
          for(var cred1 of global.credits) {
            if(cred1.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === 'group') && cred1.data.sessionsTotal > cred1.data.sessionsPaid) {
              if(cred1.data.account === this.state.family || (cred1.data.account === undefined && this.state.family === '')) {
                psessionsGS = cred1.data.sessionsTotal-cred1.data.sessionsPaid
                if(psessionsGS > this.state.product.data.sessions) {
                  psessionsGS = this.state.product.data.sessions
                }
                ppaidGS = cred1.data.sessionsPaid+psessionsGS
                pidGS = cred1.id
              }
            }
            if(!cred1.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === '11') && cred1.data.sessionsTotal > cred1.data.sessionsPaid) {
              if(cred1.data.account === this.state.family || (cred1.data.account === undefined && this.state.family === '')) {
                psessions11 = cred1.data.sessionsTotal-cred1.data.sessionsPaid
                if(psessions11 > sessions11) {
                  psessions11 = sessions11
                }
                ppaid11 = cred1.data.sessionsPaid+psessions11
                pid11 = cred1.id
              }
            }
          }
        } else {
          if(global.credits !== undefined) {
            for(var cred of global.credits) {
              if(cred.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === 'group') && cred.data.expires === undefined) {
                if(cred.data.account === this.state.family || (cred.data.account === undefined && this.state.family === '')) {
                  idGS = cred.id
                  paidGS = this.state.product.data.sessions+cred.data.sessionsPaid
                }
              }
              if(!cred.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === '11') && cred.data.expires === undefined) {
                if(cred.data.account === this.state.family || (cred.data.account === undefined && this.state.family === '')) {
                  id11 = cred.id
                  paid11 = this.state.product.data.sessions+cred.data.sessionsPaid
                }
              }
            }
          }
        }

        if(global.userStripe !== '') {
          var callFunction = Firebase.functions().httpsCallable('connectedPayment');
          callFunction({type: 'card', account: global.userStripeConnect, clientname: global.userName, amount: parseInt(this.state.product.data.price*100), fee: fee, pack: product, email: email, name: this.state.valuesCard[0], card: this.state.valuesCard[1], month: this.state.valuesCard[2], year: this.state.valuesCard[3], cvc: this.state.valuesCard[4], customer: global.userStripe, client: global.spaceClient, date: Moment().format('DD/MM/YYYY HH:mm'), currency: global.userCurrency, stype: this.state.product.data.stype, sessionsGS: this.state.product.data.sessions, sessions11: sessions11, creditsGS: idGS, credits11: id11, paidGS: paidGS, paid11: paid11, creditseGS: pidGS, creditse11: pid11, sessionseGS: psessionsGS, sessionse11: psessions11, paidnewGS: ppaidGS, paidnew11: ppaid11, expires: expires, ptype: this.state.product.data.type, uid: global.uid, user: this.state.family, invoice: this.state.invoice, product: this.state.product.id, stock: stock}).then(function(result) {});
        } else {
          var callFunction2 = Firebase.functions().httpsCallable('connectedPayment');
          callFunction2({type: 'account', account: global.userStripeConnect, amount: parseInt(this.state.product.data.price*100), fee: fee, pack: product, email: email, name: this.state.valuesCard[0], card: this.state.valuesCard[1], month: this.state.valuesCard[2], year: this.state.valuesCard[3], cvc: this.state.valuesCard[4], clientname: global.userName, client: global.spaceClient, date: Moment().format('DD/MM/YYYY HH:mm'), currency: global.userCurrency, stype: this.state.product.data.stype, sessionsGS: this.state.product.data.sessions, sessions11: sessions11, creditsGS: idGS, credits11: id11, paidGS: paidGS, paid11: paid11, creditseGS: pidGS, creditse11: pid11, sessionseGS: psessionsGS, sessionse11: psessions11, paidnewGS: ppaidGS, paidnew11: ppaid11, expires: expires, ptype: this.state.product.data.type, uid: global.uid, user: this.state.family, invoice: this.state.invoice, product: this.state.product.id, stock: stock}).then(function(result) {});
        }
      }
    }
  }


  makePayment() {
    var stock = -1
    if(this.state.product.data.stock !== undefined && this.state.product.data.stock !== -1) {
      if(this.state.product.data.stock === 0) {
        this.setState({
          showModalStock: true
        })
      } else {
        stock = this.state.product.data.stock-1
      }
    }

    this.setState({
      loading: true,
    })
    var product = this.state.product.data.name
    if(product.length > 21) {
      product = this.state.product.data.name.slice(0, 20)
    }
    var email = global.userEmail

    var idGS = ''
    var paidGS = this.state.product.data.sessions
    var id11 = ''
    var paid11 = this.state.product.data.sessions11
    var sessions11 = this.state.product.data.sessions11
    if(this.state.product.data.stype === '11') {
      paid11 = this.state.product.data.sessions
      sessions11 = this.state.product.data.sessions
    }

    var ppaidGS = 0
    var psessionsGS = 0
    var pidGS = ''
    var ppaid11 = 0
    var psessions11 = 0
    var pid11 = ''
    var expires = 0

    if(this.state.product.data.expires !== undefined) {
      expires = parseInt(Moment().add(this.state.product.data.expires, this.state.product.data.expType).format('X'))
      for(var cred1 of global.credits) {
        if(cred1.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === 'group') && cred1.data.sessionsTotal > cred1.data.sessionsPaid) {
          if(cred1.data.account === this.state.family || (cred1.data.account === undefined && this.state.family === '')) {
            psessionsGS = cred1.data.sessionsTotal-cred1.data.sessionsPaid
            if(psessionsGS > this.state.product.data.sessions) {
              psessionsGS = this.state.product.data.sessions
            }
            ppaidGS = cred1.data.sessionsPaid+psessionsGS
            pidGS = cred1.id
          }
        }
        if(!cred1.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === '11') && cred1.data.sessionsTotal > cred1.data.sessionsPaid) {
          if(cred1.data.account === this.state.family || (cred1.data.account === undefined && this.state.family === '')) {
            psessions11 = cred1.data.sessionsTotal-cred1.data.sessionsPaid
            if(psessions11 > sessions11) {
              psessions11 = sessions11
            }
            ppaid11 = cred1.data.sessionsPaid+psessions11
            pid11 = cred1.id
          }
        }
      }
    } else {
      if(global.credits !== undefined) {
        for(var cred of global.credits) {
          if(cred.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === 'group') && cred.data.expires === undefined) {
            if(cred.data.account === this.state.family || (cred.data.account === undefined && this.state.family === '')) {
              idGS = cred.id
              paidGS = this.state.product.data.sessions+cred.data.sessionsPaid
            }
          }
          if(!cred.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === '11') && cred.data.expires === undefined) {
            if(cred.data.account === this.state.family || (cred.data.account === undefined && this.state.family === '')) {
              id11 = cred.id
              paid11 = this.state.product.data.sessions+cred.data.sessionsPaid
            }
          }
        }
      }
    }

    var fee = this.state.product.data.price*0.5 //includes *100 calculation
    var callFunction = Firebase.functions().httpsCallable('connectedPayment');
    callFunction({type: 'pay', account: global.userStripeConnect, amount: parseInt(this.state.product.data.price*100), fee: fee, pack: product, customer: global.userStripe, client: global.spaceClient, date: Moment().format('DD/MM/YYYY HH:mm'), currency: global.userCurrency, stype: this.state.product.data.stype, sessionsGS: this.state.product.data.sessions, sessions11: sessions11, email: email, creditsGS: idGS, credits11: id11, paidGS: paidGS, paid11: paid11, creditseGS: pidGS, creditse11: pid11, sessionseGS: psessionsGS, sessionse11: psessions11, paidnewGS: ppaidGS, paidnew11: ppaid11, expires: expires, ptype: this.state.product.data.type, uid: global.uid, user: this.state.family, invoice: this.state.invoice, product: this.state.product.id, stock: stock}).then(function(result) {
      //var sanitizedMessage = result.data.text;
    });
  }



  // Card overlay ------------------------------------------------------------



  showOverlayConfirm() {
    this.setState({
      showOverlay: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayConfirm() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlay: false});
    }, 500);
  }


  renderOverlayConfirm() {
    if(this.state.showOverlay) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:confirmpay.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayConfirm()}>{lang.t('modal:common.close')}</button>
            <p className="clear mb-30">{lang.t('modal:confirmpay.text1')} {this.state.product.data.name} {lang.t('modal:confirmpay.text2')} {HelperBilling.showAmount(this.state.product.data.price)}?</p>
            <button className="btn primary" onClick={() => this.paymentSwitch()}>{lang.t('modal:confirmpay.button')}</button>
          </div>
        </div>
      )
    }
  }



  //Description overlay ------------------------------------------------------------



  showOverlayDesc() {
    this.setState({
      showOverlayDesc: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenDesc: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayDesc() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenDesc: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayDesc: false});
    }, 500);
  }


  renderOverlayDesc() {
    if(this.state.showOverlayDesc) {
      var desc = ''
      for(var item of global.products) {
        if(item.id === this.state.productId) {
          desc = item.data.desc
        }
      }
      return (
        <div className={'overlay '+this.state.hiddenDesc}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modal:product.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayDesc()}>{lang.t('modal:common.close')}</button>
            <p className="clear" style={{whiteSpace: 'pre'}}>{desc}</p>
          </div>
        </div>
      )
    }
  }


  hideModals() {
    this.setState({
      showModalStock: false,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderErrorCards(num) {
    var texts = [lang.t('admin:card.error1'), lang.t('admin:card.error2'), lang.t('admin:card.error3'), lang.t('admin:card.error4'), lang.t('admin:card.error5')]
    if(this.state.errorCard[num]) {
      return (
        <p className="error">{texts[num]}</p>
      )
    }
  }


  renderBottom() {
    if(this.state.productId !== '') {
      if(this.state.card) {
        return (
          <div>
            <div className="sv-30"></div>
            <div className="col-12">
              <label>{lang.t('admin:card.name')}*</label>
              <input type="text" value={this.state.valuesCard[0]} name="0" onChange={this.onChangeCard}/>
              {this.renderErrorCards(0)}
              <div className="sv-20"></div>
              <label>{lang.t('admin:card.number')}*</label>
              <input type="number" value={this.state.valuesCard[1]} name="1" onChange={this.onChangeCard}/>
              {this.renderErrorCards(1)}
              <div className="sv-20"></div>
            </div>
            <div className="sv-20"></div>
            <div className="col-4">
              <label>{lang.t('admin:card.expiry')}*</label>
              <input type="number" placeholder="MM" value={this.state.valuesCard[2]} name="2" onChange={this.onChangeCard}/>
            </div>
            <div className="col-4">
              <label>&nbsp;</label>
              <input type="number" placeholder="YY" value={this.state.valuesCard[3]} name="3" onChange={this.onChangeCard}/>
            </div>
            <div className="col-1">&nbsp;</div>
            <div className="col-3">
              <label>{lang.t('admin:card.cvc')}*</label>
              <input type="number" value={this.state.valuesCard[4]} name="4" onChange={this.onChangeCard}/>
            </div>
            <div className="clear col-12">
              {this.renderErrorCards(2)}
              {this.renderErrorCards(3)}
              {this.renderErrorCards(4)}
            </div>
            <div className="clear"></div>
            <div className="sv-40"></div>
            <button className="btn primary" onClick={() => this.checkMakePayment()}>{lang.t('admin:button.paywithcard')}</button>
            <div className="sv-10"></div>
            <p className="small center secondary" style={{whiteSpace: 'pre-wrap'}}>{lang.t('admin:pay.savecard')}</p>
          </div>
        )
      } else {
        return (
          <div>
            <div className="sv-30"></div>
            <div className="paymethod mt-10 mb-30">
              <WidgetCard id={global.spaceClient}/>
              <p>{lang.t('admin:pay.savedcard')} ({global.cards[0].brand} {lang.t('admin:label.ending')} {global.cards[0].last4})</p>
            </div>
            <button className="btn tertiary add width-12" onClick={() => this.setState({card: true})}>{lang.t('admin:button.anothercard')}</button>
            <div className="sv-60"></div>
            <button className="btn primary" onClick={() => this.showOverlayConfirm()}>{lang.t('admin:button.paywithcard')}</button>
          </div>
        )
      }
      
    }
  }


  renderPriceLabel() {
    if(this.state.product.data.type === 'other') {
      return lang.t('admin:inv.price')
    } else {
      var label = lang.t('admin:pay.sessions')
      if(this.state.product.data.stype === 'group') {
        label = this.state.product.data.sessions+' '+lang.t('admin:pay.group')
        if(this.state.product.data.sessions !== 1) {
          label = this.state.product.data.sessions+' '+lang.t('admin:pay.groups')
        }
      } else if(this.state.product.data.stype === '11') {
        label = this.state.product.data.sessions+' '+lang.t('admin:pay.pt')
        if(this.state.product.data.sessions !== 1) {
          label = this.state.product.data.sessions+' '+lang.t('admin:pay.pts')
        }
      } else if(this.state.product.data.stype === 'both') {
        label = this.state.product.data.sessions+' '+lang.t('admin:pay.group')
        if(this.state.product.data.sessions !== 1) {
          label = this.state.product.data.sessions+' '+lang.t('admin:pay.groups')
        }
        if(this.state.product.data.sessions11 !== 1) {
          label += ' & '+this.state.product.data.sessions11+' '+lang.t('admin:pay.pts')
        } else {
          label += ' & '+this.state.product.data.sessions11+' '+lang.t('admin:pay.pt')
        }
      }
      return label
    }
  }


  renderPrice() {
    if(this.state.productId !== '' && this.state.product !== null) {
      return <InfoPrice id={this.state.productId} product={this.state.product} type={'payment'}/>
    }
  }


  renderLoading() {
    if(this.state.loading) {
      return (
        <div className="loading">
          <div className="loader-box">
            <span className="loader"><span className="loader-inner"></span></span>
          </div>
          <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('message:loading.stripe')}</p>
        </div>
      )
    }
  }


  renderFamily() {
    if(this.state.product !== null) {
      if(global.userLinked !== undefined && global.userLinked.length > 0 && this.state.product.data.type === 'sessions') {
        return (
          <div className="form-item">
            <label>{lang.t('admin:pay.family')}</label>
            <select onChange={event => this.selectFamily(event)} value={this.state.family}>
              <option value=''>{lang.t('admin:pay.allfamily')}</option>
              <option value={global.spaceClient} key={global.spaceClient}>{lang.t('common:label.yourself')}</option>
              {global.userLinked.map(item => (
                <option value={item} key={item}>{this.getFamilyName(item)}</option>
              ))}
            </select>
          </div>
        )
      }
    }
  }


  renderDesc() {
    var show = false
    if(this.state.productId !== '') {
      for(var item of global.products) {
        if(item.id === this.state.productId && item.data.desc !== undefined && item.data.desc !== '') {
          show = true
        }
      }
    }
    if(show) {
      return (
        <button className="btn tertiary small rgt" style={{fontSize:'11px'}} onClick={() => this.showOverlayDesc()}>{lang.t('common:button.viewdetails')}</button>
      )
    }
  }


  renderProductSelection() {
    if(this.state.preselectedProd && this.state.product !== null) {
      return (
        <div className="info">
          <label className="lft">{lang.t('admin:pay.product')}</label>
          <p className="clear">{this.state.product.data.name}</p>
        </div>
      )
    } else {
      return (
        <div className="form-item">
          <label className="lft">Product</label>{this.renderDesc()}
          <select onChange={event => this.selectProduct(event)} value={this.state.productId}>
            <option value={''}>- {lang.t('admin:pay.select')} -</option>
            {this.state.products.map(item => (
              <option value={item.id} key={item.id}>{item.data.name} ({global.curSym}{item.data.price.toFixed(2)})</option>
            ))}
          </select>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        
        <div className="content form-sidebar">
          <div className="col-6 mt-20">
            <div className="box">
              <div className="infobox primary mb-20">
                <p>{lang.t('admin:pay.info')}</p>
              </div>
              <div className="space-10"></div>
              {this.renderProductSelection()}
              {this.renderFamily()}
              <div className="space-20"></div>
              {this.renderPrice()}
              {this.renderBottom()}
            </div>
          </div>

          <div className="col-6 sidebar mt-20">
            <div className="image empty large mb-30">
              <div className="illustration">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmptyImg.renderImage('payments')}
              </div>
            </div>
            <p className="mb-30">{lang.t('admin:pay.sidebar')}</p>
          </div>
          <div className="sv-40 clear"></div>

          <div className="header">
            <div className="content">
              <Link to={'/'+global.uid+'/admin/payments'} className="back lft mr-10">
                <div className="circle"></div>
                <div className="arrow"></div>
              </Link>
              <h2>{this.state.preselectedProd ? lang.t('admin:pay.invoice') : lang.t('header:title.payment')}</h2>
            </div>
          </div>
          
        </div>
        <Navigation active='admin' />
        {this.renderOverlayConfirm()}
        {this.renderOverlayDesc()}
        <ModalMessageSimple type='nostock2' show={this.state.showModalStock} onHide={() => this.hideModals()} clickMainButton={() => this.hideModals()}/>
        {this.renderLoading()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['header','admin','common','modal','message'])(withRouter(withAuthorization(condition)(MemberPaymentPage)));