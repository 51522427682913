import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Moment from 'moment';
import 'moment/locale/es';
import Navigation from '../../components/Navigation';
import HelperBilling from '../../helper/Billing';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperEmptyImg from '../../helper/EmptyLarge';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      subscriptions: global.subscriptions,
      invoices: [],
      done: false,
      num: global.num,
      showOverlay: false,
      hidden: 'hidden',
      margin: 0,
      currentPayment: null,
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    if(id !== 'home' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.admin')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('paymentsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('invoicesLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    this.configureData()

  }
  

  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.admin')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    var tmp = []
    var tmp2 = []
    for(var item of global.payments) {
      if(item.data.client === global.spaceClient) {
        tmp.push(item)
      }
    }
    for(var item2 of global.invoices) {
      if(item2.data.client === global.spaceClient) {
        tmp2.push(item2)
      }
    }
    this.setState({
      payments: tmp,
      subscriptions: global.subscriptions,
      invoices: tmp2
    })
  }


  getBg() {
    var label = 'image'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'image bg'+this.state.num
    return label
  }


  getSubStatus(item) {
    var label = lang.t('admin:label.active')
    if(item.data.next !== undefined) {
      label = lang.t('admin:label.nextpayment')+': '+Moment(item.data.next, 'X').locale(lang.language).format('ddd, D MMM YYYY')
    }
    if(item.data.pause !== undefined && item.data.pause !== '') {
      label = lang.t('admin:label.paused')
    }
    if(item.data.status === 'unpaid' || item.data.status === 'past_due') {
      label = lang.t('admin:label.subissue')
    }
    return label
  }


  getSubSessions(item) {
    if(item.data.sessions !== undefined) {
      if(item.data.sessions > 0) {
        return ' ('+item.data.sessions+' sessions)'
      }
    }
  }



  // Payments overlay ------------------------------------------------------------



  showOverlayPayment(item) {
    this.setState({
      showOverlay: true,
      currentPayment: item
    })
    setTimeout(() => {
      this.setState({hidden: ''});
    }, 100);
  }


  hideOverlayPayment() {
    this.setState({hidden: 'hidden'})
    setTimeout(() => {
      this.setState({showOverlay: false});
    }, 500);
  }


  renderMethod() {
    if(this.state.currentPayment.data.fee !== undefined) {
      if(this.state.currentPayment.data.fee === 0) {
        return (
          <div className="lft">{lang.t('admin:label.other')}</div>
        )
      } else {
        return (
          <div className="lft">{this.state.currentPayment.data.type} {lang.t('admin:label.ending')} {this.state.currentPayment.data.last4}</div>
        )
      }
    } else {
      return (
        <div className="lft">{this.state.currentPayment.data.type} {lang.t('admin:label.ending')} {this.state.currentPayment.data.last4}</div>
      )
    }
  }


  renderOverlayButton() {
    if(global.spaceAllowHabits) {
      return (
        <a className="btn primary" href={'https://ptmate.app/billing/receipt/'+this.state.currentPayment.id} target="_blank" rel="noopener noreferrer">{lang.t('modal:payment.button')}</a>
      )
    } else {
      return (
        <a href={this.state.currentPayment.data.receipt} target="_blank" rel="noopener noreferrer" className="btn primary">{lang.t('modal:payment.button')}</a>
      )
    }
  }


  renderOverlayPayment() {
    if(this.state.showOverlay) {
      return (
        <div className={'overlay '+this.state.hidden}   >
          <div className="box">
            <h2 className="mb-20 lft">{lang.t('modal:payment.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayPayment()}>{lang.t('modal:common.close')}</button>
            <div className="info clear">
              <p className="label">{this.renderPaymentName(this.state.currentPayment)}</p>
              <p className="big">{HelperBilling.showAmount((this.state.currentPayment.data.amount/100))}</p>
            </div>
            <div className="table">
              <div className="label lft">{lang.t('modal:payment.date')}</div>
              <div className="lft">{this.renderPaymentDate(this.state.currentPayment)}</div>
              <div className="label lft clear">{lang.t('modal:payment.method')}</div>
              <div className="lft">{this.renderMethod()}</div>
              <div className="clear"></div>
            </div>
            <div className="sv-30"></div>
            {this.renderOverlayButton()}
          </div>
        </div>
      )
    }
  }



  // Display stuff ------------------------------------------------------------



  renderPaymentName(item) {
    var label = item.data.name
    if(item.data.subscription !== undefined && item.data.subscription !== '' && this.state.item !== null) {
      for(var sub of this.state.products) {
        if(sub.id === item.data.subscription) {
          label = sub.data.name
        }
      }
    }
    if(item.data.name.indexOf('Invoice ') !== -1 || item.data.name.indexOf('Subscription ') !== -1) {
      label = lang.t('admin:label.membership')
    }
    return label
  }


  renderPaymentDate(item) {
    var label = Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM YYYY')
    if(item.data.timestamp !== undefined) {
      label = Moment(item.data.timestamp, 'X').locale(lang.language).format('D MMM YYYY')
    }
    return label
  }


  renderCardAvatar(item) {
    if(item.data.status === 'unpaid' || item.data.status === 'past_due') {
      return (
        <div className="icon red">
          <div className="inner card"></div>
        </div>
      )
    } else {
      return (
        <div className="icon secondary">
          <div className="inner card"></div>
        </div>
      )
    }
  }


  renderDebitFamily(item) {
    if(global.userLinked !== undefined && global.userLinked.length > 0) {
      var label = lang.t('admin:pay.allfamily')
      for(var cl of global.userLinked) {
        if(cl === item.data.account) {
          for(var cli of global.clients) {
            if(cli.id === cl) {
              label = cli.data.name
            }
          }
        }
      }
      return <span><br/>{label}</span>
    }
  }


  renderDebitInfo(item) {
    if(item.id === 'trial') {
      return (
        <div>
          <div className="col-6">
            {this.renderCardAvatar(item)}
            <p><strong>{item.data.name}</strong>{this.renderDebitFamily(item)}</p>
          </div>
          <div className="col-6">
            <p>{lang.t('admin:label.ends')} {Moment(item.data.next, 'X').locale(lang.language).format('dddd, D MMM YYYY')}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="col-6">
            {this.renderCardAvatar(item)}
            <p><strong>{item.data.name}</strong>{this.renderDebitFamily(item)}</p>
          </div>
          <div className="col-3">
            <p>{HelperBilling.showAmount((item.data.price/100))} {HelperBilling.getCycle(item)}</p>
          </div>
          <div className="col-3">
            <p>{this.getSubStatus(item)}</p>
          </div>
        </div>
      )
    }
  }


  renderInvoiceStatus(item) {
    if(item.data.status === 'paid') {
      return (
        <span className="pill green">{lang.t('admin:label.paid')}</span>
      )
    } else if(item.data.status === 'paid') {
      return (
        <span className="pill">{lang.t('admin:label.void')}</span>
      )
    } else if(item.data.status === 'open' && Moment(item.data.due, 'X') > Moment().add(-1, 'day')) {
      return (
        <span className="pill yellow">{lang.t('admin:label.open')}</span>
      )
    } else if(item.data.status === 'open' && Moment(item.data.due, 'X') < Moment().add(-1, 'day')) {
      return (
        <span className="pill red">{lang.t('admin:label.unpaid')}</span>
      )
    }
  }


  renderInvoices() {
    if(this.state.invoices.length > 0) {
      var list = this.state.invoices
      list.sort((a,b) => Moment(b.data.date, 'X') - Moment(a.data.date, 'X'))
      return (
        <div>
          <div className="listheader">
            <h3>{lang.t('header:admin.invoicehistory')}</h3>
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id}>
              <Link to={'/'+global.uid+'/admin/invoice/'+item.id}>
                <div className="col-4">
                  <div className="icon secondary">
                    <div className="inner invoice"></div>
                  </div>
                  <p><strong>{lang.t('admin:label.invoice')} {item.data.number}</strong></p>
                </div>
                <div className="col-3">
                  <p>{HelperBilling.showAmount(item.data.price)}</p>
                </div>
                <div className="col-3">
                  <p>{Moment(item.data.date, 'X').locale(lang.language).format('D MMM YYYY')} {this.renderInvoiceStatus(item)}</p>
                </div>
                <div className="col-2">
                  <button className="btn tertiary small rgt">{lang.t('common:button.viewdetails')}</button>
                </div>
              </Link>
            </div>
          ))}
          <div className="sv-30"></div>
        </div>
      )
    }
  }


  renderDebits() {
    if(this.state.subscriptions.length > 0) {
      var list = this.state.subscriptions
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          <div className="listheader">
            <h3>{lang.t('admin:label.yourmembership')}</h3>
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <div className="box list member highlight" key={item.id}>
              {this.renderDebitInfo(item)}
              <div className="clear"></div>
            </div>
          ))}
          <div className="sv-30"></div>
        </div>
      )
    }
  }


  renderPayments() {
    if(this.state.payments.length === 0) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1"></div>
            <div className="img2"></div>
            <div className="img3"></div>
            <div className="img4"></div>
            {HelperEmptyImg.renderImage('payments')}
          </div>
          <div className="data">
            <h3 className="mb-20">{lang.t('empty:payments.title')}</h3>
            <p>{lang.t('empty:payments.text')}</p>
          </div>
        </div>
      )
    } else {
      var list = this.state.payments
      list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
      return (
        <div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id} onClick={() => this.showOverlayPayment(item)}>
              <div className="col-4">
                <div className="icon secondary">
                  <div className="inner card"></div>
                </div>
                <p><strong>{this.renderPaymentName(item)}</strong></p>
              </div>
              <div className="col-3">
                <p>{HelperBilling.showAmount((item.data.amount/100))}</p>
              </div>
              <div className="col-3">
                <p>{this.renderPaymentDate(item)}</p>
              </div>
              <div className="col-2">
                <button className="btn tertiary small rgt">{lang.t('common:button.viewdetails')}</button>
              </div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderPayment() {
    if(global.userStripeConnect !== '' && global.products.length > 0) {
      return (
        <Link to={'/'+global.uid+'/admin/payment'} className="tab">
          <p>{lang.t('nav:admin.payment')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }


  renderNavMembership() {
    var show = false
    for(var item of global.products) {
      if(item.data.type === 'subscription') {
        show = true
      }
    }
    if(show) {
      return (
        <Link to={'/'+global.uid+'/admin/newmembership'} className="tab">
          <p>{lang.t('nav:admin.membership')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content member">
          <div className="member-header mb-10">
            <div className={this.getBg()}>
              <div className={'gradient theme-'+global.spaceTheme}></div>
            </div>
            <div className="member-main">
              <div className="business">
                <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
                <h3>{global.userBusiness}<br/><span>{lang.t('header:title.memberarea')}</span></h3>
              </div>
              <h2 className="mb-30">{lang.t('header:title.admin')}</h2>
              <Link to={'/'+global.uid+'/admin/forms'} className="tab">
                <p>{lang.t('nav:admin.forms')}</p>
                <div className="bg"></div>
              </Link>
              {this.renderPayment()}
              {this.renderNavMembership()}
              <Link to={'/'+global.uid+'/admin/payments'} className="tab active">
                <p>{lang.t('nav:admin.history')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/'+global.uid+'/admin/card'} className="tab">
                <p>{lang.t('nav:admin.card')}</p>
                <div className="bg"></div>
              </Link>
            </div>
            <div className="clear"></div>
          </div>
          {this.renderDebits()}
          <div className="listheader">
            <h3>{lang.t('header:admin.paymenthistory')}</h3>
            <div className="clear"></div>
          </div>
          {this.renderPayments()}
          {this.renderInvoices()}
          <div className="sv-40"></div>
        </div>
        <Navigation active='admin' />
        {this.renderOverlayPayment()}
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['nav','header','empty','admin','common','modal'])(withRouter(withAuthorizationMember(condition)(MemberHomePage)));