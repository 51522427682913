import React, { Component } from 'react';
import { ResponsiveLine } from 'nivo';



class WidgetChartGraph extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      curve: props.curve ?? 'cardinal',
      data: props.data ?? [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      axis: props.axis === '' ? null : {
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Dates',
        legendOffset: 36,
        legendPosition: 'center'
      },
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      curve: props.curve ?? 'cardinal',
      data: props.data ?? [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      axis: props.axis === '' ? null : {
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Dates',
        legendOffset: 36,
        legendPosition: 'center'
      },
    }
  }


  renderLight() {
    return (
      <ResponsiveLine
        data={this.state.data}
        margin={{ top: 5, right: 20, bottom: 25, left: 28 }}
        xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day', }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        axisTop={null}
        curve={this.state.curve}
        axisRight={null}
        colorBy={d => d.color}
        enableGridX={false}
        enableGridY={true}
        axisBottom={this.state.axis}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: -40,
          legendPosition: 'center'
        }}
        colors={{ scheme: 'nivo' }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        theme={{
          tooltip: {
            container: {
              background: "#ffffff",
              fontSize: 12
            },
          },
        }}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    )
  }


  renderDark() {
    return (
      <ResponsiveLine
        data={this.state.data}
        margin={{ top: 5, right: 20, bottom: 25, left: 28 }}
        xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day', }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        axisTop={null}
        curve={this.state.curve}
        axisRight={null}
        colorBy={d => d.color}
        enableGridX={false}
        //enableGridY={true}
        enableGridY={false}
        axisBottom={this.state.axis}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: -40,
          legendPosition: 'center'
        }}
        //colors={{ scheme: 'nivo' }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        theme={{
          annotations: {
            text: {
              color: '#ff0000',
              fontSize: 11
            },
          },
          "axis": {
            domain: {
              line: {
                stroke: '#ff0000',
              },
            },
            legend: {
              text: {
                color: '#ff0000',
              },
            },
            "ticks": {
              line: {
                  stroke: "#ffffff",
                  strokeWidth: 1,
              },
              "text": {
                  "fill": "#ff0000",
              }
            }
          },
          "grid": {
            "line": {
                "stroke": "#ff0000",
                "strokeWidth": "1",
            }
            
          },
          tooltip: {
            container: {
              background: "#000011",
              fontSize: 12
            },
          },
        }}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    )
  }


  render() {
    return (
      <div className="chart">
        <div className="show-light chart">
          {this.renderLight()}
        </div>
        <div className="show-dark chart solid">
          {this.renderDark()}
        </div>
      </div>
    )
  }
}


export default WidgetChartGraph;