import React, { Component } from 'react';
import Cal from '../../helper/Calendar';



class ModalBlock extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      hidden: 'hidden',
      block: props.block,
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      show: props.show,
      block: props.block,
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      this.setState({
        hidden: '',
      });
    }, 100);
  }


  hide() {
    this.setState({
      hidden: 'hidden',
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }



  getBlockTime() {
    var block = this.state.block
    var ex = []
    for(var i=0; i<block.data.exName.length; i++) {
      var min1 = parseInt(block.data.exWork[i]/60)
      var min2 = parseInt(block.data.exRest[i]/60)
      var rps = block.data.exReps[i]
      if(block.data.exRepsRounds !== undefined && block.data.exRepsRounds.length > i) {
        rps = block.data.exRepsRounds[i]
      }
      ex.push({
        id: block.data.exId[i],
        name: block.data.exName[i],
        tool: block.data.exTool[i],
        image: block.data.exImage[i],
        cat: block.data.exCat[i],
        type: block.data.exType[i],
        reps: rps,
        wmin: min1,
        wsec: block.data.exWork[i]-(min1*60),
        rmin: min2,
        rsec: block.data.exRest[i]-(min2*60),
      })
    }
    var label = Cal.getDurationMin(Cal.calcBlockTime(ex, block.data.type, block.data.rounds, block.data.emom, block.data.exWork[0], block.data.exRest[0]))
    return label
  }


  renderInterval(index) {
    var item = this.state.block.data
    if((item.type === 1 || item.type === 2) && !item.emom) {
      //label = '('+Cal.getDurationMin(item.exWork[index])+' - '+Cal.getDurationMin(item.exRest[index])+')'
      return <p style={{fontSize: 12}}>{Cal.getDurationMin(item.exWork[index])}</p>
    }
  }


  renderReps(index) {
    var item = this.state.block.data
    var label = item.exReps[index]
    if(item.exRepsRounds !== undefined && item.exRepsRounds !== '') {
      if(item.exRepsRounds.length > index) {
        if(item.exRepsRounds[index] !== '') {
          label = item.exRepsRounds[index].replaceAll('-', ' - ')
        }
      }
    }
    if(item.exUnits !== undefined) {
      if(item.exUnits.length > index) {
        if(item.exUnits[index] === 'dist') {
          label += 'm'
        }
        if(item.exUnits[index] === 'cals') {
          label += 'cal'
        }
        if(item.exUnits[index] === 'time') {
          label += 's'
        }
      }
    } else {
      if(item.exTool[index] === 6 || item.exTool[index] === 7) {
        label += 'm'
      }
      if(item.exTool[index] === 27) {
        label += 'cal'
      }
    }
    if(item.exReps[index] === 0) {
      label = ''
    }
    return label
  }


  renderWeight(index) {
    var item = this.state.block.data
    if(item.exWeight[index] !== 0) {
      var label = 'at '+item.exWeight[index]+'% of 1RM'
      if(item.exWeightRounds !== undefined && item.exWeightRounds !== '') {
        label = 'at '+item.exWeightRounds[index].replaceAll('-', ' - ')+'% of 1RM'
      }
      if(item.exWeightType !== undefined) {
        if(item.exWeightType.length > index && (item.exWeightType[index] === 'kg' || item.exWeightType[index] === 'lb')) {
          label = 'with '+item.exWeight[index]+' '+item.exWeightType[index]
          if(item.exWeightRounds !== undefined && item.exWeightRounds !== '') {
            label = 'with '+item.exWeightRounds[index].replaceAll('-', ' - ')+' '+item.exWeightType[index]
          }
        }
      }
      if(label !== '') {
        return label
      }
    }
  }


  renderNotes(index) {
    var item = this.state.block.data
    if(item.exNotes !== undefined) {
      if(item.exNotes.length > index) {
        if(item.exNotes[index] !== '') {
          return (
            <p style={{fontSize: 12}}>Notes: {item.exNotes[index]}</p>
          )
        }
      }
    }
  }


  getSetsNameStatic(item) {
    var label = 'set' 
    if(item.exId.length > 1) {
      var prev = ''
      var multiple = true
      for(var ex of item.exId) {
        if(ex !== prev && prev !== '') {
          label = 'superset'
          multiple = false
        }
        if(ex === prev && prev !== '') {
          label = 'dropset'
        }
        prev = ex
      }
      if(multiple) {
        label = 'dropset'
      } else {
        label = 'superset'
      }
    }
    return label
  }


  getBlockType(item) {
    var label = ''
    var s = ''
    if(item.rounds > 1) {
      s = 's'
    }
    if(item.type === 0) {
      label = ' - '+Cal.getDurationMin(item.rounds)
    }
    if(item.type === 1) {
      label = ' - '+item.rounds+' round'+s
      if(item.emom) {
        label = ' - '+item.rounds+' round'+s+' of '+Cal.getDurationMin(parseInt(item.exWork[0]))
      }
    }
    if(item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' rounds'
      if(item.rounds === 1) {
        label = ' - 1 round'
      }
      if(item.type === 2 && item.emom) { 
        if(item.exRest[0] === 0) {
          label += ' of '+Cal.getDurationMin(parseInt(item.exWork[0]))+' work & no rest'
        } else {
          label += ' of '+Cal.getDurationMin(parseInt(item.exWork[0]))+' work & '+Cal.getDurationMin(parseInt(item.exRest[0]))+' rest'
        }
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' '+this.getSetsNameStatic(item)+'s'
      if(item.rounds === 1) {
        label = ' - 1 '+this.getSetsNameStatic(item)
      }
    }
    if(item.type === 5) {
      label = ' - '+Cal.getDurationMin(item.rounds)
      if(item.cycles > 1) {
        label = ' - '+item.cycles+' rounds - Cap: '+Cal.getDurationMin(item.rounds)
      }
    }
    return label
  }


  renderBlockNotes() {
    if(this.state.block.data.notes !== undefined && this.state.block.data.notes !== '') {
      return (
        <div className="list bare static">
          <div className="icon quarternary">
            <div className="inner note"></div>
          </div>
          <div className="main">
            <h4 style={{marginBottom: 3}}>Notes</h4>
            <p style={{fontSize: 12}}>{this.state.block.data.notes}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderExerciseRest(index) {
    if(this.state.block.data.type === 2 && !this.state.block.data.emom && this.state.block.data.exRest[index] !== 0 && this.state.block.data.exRest[index] !== undefined && this.state.block.data.exRest[index] !== '') {
      return (
        <div className="list bare static">
          <div className="icon secondary">
            <div className="inner prog-rest"></div>
          </div>
          <div className="main">
            <h4 style={{marginBottom: 3}}>Rest</h4>
            <p style={{fontSize: 12}}>{Cal.getDurationMin(parseInt(this.state.block.data.exRest[index]))}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
  }


  render() {
    if(this.state.show) {
      var list = this.state.block.data.exName
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box clients">
            <h2 className="mb-10 lft">Program Block</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>Close</button>
            <h3 className="clear mb-20">{global.proTypes[this.state.block.data.type]} {this.getBlockType(this.state.block.data)}</h3>
            <div className="scroll">
              {list.map((item, index) => (
                <div key={index}>
                  <div className="list bare static">
                    <div className="icon primary">
                      <div className={'inner '+global.exCatsIcons[this.state.block.data.cat]}></div>
                    </div>
                    <div className="main">
                      <h4 style={{marginBottom: 3}}>{this.renderReps(index)} {item}</h4>
                      <p style={{fontSize: 12}}>{this.renderWeight(index)}</p>
                      {this.renderInterval(index)}
                      {this.renderNotes(index)}
                    </div>
                    <div className="clear"></div>
                  </div>
                  {this.renderExerciseRest(index)}
                </div>
              ))}
              {this.renderBlockNotes()}
            </div>
            <p className="center pt-20" style={{opacity: .5}}>This block will take about {this.getBlockTime()}</p>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default ModalBlock;