import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import Connector from '../../data/Connector';
import HelperPro from '../../helper/Programming';
import Resizer from "react-image-file-resizer";
import Navigation from '../../components/Navigation';
import { Link, withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import EventEmitter from '../../helper/Emitter';
import { gql } from "@apollo/client";
import { AuthUserContext, withAuthorization } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberNewAsessmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: global.spaceClient,
      values: ['', '', '', '', '', '', '', '', '', '', '', ''],
      valuesBlood: ['',''],
      valueCustom: '',
      file: null,
      fileDisplay: null,
      back: '',
      item: null,
      file2: null,
      fileDisplay2: null,
      file3: null,
      fileDisplay3: null,
      file4: null,
      fileDisplay4: null,
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    if(id !== 'health' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:title.newhealthlog')+' - '+global.userBusiness
    window.scrollTo(0, 0)

    this.setState({
      done: true
    })

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
    EventEmitter.subscribe('userLoaded', (event) => this.checkSpace())
    EventEmitter.subscribe('clientLoaded', (event) => this.configureData())
    this.configureData()
  }


  checkSpace() {
    if(global.uid === '') {
      this.props.history.push(ROUTES.LOADING)
    } else {
      var found = false
      for(var item of global.userStaff) {
        if(item.id === global.uid) {
          found = true
        }
      }
      if(found) {
        document.title = lang.t('header:title.newhealthlog')+' - '+global.userBusiness
        this.configureData()
      } else {
        this.props.history.push(ROUTES.LOADING)
      }
    }
  }


  configureData() {
    this.setState({
      healthlog: global.healthlog,
    })
  }



  // Client update stuff ------------------------------------------------------------



  onChange = event => {
    var tmp = this.state.values
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({values: tmp});
  };


  onChangeBlood = event => {
    var tmp = this.state.valuesBlood
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({valuesBlood: tmp});
  };


  onChangeCustom = event => {
    this.setState({valueCustom:  event.target.value});
  };


  setImage(file, image) {
    if(image === '1') {
      this.setState({
        file: file,
        fileDisplay:  URL.createObjectURL(file)
      })
    } else if(image === '2') {
      this.setState({
        file2: file,
        fileDisplay2:  URL.createObjectURL(file)
      })
    } else if(image === '3') {
      this.setState({
        file3: file,
        fileDisplay3:  URL.createObjectURL(file)
      })
    } else if(image === '4') {
      this.setState({
        file4: file,
        fileDisplay4:  URL.createObjectURL(file)
      })
    }
  }


  displayImage(file, display) {
    if(file === null) {
      return (
        <div className="form-image"></div>
      )
    } else {
      return (
        <div className="form-image" style={{backgroundImage: 'url('+display+')'}}> </div>
      )
    }
  }


  createAssessment() {
    var tmp = []
    var num = 0
    for(var item of this.state.values) {
      if(item === '') {
        tmp.push('0')
      } else {
        if(global.userLbs && num > 2 && num !== 11) {
          tmp.push((item*2.54).toFixed(1))
        } else {
          tmp.push(item)
        }
      }
      num++
    }

    var weight = tmp[0]
    if(global.userLbs) {
      weight = (tmp[0]*global.lbsDown).toFixed(2)
    }

    // DS CONNTECTION HERE
    tmp[2] = this.state.values[2]
    var obj = Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient+'/assessments').push()
    obj.set({
      weight: parseFloat(weight),
      fat: parseFloat(tmp[1]),
      notes: tmp[2],
      neck: parseFloat(tmp[3]),
      chest: parseFloat(tmp[4]),
      abdomen: parseFloat(tmp[5]),
      hip: parseFloat(tmp[6]),
      armR: parseFloat(tmp[7]),
      armL: parseFloat(tmp[8]),
      thighR: parseFloat(tmp[9]),
      thighL: parseFloat(tmp[10]),
      date: Moment().format('DD/MM/YYYY HH:mm'),
      image: '',
      uid: global.uid,
      heart: parseFloat(tmp[11]),
      nutrition: '',
      blood1: this.state.valuesBlood[0],
      blood2: this.state.valuesBlood[1],
      custom: this.state.valueCustom
    }).then(() => {
      global.message = lang.t('message:logcreated')
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    if(this.state.file !== null) {
      this.uploadImage(this.state.file, obj.key, 'image', obj.key)
    }
    if(this.state.file2 !== null) {
      this.uploadImage(this.state.file2, obj.key+'-2', 'image2', obj.key)
    }
    if(this.state.file3 !== null) {
      this.uploadImage(this.state.file3, obj.key+'-3', 'image3', obj.key)
    }
    if(this.state.file4 !== null) {
      this.uploadImage(this.state.file4, obj.key+'-4', 'image4', obj.key)
    }
    if(global.userNutritionId === undefined || global.userNutritionId === "" || tmp[0] === '0') {
      global.message = lang.t('message:logcreated')
      this.props.history.push('/'+global.uid+'/health/log')
    } else {
      this.createNutrition(obj.key, parseFloat(HelperPro.calculateWeight(tmp[0])))
      this.createUserWeight(parseFloat(weight))
    }
  }


  uploadImage(file, name, img, key) {
    const sr = Firebase.storage().ref()
      const fr = sr.child('images/assessments/'+global.uid+'/'+name+'.jpg')
      Resizer.imageFileResizer(
        file,
        800,
        800,
        "JPEG",
        60,
        0,
        (uri) => {
          var ar = uri.split(',')
          fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
            Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient+'/assessments/'+key).update({
              [img]: 'images/assessments/'+global.uid+'/'+name+'.jpg',
            })
          }).catch((error)=>{
            EventEmitter.dispatch('showMessageError', error.message);
          })
        },
        "base64",
        200,
        200
      );
  }


  createNutrition(key, weight) {

    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation createWeight($date: date!, $time: timestamp!, $weight: float8, $id: Int) {
        insert_weights_one(object: {created_at: $time, date_recorded: $date, updated_at: $time, u_weight: $weight, user_id: $id, weight: $weight}) {
          id
        }
      }
    `;

    client.mutate({
      variables: { id: parseInt(global.userNutritionId), date: Moment().format('YYYY-MM-DD'), time: Moment().format('YYYY-MM-DDTHH:mm:ss'), weight: weight },
      mutation
    }).then((result) => {
      Firebase.database().ref('/clients/'+global.uid+'/'+global.currentClient+'/assessments/'+key).update({
        nutrition: String(result.data.insert_weights_one.id)
      })
      this.props.history.push('/'+global.uid+'/health/log')
    }).catch((error) => {
      console.log(error);
    });
  }


  createUserWeight(weight) {
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation updateUserWeight($id: Int!, $weight: float8) {
        update_users_by_pk(pk_columns: {id: $id},
          _set: {
              current_weight: $weight,
              u_current_weight: $weight,
          })
        {
          id
          current_weight
        }
      }
    `;

    client.mutate({
      variables: { id: parseInt(global.userNutritionId), weight: weight },
      mutation
    }).then((result) => {
      //console.log(result)
      console.log('done')
    }).catch((error) => {
      console.log(error);
    });
  }



  // Display stuff ------------------------------------------------------------



  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-sidebar">
          <div className="col-6 mt-20">
            <h3 className="mb-20">{lang.t('header:health.images')}</h3>
            <div className="col-6 clear">
              <div className="image healthlog edit mb-10">
                {this.displayImage(this.state.file, this.state.fileDisplay)}
              </div>
              <div>
                <label>{lang.t('health:label.front')}</label>
                <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], '1');}}/>
              </div>
            </div>
            <div className="col-6 mb-20">
              <div className="image healthlog edit mb-10">
                {this.displayImage(this.state.file2, this.state.fileDisplay2)}
              </div>
              <div>
                <label>{lang.t('health:label.back')}</label>
                <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], '2');}}/>
              </div>
            </div>
            <div className="col-6 clear mb-20">
              <div className="image healthlog edit mb-10">
                {this.displayImage(this.state.file3, this.state.fileDisplay3)}
              </div>
              <div>
                <label>{lang.t('health:label.left')}</label>
                <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], '3');}}/>
              </div>
            </div>
            <div className="col-6 mb-20">
              <div className="image healthlog edit mb-10">
                {this.displayImage(this.state.file4, this.state.fileDisplay4)}
              </div>
              <div>
                <label>{lang.t('health:label.right')}</label>
                <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], '4');}}/>
              </div>
            </div>
          </div>

          <div className="col-6 mt-20 pl-30">
            <h3 className="mb-20">{lang.t('health:log.overview')}</h3>
            <label className="clear">{lang.t('health:log.weight')} ({global.spaceLbs ? 'lb' : 'kg'})</label>
            <input type="number" placeholder={'Weight in '+(global.spaceLbs ? 'lb' : 'kg')} value={this.state.values[0]} name="0" onChange={this.onChange}/>
            <div className="sv-20"></div>
            <label>{lang.t('health:log.bodyfat')} (%)</label>
            <input type="number" value={this.state.values[1]} name="1" onChange={this.onChange}/>
            <div className="sv-20"></div>
            <label>{lang.t('health:log.heartrate')} (bpm)</label>
            <input type="number" value={this.state.values[11]} name="11" onChange={this.onChange}/>
            <div className="sv-20"></div>
            <label>{lang.t('health:log.bloodpressure')}</label>
            <div style={{width: '50%', float: 'left', padding: '0 5px 0 0'}}>
              <input type="number" placeholder="Systolic" value={this.state.valuesBlood[0]} name="0" onChange={this.onChangeBlood}/>
            </div>
            <div style={{width: '50%', float: 'left', padding: '0 0 0 5px'}}>
              <input type="number" placeholder="Diastolic" value={this.state.valuesBlood[1]} name="1" onChange={this.onChangeBlood}/>
            </div>
            <div className="clear sv-20"></div>
            <label>{lang.t('health:log.custom')}</label>
            <input type="number" placeholder="Enter any additional value to track" value={this.state.valueCustom} onChange={this.onChangeCustom}/>
            <div className="sv-20"></div>
            <label>{lang.t('health:log.notes')}</label>
            <textarea value={this.state.values[2]} name="2" onChange={this.onChange}></textarea>

            <div className="healthlog segmental mt-30">
              <h3 className="mb-20">{lang.t('header:health.segmental')}</h3>
              <div className="clear info">
                <div className="circle lft mr-10">1</div>
                <div className="main lft">
                  <label>{lang.t('health:log.neck')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[3]} name="3" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear info">
                <div className="circle lft mr-10">2</div>
                <div className="main lft">
                  <label>{lang.t('health:log.chest')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[4]} name="4" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear info">
                <div className="circle lft mr-10">3</div>
                <div className="main lft">
                  <label>{lang.t('health:log.abdomen')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[5]} name="5" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear info">
                <div className="circle lft mr-10">4</div>
                <div className="main lft">
                  <label>{lang.t('health:log.hip')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[6]} name="6" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear info">
                <div className="circle lft mr-10">5</div>
                <div className="main lft">
                  <label>{lang.t('health:log.rightarm')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[7]} name="7" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear info">
                <div className="circle lft mr-10">6</div>
                <div className="main lft">
                  <label>{lang.t('health:log.leftarm')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[8]} name="8" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear info">
                <div className="circle lft mr-10">7</div>
                <div className="main lft">
                  <label>{lang.t('health:log.righthigh')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[9]} name="9" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
              <div className="clear info">
                <div className="circle lft mr-10">8</div>
                <div className="main lft">
                  <label>{lang.t('health:log.leftthigh')} ({global.spaceLbs ? 'in' : 'cm'})</label>
                  <input type="number" value={this.state.values[10]} name="10" onChange={this.onChange}/>
                </div>
                <div className="clear"></div>
              </div>
            </div>
            
            <div className="box healthlog form none">
              <h3>{lang.t('health:log.overview')}</h3>
              <label className="clear">{lang.t('health:log.weight')} ({global.userLbs ? 'lb' : 'kg'})</label>
              <input type="text" placeholder={'Weight in '+(global.userLbs ? 'lb' : 'kg')} value={this.state.values[0]} name="0" onChange={this.onChange}/>
              <label>{lang.t('health:log.bodyfat')} (%)</label>
              <input type="number" value={this.state.values[1]} name="1" onChange={this.onChange}/>
              <label>{lang.t('health:log.heartrate')} (bpm)</label>
              <input type="number" value={this.state.values[11]} name="11" onChange={this.onChange}/>
              <label>{lang.t('health:log.notes')}</label>
              <textarea value={this.state.values[2]} name="2" onChange={this.onChange}></textarea>
            </div>
          </div>
          <div className="clear sv-40"></div>
          <div className="width-4">
            <button className="btn primary edit" onClick={() => this.createAssessment()}>{lang.t('health:button.createlog')}</button>
          </div>
          <div className="clear sv-40"></div>
        </div>

        <div className="header">
          <div className="content">
            <Link to={'/'+global.uid+'/health/log'} className="back lft mr-10">
              <div className="circle"></div>
              <div className="arrow"></div>
            </Link>
            <h2>{lang.t('header:title.newhealthlog')}</h2>
          </div>
        </div>
        <Navigation active='health' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['header','health','common','message'])(withRouter(withAuthorization(condition)(MemberNewAsessmentPage)));