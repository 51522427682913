import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import Resizer from "react-image-file-resizer";
import Connector from '../../data/Connector';

import * as ROUTES from '../../constants/routes';
import EventEmitter from '../../helper/Emitter';
import Message from '../../components/Message';
import InputRadio from '../../components/Form/radio';
import ModalAvatar from '../../components/Modals/avatar';
import Moment from 'moment';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberSignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'form',
      email: '',
      password: '',
      error: [false, false, false],
      checked: false,

      name: '',
      phone: '',
      birth: '',
      code: '',
      error2: [false, false, false, false],
      errora: [false, false, false, false, false],
      file: null,
      fileDisplay: null,
      avatar: '',
      imageType: 'avatar',
      allowClick: true,
      move1: '',
      move2: 'in',
      move3: 'in',
      move11: 'in',

      num: global.num,
      done: false,
      logo: '',
      sname: 'PT Mate',
      link: ROUTES.LOGIN,
      link2: ROUTES.CONFIRM,
      ectype: 99,
      ecname: '',
      ecphone: '',
      showOverlayTrainer: false,
      hiddenTrainer: 'hidden',
      showOverlayDuplicate: false,
      hiddenDuplicate: 'hidden',
      margin: 0,
      ofage: true,
      unauth: false,
      country: 'au',
      lbs: false,
      showModalAvatar: false,
    };
  }


  componentDidMount() {
    var title = 'Member Area'
    setTimeout(() => {
      if(lang.language.indexOf('es') !== -1) {
        title = 'Área de Miembros'
        lang.language = 'es'
      }
      document.title = title+' - PT Mate'
      if(global.userBusiness !== '') {
        document.title = title+' - '+global.userBusiness
      }
    }, 300);
    //Firebase.auth().signOut()

    var tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    var ctry = 'us'
    var lbs = true
    if(tz.indexOf('Australia') !== -1) {
      ctry = 'au'
      lbs = false
    }
    this.setState({
      country: ctry,
      lbs: lbs
    })

    global.uid = ''
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-2]
    console.log(id)
    if(id !== 'ptmate.me' && id !== 'www.ptmate.me' && id !== '') {
      global.uid = id
      Connector.loadPublic()
      this.setState({
        link: '/'+id+'/login',
        link2: '/'+id+'/confirm'
      })
    }
    if(arr[arr.length-1] === 'complete-signup') {
      Connector.loadConnect((result) => {})
      if(global.uid !== '') {
        Connector.loadClients((result) => {})
        Connector.loadForms((result) => {})
      }
      this.setState({
        type: 'form2',
        allowClick: true,
        move1: 'out',
        move2: ''
      })
    }

    setTimeout(() => {
      if(Firebase.auth().currentUser !== null && this.props.location.pathname.indexOf('complete-signup') === -1) {
        if(Firebase.auth().displayName === 'client') {
          if(global.uid === '') {
            this.props.history.push('/complete-signup')
          } else {
            if(Firebase.auth().currentUser.uid === 'iJam7aX6gSYjd92D3NSbcKacCbv2') {
              Firebase.auth().signOut()
            } else {
              Connector.checkSpaceExists(global.uid)
              EventEmitter.subscribe('spaceChecked', (event) => {
                if(event === 'no') {
                  global.uid = ''
                  this.props.history.push('/complete-signup')
                } else {
                  this.props.history.push('/'+global.uid+'/complete-signup')
                }
              })
            }
          }
        } else {
          if(Firebase.auth().currentUser.uid === 'iJam7aX6gSYjd92D3NSbcKacCbv2') {
            Firebase.auth().signOut()
          } else {
            global.portalChange = true
            Firebase.auth().signOut()
            window.location = 'https:/ptmate.app'
          }
        }
        
        
      }
    }, 1000);

    EventEmitter.subscribe('publicLoaded', (event) => {
      this.setAppearance()
    })

    EventEmitter.subscribe('connectLoaded', (event) => {
      this.checkConnect()
    })

    EventEmitter.subscribe('changePortal', (event) => {
      this.showOverlayTrainer()
    })

    var rand = parseInt(Math.random()*global.avatars.length)
    this.setState({
      avatar: global.avatars[rand]
    })
  }


  setAppearance() {
    var tmp = 'PT Mate'
    if(global.userBusiness !== '') {
      tmp = global.userBusiness
      document.title = lang.t('header:title.memberarea')+' - '+global.userBusiness
    }
    this.setState({
      logo: global.spaceImage,
      sname: tmp
    })
  }


  checkConnect() {
    var remove = ''
    for(var item of global.connect) {
      if(item.data.email === Firebase.auth().currentUser.email) {
        let itemsRef = Firebase.database().ref('/clients/'+item.data.space+'/'+item.data.client);
        itemsRef.on("value", function(snapshot) {
          if (snapshot.exists()) {
            var data = snapshot.val();
            if(data.deleted !== undefined) {
              remove = item.id
            }
          }
        });
      }
    }
    if(remove !== '') {
      for(var i=0; i<global.connect.length; i++) {
        if(global.connect[i].id === remove) {
          Firebase.database().ref('/connect/'+remove).remove()
          global.connect.splice(i, 1)
        }
      }
    }
  }



  // Controls ------------------------------------------------------------



  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  onChangeBirth = event => { 
    var valid = Moment(event.target.value, 'YYYY-MM-DD').isValid()
    if(valid) {
      var age = true
      if( Moment(event.target.value, 'YYYY-MM-DD').isAfter(Moment().add(-18, 'years'))) {
        age = false
      }
      this.setState({
        birth: Moment(event.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        ofage: age
      });
    }
  };


  onChange2 = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  onChange3 = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  toggleChange = () => {
    this.setState({
      checked: !this.state.checked,
    });
  }


  createUser() {
    if(this.state.allowClick) {
      var tmp = [false, false, false]
      this.setState({
        error: [false, false, false],
        allowClick: false,
        //move1: 'out'
      })
      if(this.state.email !== '' && this.state.password !== '' && this.state.checked) {
        this.setState({
          error: [false, false, false],
          allowClick: false,
          //move1: 'out'
        })

        Firebase.auth().createUserWithEmailAndPassword(
          this.state.email,
          this.state.password
        ).then(authUser => {
          //this.createEntry()
          this.setState({
            move1: 'out'
          })
          setTimeout(() => {
            this.createEntry()
          }, 250);
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
          this.setState({
            allowClick: true,
          })
        })
        
      } else {
        if(this.state.email === '') {
          tmp[0] = true
        }
        if(this.state.password === '') {
          tmp[1] = true
        }
        if(!this.state.checked) {
          tmp[2] = true
        }
        this.setState({
          error: tmp,
          allowClick: true
        })
      }
    }
  }


  createEntry() {
    global.uidUser = Firebase.auth().currentUser.uid;
    Connector.loadConnect((result) => {})
    if(global.uid !== '') {
      Connector.loadClients((result) => {})
      Connector.loadForms((result) => {})
      Connector.checkSpaceExists(global.uid)
      EventEmitter.subscribe('spaceChecked', (event) => {
        if(event === 'no') {
          global.uid = ''
          this.props.history.push('/complete-signup')
        }
      })
    }
    Firebase.auth().currentUser.updateProfile({
      displayName: 'client'
    })
    Firebase.database().ref('/usersClients/'+global.uidUser).set({
      uid: global.uidUser,
      name: '',
      email: Firebase.auth().currentUser.email,
      phone: '',
      lbs: false,
      reminder: true,
      birth: '01/01/1900',
      country: 'au',
      image: '',
      imageDate: Moment().format('DD/MM/YYYY HH:mm'),
      pushToken: '',
    }).then(() => {
      this.setState({
        type: 'form2',
        allowClick: true
      })
      setTimeout(() => {
        this.setState({
          move2: '',
        })
      }, 50);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Overlay trainer switch ------------------------------------------------------------



  showOverlayTrainer() {
    global.portalChange = false
    this.setState({
      showOverlayTrainer: true,
    })
    setTimeout(() => {
      const height = 300
      this.setState({
        hiddenTrainer: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayTrainer() {
    this.setState({
      hiddenTrainer: 'hidden',
      margin: -300/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayTrainer: false});
      this.gotoMemberPortal()
    }, 500);
  }


  renderOverlayTrainer() {
    if(this.state.showOverlayTrainer) {
      return (
        <div className={'overlay '+this.state.hiddenTrainer}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">{lang.t('modal:trainer.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayTrainer()}>{lang.t('modal:common.close')}</button>
            <p className="clear">{lang.t('modal:trainer.text')}</p>
            <div className="sv-20"></div>
            <button className="btn primary" onClick={() => this.hideOverlayTrainer()}>{lang.t('common.button.gotit')}</button>
            <div className="sv-20"></div>
            <button className="btn tertiary width-12 small" onClick={() => this.gotoMemberPortal()}>{lang.t('modal:trainer.button')}</button>
          </div>
        </div>
      )
    }
  }


  gotoMemberPortal() {
    Firebase.auth().signOut()
    window.location = 'https:/ptmate.app'
  }



  // Overlay duplicate email ------------------------------------------------------------



  showOverlayDuplicate() {
    this.setState({
      showOverlayDuplicate: true,
    })
    setTimeout(() => {
      const height = 300
      this.setState({
        hiddenDuplicate: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayDuplicate() {
    this.setState({
      hiddenDuplicate: 'hidden',
      margin: -300/2+100
    })
    setTimeout(() => {
      this.setState({
        showOverlayDuplicate: false,
        type: 'form',
        move1: '',
        move2: 'in',
        move3: 'in',
        move11: 'in',
      });
    }, 500);
  }


  renderOverlayDuplicate() {
    if(this.state.showOverlayDuplicate) {
      return (
        <div className={'overlay '+this.state.hiddenDuplicate}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">{lang.t('modal:email.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayDuplicate()}>{lang.t('modal:common.close')}</button>
            <p className="clear">{lang.t('modal:email.text')}</p>
            <div className="sv-20"></div>
            <button className="btn primary" onClick={() => this.hideOverlayDuplicate()}>{lang.t('common:button.gotit')}</button>
          </div>
        </div>
      )
    }
  }



  // Page 2 stuff ------------------------------------------------------------



  setCountry(event) {
    var lbs = false
    if(event.target.value === 'us') {
      lbs = true
    }
    this.setState({
      country: event.target.value,
      lbs: lbs
    });
  }


  setAvatar(item) {
    this.setState({
      avatar: item
    })
    this.hideModals()
  }


  hideModals() {
    this.setState({
      showModalAvatar: false,
    })
  }

  
  checkUpdate() {
    var tmp = [false, false, false]
    this.setState({error2: [false, false, false, false]})
    if(this.state.name !== '' && this.state.phone !== '' && this.state.birth !== '' && this.state.ofage) {
      this.updateUser()
    } else {
      if(this.state.name === '') {
        tmp[0] = true
      }
      if(this.state.phone === '') {
        tmp[1] = true
      }
      if(this.state.birth === '') {
        tmp[2] = true
      }
      if(!this.state.ofage) {
        tmp[3] = true
      }
      this.setState({error2: tmp})
    }
  }


  updateUser() {

    global.userName = this.state.name
    global.userEmail = Firebase.auth().currentUser.email
    var bus = this.state.code
    if(bus === '') {
      bus = '0'
    } else {
      if(this.state.country === 'us') {
        var ar = this.state.code.split(".")
        var h1 = parseInt((ar[0])*12)*2.54
        var h2 = 0
        if(ar.length > 1) {
          h2 = parseInt(ar[1])*2.54
        }
        bus = parseInt(h1+h2)
      }
    }
    var birth = '01/01/1990'
    var valid = Moment(this.state.birth, 'DD/MM/YYYY').isValid()
    if(valid) {
      birth = this.state.birth
    }
    var avatar = ''
    if(this.state.imageType === 'avatar') {
      avatar = this.state.avatar
    }
    global.userAvatar = avatar

    Firebase.database().ref('/usersClients/'+Firebase.auth().currentUser.uid).update({
      name: this.state.name,
      phone: '',
      birth: birth,
      height: parseInt(bus),
      country: this.state.country,
      lbs: this.state.lbs,
      avatar: avatar,
      image: '',
    }).then(() => {
      if(this.state.file !== null && this.state.imageType === 'image') {
        this.uploadImage()
      }
      this.setState({
        allowClick: false,
        move2: 'out'
      })
      setTimeout(() => {
        this.setState({
          type: 'form3',
          allowClick: true
        })
      }, 250);
      setTimeout(() => {
        this.setState({
          move3: '',
        })
      }, 300);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      //this.setState({type: 'form2'})
      this.setState({
        type: 'form2',
        move2: '',
        move3: 'in',
      })
    })
  }


  uploadImage() {
    var user = Firebase.auth().currentUser
    const sr = Firebase.storage().ref()
    const fr = sr.child('images/users/'+Firebase.auth().currentUser.uid+'.jpg')
    user.updateProfile({
      photoURL: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg'
    })
    Resizer.imageFileResizer(
      this.state.file,
      800,
      800,
      "JPEG",
      70,
      0,
      (uri) => {
        var ar = uri.split(',')
        fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
          Firebase.database().ref('/usersClients/'+Firebase.auth().currentUser.uid).update({
            image: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg',
            imageDate: Moment().format('DD/MM/YYYY HH:mm')
          }).then(() => {
            //this.setState({type: 'form3'})
          })
        }).catch((error)=>{
          //EventEmitter.dispatch('showMessageError', error.message);
        })
      },
      "base64",
      200,
      200
    );
  }


  setType(value) {
    this.setState({
      ectype: parseInt(value)
    })
  }


  sendToggle() {
    if(Firebase.auth().currentUser.uid === 'iJam7aX6gSYjd92D3NSbcKacCbv2') {
      Firebase.database().ref('/clients/'+global.uid+'/'+global.spaceClient).update({
        ecName: this.state.ecname,
        ecType: this.state.ectype,
        ecPhone: this.state.ecphone,
      }).then(() => {
        this.setState({
          type: 'loading'
        })
        setTimeout(() => {
          this.props.history.push('/'+global.uid+'/confirm')
        }, 900);
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
        this.setState({type: 'form3'})
      })
      Firebase.database().ref('/admin/accounts').push({
        date: Moment().format('DD/MM/YYYY HH:mm'),
        space: global.uid,
        email: this.state.email
      })
    } else {
      var client = ''
      var cid = ''
      var tuid = global.uid
      for(var item2 of global.clients) {
        if(item2.data.email === Firebase.auth().currentUser.email && item2.data.deleted === undefined) {
          client = item2.id
        }
      }
      if(client === '') {
        for(var item1 of global.connect) {
          if(item1.data.email === Firebase.auth().currentUser.email) {
            client = item1.data.client
            cid = item1.id
            tuid = item1.data.space
          }
        }
      }
      global.uid = tuid
      global.userPhone = this.state.phone
      global.userBday = Moment(this.state.birth, 'DD/MM/YYYY')
      global.emergency = [this.state.ecname, this.state.ectype, this.state.ecphone]
      Firebase.database().ref('/usersClients/'+Firebase.auth().currentUser.uid).update({
        phone: this.state.phone,
      })
      if(global.uid !== '') {
        var obj =  Firebase.database().ref('/clients/'+global.uid).push()
        if(client === '') {
          client = obj.key
        }
        var bus = this.state.code
        var image = ''
        if(this.state.file !== null) {
          image = 'images/usersClients/'+Firebase.auth().currentUser.uid+'.jpg'
        }
        if(bus === '') {
          bus = '0'
        }
        global.spaceClient = client
        global.coupon = bus
        Firebase.database().ref('/usersClients/'+Firebase.auth().currentUser.uid+'/trainers/'+tuid).update({
          client: client,
          trainer: global.uid
        })
        Firebase.database().ref('/clients/'+tuid+'/'+client).update({
          uid: Firebase.auth().currentUser.uid,
          ecName: this.state.ecname,
          ecType: this.state.ectype,
          ecPhone: this.state.ecphone,
          name: this.state.name,
          phone: this.state.phone,
          birth: this.state.birth,
          height: parseInt(bus),
          image: image,
          avatar: global.userAvatar,
          email: Firebase.auth().currentUser.email,
          country: 'au',
          imageDate: Moment().format('DD/MM/YYYY HH:mm')
        })
        if(cid !== '') {
          Firebase.database().ref('/connect/'+cid).remove()
          Firebase.database().ref('/clients/'+tuid+'/'+client+'/deleted').remove()
        }
        Firebase.database().ref('/clients/'+tuid+'/'+client+'/history').push({
          date: Moment().format('DD/MM/YYYY HH:mm'),
          title: lang.t('auth:label.history.connected.title'),
          desc: lang.t('auth:label.history.connected.label')
        })
        Firebase.database().ref('/admin/accounts').push({
          date: Moment().format('DD/MM/YYYY HH:mm'),
          space: tuid,
          email: Firebase.auth().currentUser.email
        })
      }
      Firebase.database().ref('/usersClients/'+Firebase.auth().currentUser.uid).update({
        ecName: this.state.ecname,
        ecType: this.state.ectype,
        ecPhone: this.state.ecphone,
      }).then(() => {
        this.setState({
          type: 'loading'
        })
        setTimeout(() => {
          this.props.history.push(this.state.link2)
        }, 900);
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
        this.setState({type: 'form3'})
      })
    }
    
  }



  // Unauth stuff ------------------------------------------------------------



  onChangeEmail = event => {
    this.setState({ email: event.target.value });
  };


  startUnauth() {
    if(this.state.allowClick) {
      var tmp = [false, false, false, false, false]
      this.setState({
        errora: [false, false, false, false, false],
        allowClick: false
      })
      if(this.state.name !== '' && this.state.email !== '' && this.state.phone !== '' && this.state.birth !== '') {
        this.loginUnauth()
      } else {
        if(this.state.name === '') {
          tmp[0] = true
        }
        if(this.state.email === '') {
          tmp[1] = true
        }
        if(this.state.phone === '') {
          tmp[2] = true
        }
        if(this.state.birth === '') {
          tmp[3] = true
        }
        this.setState({
          errora: tmp,
          allowClick: true
        })
      }
    }
  }


  loginUnauth() {
    Firebase.auth().signInWithEmailAndPassword('membertemplate@ptmate.net', 'password')
    .then(() => {
      Connector.loadAdminList((result) => {
        var pass = true
        for(var item of global.adminList) {
          if(item.data.space === global.uid && item.data.email === this.state.email) {
            pass = false
          }
        }
        if(pass) {
          this.createUnauth()
        } else {
          this.showOverlayDuplicate();
        }
      })
      
    })
    .catch(error => {
      console.log('error')
      console.log(error)
    });
  }


  createUnauth() {
    var bus = this.state.code
    if(bus === '') {
      bus = '0'
    }
    var birth = '01/01/1990'
    var valid = Moment(this.state.birth, 'DD/MM/YYYY').isValid()
    if(valid) {
      birth = this.state.birth
    }
    var obj = Firebase.database().ref('/clients/'+global.uid).push()
    obj.update({
      uid: '',
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      birth: birth,
      country: 'au',
      image: '',
      imageDate: Moment().format('DD/MM/YYYY HH:mm'),
      pushToken: '',
      height: parseInt(bus),
    }).then(() => {
      global.spaceClient = obj.key
      this.setState({
        move2: 'out',
      })
      setTimeout(() => {
        this.setState({
          type: 'form3',
          allowClick: true
        })
      }, 250);
      setTimeout(() => {
        this.setState({
          move3: '',
        })
      }, 300);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    Firebase.database().ref('/clients/'+global.uid+'/'+obj.key+'/history').push({
      date: Moment().format('DD/MM/YYYY HH:mm'),
      title: lang.t('auth:label.history.created.title'),
      desc: lang.t('auth:label.history.created.label')
    })
  }



  // Display stuff ------------------------------------------------------------



  getBg() {
    var label = 'hero bg1'
    if(!this.state.done && this.state.num === 99) {
      var num = parseInt(Math.random()*6+1)
      global.num = num
      this.setState({
        num: num
      })
    }
    label = 'hero bg'+this.state.num
    return label
  }


  displayImage() {
    if(this.state.file === null) {
      if(this.state.imageType === 'avatar') {
        return (
          <div className={'avatar '+this.state.avatar}></div>
        )
      } else if(this.state.imageType === '') {
        return <div className="avatar nophoto"></div>
      } else {
        return (
          <div className="avatar"><img src="/img/data-client.svg" alt="Select your avatar"/></div>
        )
      }
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.fileDisplay+')'}}></div>
      )
    }
  }


  renderLogo() {
    if(this.state.logo === '') {
      return (
        <img src="/img/logo.svg" alt="PT Mate Member Area"/>
      )
    } else {
      return (
        <div className="logo">
          <img src={this.state.logo} alt="Member Area"/>
        </div>
      )
    }
  }


  renderError() {
    var label = ''
    if(this.state.error[0]) {
      label += ' '+lang.t('auth:label.error.email')
    }
    if(this.state.error[1]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:label.error.password')
    }
    if(this.state.error[2]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:label.error.tc')
    }
    if(this.state.error[0] || this.state.error[1] || this.state.error[2]) {
      return (
        <p className="error">{lang.t('auth:label.review')} {label}</p>
      )
    }
  }


  renderError2() {
    var label = ''
    if(this.state.error2[0]) {
      label += ' '+lang.t('auth:form.fullname.label')
    }
    if(this.state.error2[1]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:form.phone.label')
    }
    if(this.state.error2[2]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:form.dob.label')
    }
    if(this.state.error2[3]) {
      if(label !== '') {
        label += ','
      }
    }
    if(this.state.error2[3]) {
      return (
        <p className="error">{lang.t('auth:label.age')}</p>
      )
    } else {
      if(this.state.error2[0] || this.state.error2[1] || this.state.error2[2]) {
        return (
          <p className="error">{lang.t('auth:label.review')} {label}</p>
        )
      }
    }
    
  }


  renderErrorA() {
    var label = ''
    if(this.state.errora[0]) {
      label += ' '+lang.t('auth:form.fullname.label')
    }
    if(this.state.errora[1]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:form.email.label')
    }
    if(this.state.errora[2]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:form.phone.label')
    }
    if(this.state.errora[3]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:form.dob.label')
    }
    if(this.state.errora[4]) {
      /*if(label !== '') {
        label += ','
      }*/
    }
    if(this.state.errora[4]) {
      return (
        <p className="error">{lang.t('auth:label.age')}</p>
      )
    } else {
      if(this.state.errora[0] || this.state.errora[1] || this.state.errora[2] || this.state.errora[3]) {
        return (
          <p className="error">{lang.t('auth:label.review')} {label}</p>
        )
      }
    }
    
  }


  renderForm3() {
    if(this.state.type === 'form3') {
      return (
        <div className={'inner '+this.state.move3}>
          <h1 style={{whiteSpace: 'pre-wrap'}}>{lang.t('auth:title3')}</h1>
          <p className="mb-20">{lang.t('auth:label3')}</p>
          <div className="space-20 clear"></div>   
          <div className="form">
            <label>{lang.t('auth:form.name.label')}</label>
            <input type="text" placeholder={lang.t('auth:form.name.placeholder')} name="ecname" value={this.state.ecname} onChange={this.onChange3}/>
            <label>{lang.t('auth:form.phone.label')}</label>
            <input type="number" placeholder={lang.t('auth:form.phone.placeholder2')} name="ecphone" value={this.state.ecphone} onChange={this.onChange3}/>

            <div className="clear"></div>
            <label>{lang.t('auth:form.ec.label')}</label>
            <div className="radios">
              <button style={{marginTop:0}} className={this.state.ectype === 0 ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setType(0)}>{lang.t('auth:form.ec.spouse')}</button>
              <button style={{marginTop:0}} className={this.state.ectype === 1 ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setType(1)}>{lang.t('auth:form.ec.friend')}</button>
              <button style={{marginTop:0}} className={this.state.ectype === 2 ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.setType(2)}>{lang.t('auth:form.ec.other')}</button>
            </div>

            <div className="sv-40 clear"></div>
            <button className="btn primary" onClick={() => this.sendToggle()}>{lang.t('auth:button.letsgo')}</button>
          </div>
        </div>
      )
    }
  }


  renderImageSelect() {
    if(this.state.imageType === 'avatar') {
      return <button className="btn tertiary small mb-20" onClick={() => this.setState({showModalAvatar: true})}>{lang.t('auth:button.gallery')}</button>
    } else if(this.state.imageType === 'image') {
      return (
        <div className="form-item">
          <input type="file" placeholder={lang.t('auth:form.image.placeholder')} onChange={(e: any) => {this.setImage(e.target.files[0]);}}/>
        </div>
      )
    }
  }


  renderForm2() {
    if(this.state.type === 'form2') {
      return (
        <div className={'inner '+this.state.move2}>
          {this.renderLogo()}
          <h1 className="mb-10">{lang.t('auth:title2')}</h1>
          <p className="mb-20" style={{whiteSpace: 'pre-wrap'}}>{lang.t('auth:label2')}</p>
          {this.displayImage()}
          <div className="sv-10"></div>
          <InputRadio label='Image' value={this.state.imageType} values={[{name: lang.t('auth:form.image.gallery'), value: 'avatar'}, {name: lang.t('auth:form.image.photo'), value: 'image'}, {name: lang.t('auth:form.image.noimage'), value: ''}]} clickElement={(event) => this.setState({imageType: event})}/>
          {this.renderImageSelect()}
          <div className="sv-20"></div>
          
          <label>{lang.t('auth:form.fullname.label')}*</label>
          <input type="text" placeholder={lang.t('auth:form.fullname.placeholder')} name="name" value={this.state.name} onChange={this.onChange2}/>
          <label>{lang.t('auth:form.phone.label')}*</label>
          <input type="number" placeholder={lang.t('auth:form.phone.placeholder')} name="phone" value={this.state.phone} onChange={this.onChange2}/>
          <label>{lang.t('auth:form.dob.label')}*</label>
          <input type="date" placeholder={lang.t('auth:form.dob.placeholder')} value={Moment(this.state.birth, 'DD/MM/YYYY').format('YYYY-MM-DD')} onChange={this.onChangeBirth}/>
          <label>{lang.t('auth:form.height.label')} {this.state.country === 'us' ? 'ft' : 'cm'}</label>
          <input type="number" placeholder={lang.t('auth:form.height.placeholder')+' '+(this.state.country === 'us' ? 'ft' : 'cm')+' (optional)'} name="code" value={this.state.code} onChange={this.onChange2}/>
          {this.renderError2()}
          <label>{lang.t('auth:form.country.label')}</label>
          <select value={this.state.country} onChange={event => this.setCountry(event)}>
            <option value='au'>{lang.t('auth:form.country.au')}</option>
            <option value='us'>{lang.t('auth:form.country.us')}</option>
          </select>
          <div className="sv-30"></div>   
          <button className="btn primary" onClick={() => this.checkUpdate()}>{lang.t('common:button.continue')}</button>
        </div>
      )
    }
  }


  renderFormNoAcct() {
    if(this.state.type === 'noacct') {
      return (
        <div className={'inner '+this.state.move11}>
          <h1 className="mb-10">{lang.t('auth:title1')}</h1>
          <p className="mb-20" style={{whiteSpace: 'pre-wrap'}}>{lang.t('auth:label1')}</p>
          <label>{lang.t('auth:form.fullname.label')}*</label>
          <input type="text" placeholder={lang.t('auth:form.fullname.placeholder')} name="name" value={this.state.name} onChange={this.onChange2}/>
          <label>{lang.t('auth:form.email.label')}*</label>
          <input type="text" placeholder={lang.t('auth:form.email.placeholder')} value={this.state.email} onChange={this.onChangeEmail}/>
          <label>{lang.t('auth:form.phone.label')}*</label>
          <input type="number" placeholder={lang.t('auth:form.phone.placeholder')} name="phone" value={this.state.phone} onChange={this.onChange2}/>
          <label>{lang.t('auth:form.dob.label')}*</label>
          <input type="date" placeholder={lang.t('auth:form.dob.placeholder')} value={Moment(this.state.birth, 'DD/MM/YYYY').format('YYYY-MM-DD')} onChange={this.onChangeBirth}/>
          <label>{lang.t('auth:form.height.label')} {this.state.country === 'us' ? 'ft' : 'cm'}</label>
          <input type="number" placeholder={lang.t('auth:form.height.placeholder')+' '+(this.state.country === 'us' ? 'ft' : 'cm')+' (optional)'} name="code" value={this.state.code} onChange={this.onChange2}/>
          {this.renderErrorA()}
          <div className="sv-30"></div>   
          <button className="btn primary" onClick={() => this.startUnauth()}>{lang.t('common:button.continue')}</button>
          <div style={{height: 20}}></div>
          <button className="btn tertiary width-12 small" onClick={() => this.setState({type: 'form', move1: '', move11: 'in'})}>{lang.t('common:button.goback')}</button>
        </div>
      )
    }
  }


  renderNoAccount() {
    if(global.uid !== '') {
      return (
        <div className="clear">
          <div style={{height: 20}}></div>
          <button className="btn tertiary width-12 add small" onClick={() => this.setState({type: 'noacct', move11: '', move1: 'out'})}>{lang.t('auth:button.noaccount')}</button>
        </div>
      )
    }
  }


  renderForm() {
    if(this.state.type === 'form') {
      return (
        <div className={'inner '+this.state.move1}>
          {this.renderLogo()}
          <h1>{lang.t('auth:title.signup')} {this.state.sname}</h1>
          <label>{lang.t('auth:form.email.label')}</label>
          <input type="text" placeholder={lang.t('auth:form.email.placeholder')} name="email" value={this.state.email} onChange={this.onChange}/>
          <label>{lang.t('auth:form.password.label')}</label>
          <input type="password" placeholder={lang.t('auth:form.password.placeholder2')} name="password" value={this.state.password} onChange={this.onChange}/>
          <div className="terms">
             <input type="checkbox" value={this.state.checked} onChange={this.toggleChange}/>
             <p>{lang.t('auth:label.tc1')} <a href="http://www.ptmate.net/terms-conditions" target="_blank" rel="noopener noreferrer">{lang.t('auth:label.tc2')}</a> {lang.t('auth:label.tc3')} <a href="http://www.ptmate.net/privacy-policy" target="_blank" rel="noopener noreferrer">{lang.t('auth:label.tc4')}</a></p>
          </div>
          {this.renderError()}
          <button className="btn primary" onClick={() => this.createUser()}>{lang.t('auth:button.signup')}</button>
          {this.renderNoAccount()}
        </div>
      )
    }
  }


  renderLoader() {
    if(this.state.type === 'loading') {
      return (
        <div className="loading">
          <div className="loader-box">
            <span className="loader"><span className="loader-inner"></span></span>
          </div>
          <p>{lang.t('auth:label.loading')}</p>
        </div>
      )
    }
  }


  renderBottom() {
    if(this.state.type === 'form') {
      return (
        <Link to={this.state.link} className="link memberlink">{lang.t('auth:button.existing')}</Link>
      )
    }
  }


  render() {
    return (
      <div className={'register theme-'+global.spaceTheme}>
        <div className="content">
          {this.renderForm()}
          {this.renderForm2()}
          {this.renderFormNoAcct()}
          {this.renderForm3()}
          {this.renderLoader()}
        </div>
        <div className={this.getBg()}>
          <div className={'gradient theme-'+global.spaceTheme}></div>
        </div>
        {this.renderBottom()}
        {this.renderOverlayTrainer()}
        {this.renderOverlayDuplicate()}
        <ModalAvatar show={this.state.showModalAvatar} onHide={() => this.hideModals()} clickElement={(event) => this.setAvatar(event)}/>
        <Message/>
      </div>
    )
  }
}

const MemberSignUpForm = compose(
  withRouter,
)(MemberSignUpPage);

export default withTranslation(['auth','common','modal','header'])(MemberSignUpForm);