import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import Navigation from '../../components/Navigation';
import WidgetChartPie from '../../components/Widgets/chart-pie';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { AuthUserContext, withAuthorizationMember } from '../../components/Session';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class MemberHabitPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      margin: 0,
      showOverlayDay: false,
      hiddenDay: 'hidden',
      month: Moment().format('MM/YYYY'),
      day: Moment().format('DD/MM/YYYY'),
      status: '0',
      notes: '',
      data: [
        {
          "id": "japan",
          "label": "java",
          "value": 47,
          "color": "#1DC5C9"
        },
      ],
    };
  }


  componentDidMount() {
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-2]
    if(id !== 'home' && id !== 'member') {
      global.uid = id
    }
    Connector.setUser()
    document.title = lang.t('header:health.habit')+' - '+global.userBusiness

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('habitsLoaded', (event) => this.configureData())
    this.configureData()
  }


  configureData() {
    var id = ''
    var item = null
    var cid = ''
    var client = null
    var values = ['', '', '', '', '']
    var hasEnd = true
    var arr = this.props.location.pathname.split('/')
    for(var hab of global.habits) {
      if(hab.id === arr[arr.length-1]) {
        item = hab
        id = hab.id
        cid = item.data.client
        var frequency = 'day'
        if(item.data.interval === 7) { frequency = 'week' }
        values = [item.data.name, item.data.amount, item.data.unit, frequency, Moment(item.data.start, 'X').format('YYYY-MM-DD'), Moment(item.data.end, 'X').format('YYYY-MM-DD')]
        if(Moment(item.data.end, 'X') > Moment('01/01/2999', 'DD/MM/YYYY')) {
          hasEnd = false
          values[5] = Moment().add(180, 'days').format('YYYY-MM-DD')
        }
      }
    }
    for(var cl of global.clients) {
      if(cl.id === cid) {
        client = cl
      }
    }
    if(item !== null) {
      var tmp1 = 0
      var tmp2 = 0
      var tmp3 = 0
      var start = Moment(item.data.start, 'X').startOf('day')
      var end = Moment().add(1, 'days')
      if( Moment(item.data.end, 'X') < Moment().add(1, 'days')) {
        end = Moment(item.data.end, 'X')
      }
      var d = Moment.duration(end.diff(start))
      var dys = d.asDays()
      for(var i=0; i<dys.toFixed(0); i++) {
        var found = false
        if(item.data.days !== undefined) {
          var dt = Moment(item.data.start, 'X').add(i, 'days').format('DD/MM/YYYY')
          for(var day of item.data.days) {
            if(day.indexOf(dt) !== -1 && day.indexOf('||1||') !== -1) {
              tmp1++
              found = true
            } else if(day.indexOf(dt) !== -1 && day.indexOf('||2||') !== -1) {
              tmp2++
              found = true
            }
          }
        }
        if(!found) {
          tmp3++
        }
      }
      
      var tmpData = [
        {
          "id": lang.t('health:label.achieved'),
          "label": lang.t('health:label.achieved'),
          "value": tmp1,
          "color": "#FB0044"
        },
        {
          "id": lang.t('health:label.notachieved'),
          "label": lang.t('health:label.notachieved'),
          "value": tmp2,
          "color": "#81DB24"
        },
        {
          "id": lang.t('health:label.pending'),
          "label": lang.t('health:label.pending'),
          "value": tmp3,
          "color": "#D3D7D8"
        },
      ]
    }

    this.setState({
      groups: global.clientGroups,
      data: tmpData,
      id: id,
      item: item,
      client: client,
      name: values[0],
      amount: parseFloat(values[1]),
      unit: values[2],
      frequency: values[3],
      start: values[4],
      end: values[5],
      hasEnd: hasEnd
    })
  }


  getCompliance(type) {
    var label = '-'
    var style = 'default'
    var tmp1 = 0
    var start = Moment(this.state.item.data.start, 'X').startOf('day')
    var end = Moment().add(1, 'days')
    if(Moment(this.state.item.data.end, 'X') < Moment()) {
      end = Moment(this.state.item.data.end, 'X')
    }
    var d = Moment.duration(end.diff(start))
    var dys = d.asDays()
    for(var i=0; i<dys.toFixed(0); i++) {
      if(this.state.item.data.days !== undefined) {
        var dt = Moment(this.state.item.data.start, 'X').add(i, 'days').format('DD/MM/YYYY')
        for(var day of this.state.item.data.days) {
          if(day.indexOf(dt) !== -1 && day.indexOf('||1||') !== -1) {
            tmp1++
          }
        }
      }
    }
    var per = tmp1/dys.toFixed(0)*100
    label = per.toFixed(0)+"%"
    if(per > 0 && per < 30) {
      style = 'red'
    }
    if(per > 29 && per < 80) {
      style = 'yellow'
    }
    if(per > 79) {
      style = 'green'
    }
    if(isNaN(per)) {
      label = '-'
      style = 'default'
    }
    if(type === 'label') {
      return label
    } else {
      return style
    }
  }


  getInterval() {
    var label = lang.t('health:label.perday')
    if(this.state.item.data.interval === 7) {
      label = lang.t('health:label.perweek')
    }
    return label
  }


  setMonth(type) {
    var dt = Moment('01/'+this.state.month, 'DD/MM/YYYY').add(1, 'month')
    if(type === 'prev') {
      dt = Moment('01/'+this.state.month, 'DD/MM/YYYY').add(-1, 'month')
    }
    this.setState({
      month: Moment(dt).format('MM/YYYY')
    })
  }



  // Day overlay ------------------------------------------------------------



  showOverlayDay(item) {
    if(Moment(item, 'DD/MM/YYYY') > Moment(this.state.item.data.start, 'X').add(-1, 'day') && Moment(item, 'DD/MM/YYYY') < Moment(this.state.item.data.end, 'X').add(1, 'day') && Moment(item, 'DD/MM/YYYY') < Moment().add(1, 'day')) {
      var status = '0'
      var notes = ''
      if(this.state.item.data.days !== undefined) {
        for(var day of this.state.item.data.days) {
          if(day.indexOf(item) !== -1) {
            var ar = day.split('||')
            status = ar[1]
            notes = ar[2]
          }
        }
      }
      this.setState({
        showOverlayDay: true,
        day: item,
        status: status,
        notes: notes,
      })
      setTimeout(() => {
        const height = this.divElement.clientHeight
        this.setState({
          hiddenDay: '',
          margin: -height/2,
        });
      }, 100);
    }
  }


  hideOverlayDay() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenDay: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayDay: false});
    }, 500);
  }


  renderOverlayDayName() {
    var label = Moment(this.state.day, 'DD/MM/YYYY').locale(lang.language).format('ddd, D MMM YYYY')
    if(this.state.item.data.interval === 7) {
      var d1 = Moment(this.state.item.data.start, 'X').locale(lang.language).format('D MMM')
      var d2 = Moment(this.state.item.data.start, 'X').locale(lang.language).add(6, 'days').format('D MMM')
      
      var i = 0
      while (Moment(this.state.item.data.start, 'X').add(i*7, 'days') < Moment(this.state.day, 'DD/MM/YYYY').add(1, 'days')) {
        d1 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').locale(lang.language).format('D MMM')
        d2 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').locale(lang.language).add(6, 'days').format('D MMM')
        i++
      }
      label = d1+' - '+d2
      
    }
    return label
  }


  renderOverlayDay() {
    if(this.state.showOverlayDay) {
      return (
        <div className={'overlay '+this.state.hiddenDay}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{this.renderOverlayDayName()}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayDay()}>{lang.t('modal:common.close')}</button>
            <div className="clear"></div>
            <h4 className="mb-20">{this.state.item.data.amount} {this.state.item.data.unit} {this.getInterval()}</h4>
            <label>{lang.t('health:label.status')}</label>
            <div className="radios">
              <button className={this.state.status === '1' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({status: '1'})}>{lang.t('health:label.achieved')}</button>
              <button className={this.state.status === '2' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({status: '2'})}>{lang.t('health:label.notachieved')}</button>
            </div>
            <div className="sv-20"></div>
            <label>{lang.t('health:log.notes')} ({lang.t('health:label.optional')})</label>
            <textarea value={this.state.notes} onChange={this.onChangeNotes}></textarea>
            <div className="sv-30"></div>
            <button className="btn primary" onClick={() => this.updateDay()}>Save changes</button>
          </div>
        </div>
      )
    }
  }


  onChangeNotes = event => {
    this.setState({
      notes: event.target.value,
    });
  };


  updateDay() {
    this.hideOverlayDay()
    var found = false
    var days = []

    var label = this.state.day
    if(this.state.item.data.interval === 7) {
      var d1 = Moment(this.state.item.data.start, 'X').format('DD/MM/YYYY')
      var d2 = Moment(this.state.item.data.start, 'X').add(1, 'days').format('DD/MM/YYYY')
      var d3 = Moment(this.state.item.data.start, 'X').add(2, 'days').format('DD/MM/YYYY')
      var d4 = Moment(this.state.item.data.start, 'X').add(3, 'days').format('DD/MM/YYYY')
      var d5 = Moment(this.state.item.data.start, 'X').add(4, 'days').format('DD/MM/YYYY')
      var d6 = Moment(this.state.item.data.start, 'X').add(5, 'days').format('DD/MM/YYYY')
      var d7 = Moment(this.state.item.data.start, 'X').add(6, 'days').format('DD/MM/YYYY')
      
      var i = 0
      while (Moment(this.state.item.data.start, 'X').add(i*7, 'days') < Moment(this.state.day, 'DD/MM/YYYY').add(1, 'days')) {
        d1 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').format('DD/MM/YYYY')
        d2 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').add(1, 'days').format('DD/MM/YYYY')
        d3 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').add(2, 'days').format('DD/MM/YYYY')
        d4 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').add(3, 'days').format('DD/MM/YYYY')
        d5 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').add(4, 'days').format('DD/MM/YYYY')
        d6 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').add(5, 'days').format('DD/MM/YYYY')
        d7 = Moment(this.state.item.data.start, 'X').add(i*7, 'days').add(6, 'days').format('DD/MM/YYYY')
        i++
      }
      label = d1+','+d2+','+d3+','+d4+','+d5+','+d6+','+d7
      
    }

    if(this.state.item.data.days !== undefined) {
      days = this.state.item.data.days
      for(var i1=0; i1<days.length; i1++) {
        if(days[i1].indexOf(this.state.day) !== -1) {
          days[i1] = label+'||'+this.state.status+'||'+this.state.notes
          found = true
        }
      }
    }
    if(!found) {
      days.push(label+'||'+this.state.status+'||'+this.state.notes)
    }
    Firebase.database().ref('/habits/'+global.uid+'/'+this.state.item.id).update({
      days: days
    }).then(() => {
      EventEmitter.dispatch('showMessage',  lang.t('message:day'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Display stuff ------------------------------------------------------------



  renderGraph() {
    if(Moment(this.state.item.data.start, 'X') <= Moment()) {
      if(this.state.data[0].id === 'japan') {
        return (
          <p className="empty">{lang.t('auth:label.loading2')}</p>
        )
      } else if(this.state.data[0].id !== 'japan') {
        return <WidgetChartPie data={this.state.data} colors={['#81DB24', '#DE1067', '#999C9F']}/>
      }
    } else {
      return (
        <div></div>
      )
    }
  }


  getDayClass(item) {
    var label = 'weekday interactive lft'
    var start = Moment(this.state.item.data.start, 'X').add(-1, 'day')
    var end = Moment()
    if( Moment(this.state.item.data.end, 'X') < Moment()) {
      end = Moment(this.state.item.data.end, 'X')
    }
    if(start < Moment(item, 'DD/MM/YYYY') && end > Moment(item, 'DD/MM/YYYY')) {
      label = 'weekday interactive pending lft'
    }
    if(this.state.item.data.days !== undefined) {
      for(var day of this.state.item.data.days) {
        if(day.indexOf(item) !== -1 && day.indexOf('||1||') !== -1) {
          label = 'weekday interactive done lft'
        }
        if(day.indexOf(item) !== -1 && day.indexOf('||2||') !== -1) {
          label = 'weekday interactive fail lft'
        }
      }
    }
    return label
  }


  renderStaticEnd() {
    if(Moment(this.state.item.data.end, 'X') < Moment('31/01/2999', 'DD/MM/YYYY')) {
      return (
        <div className="info">
          <label>Ends</label>
          <p>{Moment(this.state.item.data.end, 'X').locale(lang.language).format('D MMM YYYY')}</p>
        </div>
      )
    }
  }


  renderBase() {
    var d1 = Moment('01/'+this.state.month, 'DD/MM/YYYY').format('d')
    var d2 = Moment('01/'+this.state.month, 'DD/MM/YYYY').endOf('month').format('D')
    var list1 = []
    var list2 = []
    if(parseInt(d1) === 0) {
      d1 = 7
    }
    for(var i1=1; i1<parseInt(d1); i1++) {
      list1.push('')
    }
    for(var i2=1; i2<(parseInt(d2)+1); i2++) {
      list2.push(Moment(i2+'/'+this.state.month, 'D/MM/YYYY').format('DD/MM/YYYY'))
    }
    if(this.state.item === null) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <Navigation active='health' />
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
              <div className="box">
                <h3>{lang.t('header:health.habit')}</h3>
                <div className="clear sv-20"></div>
                <div className="info">
                  <label>{lang.t('health:label.name')}</label>
                  <p>{this.state.item.data.name}</p>
                </div>
                <div className="info">
                  <label>{lang.t('health:label.measurement')}</label>
                  <p>{this.state.item.data.amount} {this.state.item.data.unit} {this.getInterval()}</p>
                </div>
                <div className="info">
                  <label>{lang.t('health:label.start')}</label>
                  <p>{Moment(this.state.item.data.start, 'X').locale(lang.language).format('D MMM YYYY')}</p>
                </div>
                {this.renderStaticEnd()}
                <div className="info">
                  <label>{lang.t('health:label.compliance')}</label>
                  <div className="bar">
                    <div className={'inner '+this.getCompliance('style')} style={{width: this.getCompliance('label')}}></div>
                  </div>
                  <p>{this.getCompliance('label')}</p>
                  <div className="clear"></div>
                </div>
                <div className="sv-50"></div>
              </div>
            </div>

            <div className="col-6 sidebar">
              <div style={{width: '100%', height: '300px'}}>
                {this.renderGraph()}
              </div>
              <div className="clear sv-20"></div>
              <div className="calmini">
                <div className="calmini-header mb-20">
                  <div className="arrow lft" onClick={() => this.setMonth('prev')}>‹</div>
                  <div className="arrow rgt" style={{float:'right'}} onClick={() => this.setMonth('next')}>›</div>
                  <h3 className="lft">{Moment('01/'+this.state.month, 'DD/MM/YYYY').locale(lang.language).format('MMMM YYYY')}</h3>
                  <div className="clear"></div>
                </div>
                {list1.map(item => (
                  <div key={item}>
                    <div className="weekday lft" key={item}></div>
                  </div>
                ))}
                {list2.map((item,index) => (
                  <div key={item}>
                    <div className={this.getDayClass(item)} key={item} onClick={() => this.showOverlayDay(item)}>{index+1}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>

          <div className="header">
            <div className="content">
              <Link to={'/'+global.uid+'/health/habits'} className="back lft mr-10">
                <div className="circle"></div>
                <div className="arrow"></div>
              </Link>
              <h2>{lang.t('header:health.habit')}</h2>
            </div>
          </div>
          <Navigation active='health' />
          {this.renderOverlayDay()}
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['header','auth','health','common','modal','message'])(withRouter(withAuthorizationMember(condition)(MemberHabitPage)));